import React, { useEffect, useState } from "react";
import { getUserAgentSum } from "../../axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DefaultUserPNG from "../../assets/store-info.png"

import "./AgentSummarize.css"
import { NavBar, Toast } from "antd-mobile";
export default function AgentSummarize() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const [betAmount, setBetAmount] = useState(0);
    const [userAmount, setUserAmount] = useState(0);
    useEffect(() => {
        getUserAgentSum({}).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setBetAmount(data.betAmount);
                setUserAmount(data.userAmount);
            }
        })
    }, []);
    const back = () => {
        navigate(-1);
    }
    return (
        <div className="pointBox">
            <NavBar onBack={back}>
                用户管理
            </NavBar>
            <div className="myheader myH">
                <div className="userInfo">
                    <div className="image1 uni-image" style={{ "borderRadius": "100%" }}>
                        <img src={user.avatar || DefaultUserPNG} draggable="false" />
                    </div>
                    <div className="name">{user.username}</div>
                    <div className="my">
                        <div className="topBtms">
                            <div className="topBtmsd1" onClick={() => navigate("/person/agentmystatistics")}>
                                <div className="s">
                                    <div className="s1">累计本月销量 {">"}</div>
                                    <div className="s2">{betAmount}</div>
                                </div>
                            </div>
                            <div className="topBtmsd1" onClick={() => navigate("/person/agentmyusers")}>
                                <div className="s">
                                    <div className="s1">本月开户人数 {">"}</div>
                                    <div className="s2">{userAmount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}