import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavBar, Tabs, InfiniteScroll, Toast } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import { getActionList } from "../../axios/index.js";
import { getTime } from "../../utils/index.ts";
import { action2CN } from "../../constant/cnNameMap.ts";
import "./ActionList.css";
const addBalanceAction = ["charge", "add", "bonus", "recall", "commission", "turnin"];
const subBalanceAction = ["sub", "order", "draw", "turnout"];
const actionType2CN = ["代购", "合买", "保底", '发单'];
export default function UserDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const [actionList, setActionList] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const defaultActiveTab = useMemo(() => {
        return location.state?.tab || 'charge,order,recall,bonus,add,sub,commission,draw,turnin,turnout'
    }, [location.state?.tab]);
    const [currentTab, setCurrentTab] = useState(defaultActiveTab);
    const userId = useMemo(() => {
        return location?.state?.id;
    }, [location?.state?.id])
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            action: currentTab,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        if (userId) {
            query["userId"] = userId;
        }
        try {
            const { data } = await getActionList(query);
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                pagenation.current.pageNum++;
                setActionList([...actionList, ...data.actionList]);
                setHasMore(data.actionList.length === 10);
            }
        } catch (e) {
            Toast.show("获取彩票失败");
        }
        pagenation.current.loading = false;
    }

    useEffect(() => {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        setActionList([]);
        pagenation.current.pageNum = 0;
        let query: any = {
            action: currentTab,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        if (userId) {
            query["userId"] = userId;
        }
        getActionList(query).then(({ data }: any) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                pagenation.current.pageNum++;
                setActionList(data.actionList);
                setHasMore(data.actionList.length === 10);
            }
        }).finally(() => {
            pagenation.current.loading = false;
        })
    }, [currentTab]);

    const back = () => {
        navigate('/person/person');
    }
    return (
        <div className="action-list">
            <NavBar onBack={back}>
                账户明细
            </NavBar>
            <Tabs onChange={setCurrentTab}>
                <Tabs.Tab title='全部' key='charge,order,recall,bonus,add,sub,commission,draw,turnin,turnout'>
                </Tabs.Tab>
                <Tabs.Tab title='投注' key='order,recall'>
                </Tabs.Tab>
                <Tabs.Tab title='返奖' key='bonus,commission'>
                </Tabs.Tab>
                <Tabs.Tab title='充值' key='charge,add'>
                </Tabs.Tab>
                <Tabs.Tab title='提款' key='draw,sub'>
                </Tabs.Tab>
                <Tabs.Tab title='转款' key='turnin,turnout'>
                </Tabs.Tab>
            </Tabs>
            <div className="content">
                {
                    actionList.map((action: any) => {
                        return (
                            <div className="item">
                                <div className="left">
                                    <div className="d1">
                                        {
                                           (!["add", "sub", 'draw', 'turnout', 'turnin'].includes(action.action)) && actionType2CN[action.type || 0]
                                        }
                                        { action2CN[action.action] }
                                        {  
                                            action.action === 'turnout' && (<span style={{ fontSize: '14px' }}>{`(${action.additionalUserId.phone})`}</span>)
                                        }
                                        {  
                                            action.action === 'turnin' && (<span style={{ fontSize: '14px' }}>{`(${action.additionalUserId.phone})`}</span>)
                                        }
                                    </div>
                                    {
                                        addBalanceAction.includes(action.action) && (<div className="d3">+{action.balanceChanges?.toFixed(2)}元</div>)
                                    }
                                    {
                                        subBalanceAction.includes(action.action) && (<div className="d2">-{action.balanceChanges?.toFixed(2)}元</div>)
                                    }
                                    
                                </div>
                                <div className="right">
                                    <div className="d1">{getTime(action.time)}</div>
                                    {
                                        action.balance && (<div className="d2">剩余{action.balance.toFixed(2)}元</div>)
                                    }
                                    
                                </div>
                            </div>
                        )
                    })
                }
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
            </div>
        </div>
    )
}

