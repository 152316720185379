
export const freeCombine = [
    "1^1",
    "2^1",
    "3^1",
    "4^1",
    "5^1",
    "6^1",
    "7^1",
    "8^1",
    "9^1",
    "10^1",
    "11^1",
    "12^1",
    "13^1",
    "14^1",
    "15^1"
];

export const option2Num: {
    [key: string]: number
} = {
    "1^1": 1,
    "2^1": 2,
    "3^1": 3,
    "4^1": 4,
    "5^1": 5,
    "6^1": 6,
    "7^1": 7,
    "8^1": 8,
    "9^1": 9,
    "10^1": 10,
    "11^1": 11,
    "12^1": 12,
    "13^1": 13,
    "14^1": 14,
    "15^1": 15,
    "3^3": 16,
    "3^4": 17,
    "4^4": 18,
    "4^5": 19,
    "4^6": 20,
    "4^11": 21,
    "5^5": 22,
    "5^6": 23,
    "5^10": 24,
    "5^16": 25,
    "5^20": 26,
    "5^26": 27,
    "6^6": 28,
    "6^7": 29,
    "6^15": 30,
    "6^20": 31,
    "6^22": 32,
    "6^35": 33,
    "6^42": 34,
    "6^50": 35,
    "6^57": 36,
    "7^7": 37,
    "7^8": 38,
    "7^21": 39,
    "7^35": 40,
    "7^120": 41,
    "8^8": 42,
    "8^9": 43,
    "8^28": 44,
    "8^56": 45,
    "8^70": 46,
    "8^247": 47
}

export const mixedCombine:string[] = [
    "3^3",
    "3^4",
    "4^4",
    "4^5",
    "4^6",
    "4^11",
    "5^5",
    "5^6",
    "5^10",
    "5^16",
    "5^20",
    "5^26",
    "6^6",
    "6^7",
    "6^15",
    "6^20",
    "6^22",
    "6^35",
    "6^42",
    "6^50",
    "6^57",
    "7^7",
    "7^8",
    "7^21",
    "7^35",
    "7^120",
    "8^8",
    "8^9",
    "8^28",
    "8^56",
    "8^70",
    "8^247"
]

export const mixedCombineGroup = {
    "3": ["3^3", "3^4"],
    "4": ["4^4", "4^5", "4^6", "4^11"],
    "5": ["5^5",  "5^6", "5^10", "5^16", "5^20", "5^26"],
    "6": ["6^6", "6^7", "6^15", "6^20", "6^22", "6^35", "6^42", "6^50", "6^57"],
    "7": ["7^7", "7^8", "7^21", "7^35", "7^120"],
    "8": ["8^8", "8^9", "8^28", "8^56", "8^70", "8^247"],
}

export const mixedCombine2Free: {
    [key: string]: string[]
} = {
    "3^3": ["2^1"],
    "3^4": ["2^1", "3^1"],
    "4^4": ["3^1"],
    "4^5": ["3^1", "4^1"],
    "4^6": ["2^1"],
    "4^11": ["2^1", "3^1", "4^1"],
    "5^5": ["4^1"],
    "5^6": ["4^1", "5^1"],
    "5^10": ["2^1"],
    "5^16": ["3^1", "4^1", "5^1"],
    "5^20": ["2^1", "3^1"],
    "5^26": ["2^1", "3^1", "4^1", "5^1"],
    "6^6": ["5^1"],
    "6^7": ["5^1", "6^1"],
    "6^15": ["2^1"],
    "6^20": ["3^1"],
    "6^22": ["4^1", "5^1", "6^1"],
    "6^35": ["2^1", "3^1"],
    "6^42": ["3^1", "4^1", "5^1", "6^1"],
    "6^50": ["2^1", "3^1", "4^1"],
    "6^57": ["2^1", "3^1", "4^1", "5^1", "6^1"],
    "7^7": ["6^1"],
    "7^8": ["6^1", "7^1"],
    "7^21": ["5^1"],
    "7^35": ["4^1"],
    "7^120": ["2^1", "3^1", "4^1", "5^1", "6^1", "7^1"],
    "8^8": ["7^1"],
    "8^9": ["7^1", "8^1"],
    "8^28": ["6^1"],
    "8^56": ["5^1"],
    "8^70": ["4^1"],
    "8^247": ["2^1", "3^1", "4^1", "5^1", "6^1", "7^1", "8^1"],
}