import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { getTime, splitArray, splitArrayByN } from "../../utils";
import { cnNameMap } from "../../constant/cnNameMap";
import InputModal from "../../components/InputModal";

import { NavBar, Stepper, Toast } from "antd-mobile";
import "./BonusOptimization.css";
import { PlayType } from "../../constant/betPlayType";

// 计算当前数组的平均值
function calculateAverage(arr: any[]) {
    let totalValue = 0;
    let totalCount = 0;

    for (const item of arr) {
        totalValue += item.bonus * item.times;
        totalCount += item.times;
    }

    return totalValue / totalCount;
};

function allAboveAmount(arr: any[], amount: number) {
    for (const item of arr) {
        let value = item.bonus * item.times;
        if(value < amount) return false
    };
    return true
}

// 找到使整体更趋于平均值的项，并将其数量加一
function addItemCountToAverage(arr: any[]) {
    const average = calculateAverage(arr);

    let minDifference = Infinity;
    let targetIndex = -1;

    // 遍历每一项，找到使整体更趋于平均值的项
    for (let i = 0; i < arr.length; i++) {
        const currentItem = arr[i];
        const currentValue = currentItem.bonus;

        // 假设将当前项的数量加一后的差值
        const newCount = currentItem.times + 1;
        const newValue = currentValue * newCount;
        const newDifference = newValue - average;

        if (newDifference < minDifference) {
        minDifference = newDifference;
        targetIndex = i;
        }
    }
    // 将目标项的数量加一
    if (targetIndex !== -1) {
        arr[targetIndex].times++;
    }
}

type OptimizationType = "avg" | "hot" | "cold";
export default function BonusOptimization() {
    const location = useLocation();
    const navigate = useNavigate();
    const { amount, closeTime, subType, items, selectedOptions, type, matchGroupById } = location.state;
    const [list, setList] = useState<any[]>([]);
    const [times, setTimes] = useState(1);
    const [inputModalVisible, setInputModalVisible] = useState(false);
    const [optimizationItems, setOptimizationItems] = useState<any[]>([]);
    const [optimizationType, setOptimizationType] = useState<OptimizationType>("avg");
    const [optimizationAmount, setOptimizationAmount] = useState<number>(amount);
    function updateList() {
        const _list = [];
        const matchList = Object.keys(items);
        let enableSingle = false;
        let _selectedOptions = selectedOptions;
        if(_selectedOptions.includes(1)) {
            enableSingle = true;
            _selectedOptions = _selectedOptions.filter((val: number) => val !== 1);
        }
        if(enableSingle){
            for(let match of matchList) {
                const item = items[match];
                const typeList = Object.keys(item);
                for(let type of typeList) {
                    const targetList = item[type].target;
                    for(let target of targetList) {
                        const bonus = matchGroupById[match]["odds"][type][target];
                        _list.push({
                            option: 1,
                            bonus: bonus * 2,
                            items: [{
                                match,
                                type,
                                target,
                                bonus: bonus,
                            }]
                        })
                    }
                }
            }
        }
        let map:any = {};
        for(let match of matchList) {
            const item = items[match];
            const typeList = Object.keys(item);
            let matchTargetList = [];
            for(let type of typeList) {
                const targetList = item[type].target;
                for(let target of targetList) {
                    matchTargetList.push({
                        match,
                        type,
                        target,
                        bonus: matchGroupById[match]["odds"][type][target],
                    })
                }
            }
            map[match] = matchTargetList;
        }
        let targetArr = Object.values(map);
        for(let option of _selectedOptions) {
            const arrSplitByOption = splitArrayByN(targetArr, option);
            for(let arrSplit of arrSplitByOption) {
                const arrCombine = splitArray(arrSplit);
                for(let arr of arrCombine) {
                    let totalBonus = 2;
                    for(let target of arr) {
                        totalBonus *= target.bonus;
                    }
                    _list.push({
                        option,
                        bonus: totalBonus,
                        items: arr
                    })
                }
            }
        }
        _list.sort(((a, b) => a.bonus - b.bonus ));
        setList(_list);
    };

    function optimization() {
        if(list.length === 0) return;
        let _items = [];
        let orderNum = optimizationAmount / 2 - list.length;
        
        for(let i of list) {
            _items.push({
                ...i,
                times: 1,
                totalBonus: i.bonus,                
            })
        }
        while(orderNum > 0) {
            addItemCountToAverage(_items);
            orderNum--;
            if(allAboveAmount(_items, optimizationAmount)) break;
        }
        if(orderNum > 0) {
            if(optimizationType === "avg") {
                while(orderNum > 0) {
                    addItemCountToAverage(_items);
                    orderNum--;
                }
            }
            if(optimizationType === "hot") {
                _items[0].times += orderNum;
                _items[0].totalBonus = _items[0].bonus * _items[0].times;
            }
            if(optimizationType === "cold") {
                let len = _items.length;
                _items[len - 1].times += orderNum;
                _items[len - 1].totalBonus = _items[len - 1].bonus * _items[len - 1].times;
            }
        };
        setOptimizationItems(_items);
    }

    async function handleOrderClick() {
        if(optimizationAmount < amount) {
            Toast.show(`金额不能少于${amount}`);
            return;
        }
        const _optimizationItems = [];
        for(let optimizationItem of optimizationItems) {
            if(optimizationItem.times > 0) {
                _optimizationItems.push({
                    times: optimizationItem.times * times,
                    options: `${optimizationItem.option}^1`,
                    items: optimizationItem.items.map((item: any) => {
                        return {
                            "match": item.match,
                            "type": item.type,
                            "target": [item.target]
                        }
                    })
                });
            }
        }
        let data = {
            type,
            subType,
            playType: PlayType.BonusOptimization,
            times,
            closeTime,
            options: [...selectedOptions].map((val: number) => `${val}^1`).join("_"),
            items,
            optimizationTickets: _optimizationItems,
        }
        navigate("/person/pay", { state: { data, type, subType, amount: optimizationAmount * times, closeTime} });
    }

    const handleAmountChange = (val: number) => {
        if(val % 2 > 0) {
            val++;
        }
        setOptimizationAmount(val);
        updateList();
    };

    const handleItemTimesChange = (index: number, newTimes: number) => {
        const item = optimizationItems[index];
        const timesdelta = newTimes - item.times;
        item.times = newTimes;
        setOptimizationItems([...optimizationItems]);
        setOptimizationAmount(optimizationAmount + timesdelta * 2);
    }
    
    useEffect(() => {
        updateList();
    }, [])

    useEffect(() => {
        optimization();
    }, [list, optimizationType]);

    const back = () => {
        navigate(-1);
    };

    return (
        <div className="bonus-optimization">
            <NavBar
                onBack={back} >
                奖金优化
            </NavBar>
            <div style={{ paddingBottom: "100px" }}>
                <div className="jz_tzjz">{getTime(closeTime)}截止，请尽快提交投注</div>
                <div className="tc planBuyBox">
                    <span>计划购买金额</span>
                    <Stepper min={amount} step={2} value={optimizationAmount} onChange={handleAmountChange}/>
                </div>
                <div className="jjyhTabBox">
                    <div className={`item ${optimizationType === "avg" ? "cur" : ""}`} onClick={() => { setOptimizationType("avg") }}>平均优化</div>
                    <div className={`item ${optimizationType === "hot" ? "cur" : ""}`} onClick={() => { setOptimizationType("hot") }}>博热优化</div>
                    <div className={`item ${optimizationType === "cold" ? "cur" : ""}`} onClick={() => { setOptimizationType("cold") }}>博冷优化</div>
                </div>
                <div className="jjyhTable">
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <tr className="head">
                            <td>过关</td>
                            <td>单注组合</td>
                            <td>倍数</td>
                            <td>预计奖金</td>
                        </tr>
                        {
                            optimizationItems.map((optimizationItem, index) => {
                                const { option, bonus, items, times } = optimizationItem;
                                return (
                                    <tr style={{ borderTop: "1px solid rgb(234, 234, 234)" }}>
                                        <td>{ option }</td>
                                        <td>
                                            {
                                                items.map((item: any) => {
                                                    const { match, target, type } = item;
                                                    return (
                                                        <p style={{ maxWidth: "160px", textAlign: "left" }}>{`${matchGroupById[match].matchNumStr} ${matchGroupById[match].homeTeamAbbName}=${cnNameMap[`${type}_${target}`] || cnNameMap[target]}`}</p>
                                                    )
                                                })
                                            }
                                        </td>
                                        <td>
                                            <Stepper min={1} step={1} value={times} onChange={(val) => handleItemTimesChange(index, val)}/>
                                        </td>
                                        <td className="c_red">
                                            <p style={{ maxWidth: "78px" }}>{ (bonus * times).toFixed(2) }</p>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
            </div>
            <div className="bottom-bar-top">
                <div onClick={() => setInputModalVisible(true)}>
                    投<span className="txt-primary" style={{ margin: "0px 3px" }}>{ times }</span>倍
                </div>
            </div>
            <div className="bottom-bar-next">
                <div className="bottom-bar-foot">
                    <div className="bottom-bar-foot-left">
                        <div className="jz_select_num" style={{ lineHeight: "46px" }}>
                            金额 <span className="fee" style={{ margin: "0px 3px" }}>{ times * optimizationAmount }</span>元
                        </div>
                    </div>
                    {/* <div className="bottom-bar-foot-tops">
                        <span className="bottom-bar-foot-btn" style={{ fontSize: "15px" }}>保存</span>
                    </div> */}
                    <div className="bottom-bar-foot-next" onClick={handleOrderClick}>下一步</div>
                </div>
            </div>
            {
                inputModalVisible && (
                    <InputModal baseTimes={times} height={85} onConfirmClick={setTimes} onClose={() => setInputModalVisible(false)}/>
                )
            }
        </div>
    )
}