import React, { useEffect, useMemo, useRef, useState } from "react";
import { InfiniteScroll, Mask, NavBar, Toast } from "antd-mobile";
import { DownOutline, UpOutline } from "antd-mobile-icons";
import DatePicker from "../../components/DatePicker.tsx";
import { getDay } from "../../utils/index.ts";
import { getDailyUpdateList } from "../../axios/index.js";
import { useNavigate } from "react-router-dom";

const status2CN = ['未提交', '已提交', '已通过']

export default function DailyUpdateList() {
    const navigate = useNavigate();
    const now = useMemo(() => Date.now(), []);
    const [dateStr, setDateStr] = useState(getDay(now));
    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [dailyUpdateList, setDailyUpdateList] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const pagenation = useRef({ pageNum: 0, loading: false });
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            pageNum: pagenation.current.pageNum,
            pageSize: 20,
            dateStr,
        };
        getDailyUpdateList(query).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
                setHasMore(false);
            } else {
                pagenation.current.pageNum++;
                setDailyUpdateList([...dailyUpdateList, ...data.result]);
                setHasMore(data.result.length === 20);
            }
        }).finally(() => {
            pagenation.current.loading = false;;
        })
    }

    useEffect(() => {
        pagenation.current.pageNum = 0;
        setDailyUpdateList([]);
        setHasMore(true);
    }, [dateStr])
    return (
        <div style={{ height: '100vh', backgroundColor: '#dcdcdc' }}>
            <NavBar onBack={() => navigate(-1)} >
                数据统计
            </NavBar>
            <div>
            {
                    dailyUpdateList.map((dailyUpdate) => {
                        return (
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "10px",
                                padding: "15px",
                                backgroundColor: "white"
                            }} onClick={() => navigate(`/docking/update/detail/${dailyUpdate._id}`)}>
                                <div>
                                    { dailyUpdate.dateStr }
                                </div>
                                <div>
                                    { status2CN[dailyUpdate.status] }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
            <Mask visible={datePickerVisible} onMaskClick={() => setDatePickerVisible(false)}>
                <div>
                    <DatePicker enablePrecision={false} defaultTime={now} onChange={(time) => {
                        setDatePickerVisible(false);
                        setDateStr(getDay(time));
                    }} />
                </div>
            </Mask>
        </div>
    )
}