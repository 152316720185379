import { post } from "./base.js";

/**
 * @param {any} params
 */
export function postTextAudit(params) {
    return post("/user/chat/audit", params);
}


/**
 * @param {any} params
 */
export function postUserChatStatus(params) {
    return post("/user/chat/status", params);
}
