import React from "react";
import { TabBar } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "../assets/user/home.png";
import HomeActive from "../assets/user/home-active.png";
import GroupBuy from "../assets/user/groupbuy.png";
import GroupBuyActive from "../assets/user/groupbuy-active.png";
import Score from "../assets/user/score.png";
import ScoreActive from "../assets/user/score-active.png";
import Person from "../assets/user/person.png";
import PersonActive from "../assets/user/person-active.png";
import FollowBuyActive from "../assets/user/followbuy-active.png";

export default function Footer() {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location

    const setRouteActive = (value: string) => {
        navigate(value)
    }

    const tabs = [
        {
            key: "/",
            title: "首页",
            icon: (active: boolean) => active ? <img src={HomeActive} width={25}/> : <img src={Home} width={25}/>
        },
        {
            key: '/person/groupbuy/hall',
            title: '合买',
            icon: (active: boolean) => active ? <img src={GroupBuyActive} width={25}/> : <img src={GroupBuy} width={25}/>
        },
        {
            key: '/person/followbuy/hall',
            // title: '跟单',
            icon: <img src={FollowBuyActive} width={50} style={{ position: "relative", bottom: "20px" }}/>
        },
        {
            key: '/person/match',
            title: '比赛',
            icon: (active: boolean) => active ? <img src={ScoreActive} width={25}/> : <img src={Score} width={25}/>
        },
        {
            key: '/person/person',
            title: '我的',
            icon: (active: boolean) => active ? <img src={PersonActive} width={25}/> : <img src={Person} width={25}/>
        },
    ]

    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}