import { Button, Collapse, Mask, NavBar, Tabs, Toast, Empty } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMatch } from "../../axios";
import { getTimeInDay } from "../../utils";
import { matchInfo, matchItem, matchItemMap } from "../../interface";
import BasketballMixedModal from "./basketballMixedModal";
import BasketballScoredModal from "./basketballScoreModal";
import { cnNameMap } from "../../constant/cnNameMap";

export default function Basketball() {
    const navigate = useNavigate();
    const location = useLocation();
    const shop = useSelector((state) => state.shop.shop);
    const earlyCloseTime = useMemo(() => ( shop.config?.["basketball"]?.["earlyCloseTime"] || 5 * 60 * 1000 ),[shop]);
    const _selectedItems = useMemo(() => location.state?.selectedItems || {}, [location.state]);
    const [currentSubType, setCurrentSubType] = useState<string>("mixed");
    const [matchList, setMatchList] = useState<[string, matchInfo[]][]>();
    const [matchIdList, setMatchIdList] = useState<string[]>();
    const [matchCloseTime, setMatchCloseTime] = useState<{ [key: string]: number }>({});
    const [selectedItems, setSelectedItems] = useState<matchItemMap>(_selectedItems);
    const [selectedMatchNum, setSelectedMatchNum] = useState<number>(0);
    const [single, setSingle] = useState(false);
    const [canDoOrder, setCanDoOrder] = useState(false);
    const [mixedModalVisible, setMixedModalVisible] = useState(false);
    const [mixedModalItem, setMixedModalItem] = useState<any>();
    const [mixedModalMatch, setMixedModalMatch] = useState<any>();
    const [scoreModalVisible, setScoreModalVisible] = useState(false);
    const [scoreModalItem, setScoreModalItem] = useState<any>();
    const [scoreModalMatch, setScoreModalMatch] = useState<any>();
    const [multiCountAry, setMultiCountAry] = useState<{ [key: string]: number }>({});
    const back = () => {
        navigate("/");
    };

    useEffect(() => {
        getMatch({ type: "basketball" }).then(({data}: { data: { matchList:matchInfo[] } }) => {
            const { matchList } = data;
            const matchGroupByDate: { [key: string] : matchInfo[] } = {};
            const _matchCloseTime: { [key: string]: number } = {};
            for(let match of matchList) {
                const { businessDate, _id, closeTime, odds } = match;
                if (!odds) { continue };
                _matchCloseTime[_id] = closeTime;
                if(matchGroupByDate[businessDate]) {
                    matchGroupByDate[businessDate].push(match)
                } else {
                    matchGroupByDate[businessDate] = [match];
                }
            }
            setMatchCloseTime(_matchCloseTime);
            const _matchList = Object.entries(matchGroupByDate).sort((a, b) => {
                const dateA = new Date(a[0]).getTime();
                const dateB = new Date(b[0]).getTime();
                return dateA - dateB;
            });;
            setMatchList(_matchList);
            let _matchIdList: string[]  = [];
            for(let matchInfoList of _matchList) {
                _matchIdList = _matchIdList.concat(matchInfoList[1].map((match) => match._id));
            };
            setMatchIdList(_matchIdList);
        });
    }, []);

    useEffect(() => {
        let enableSingle = true;
        let num = 0;
        const _multiCountAry:{[key: string]: number} = {};
        const matchList = Object.keys(selectedItems);
        for(let match of matchList) {
            const item = selectedItems[match];
            let types = Object.keys(item).filter(type => item[type].target.size);
            if(types.length > 0) {
                _multiCountAry[match] = 0;
                num++;
                for(let type of types) {
                    _multiCountAry[match] += item[type].target.size;
                    enableSingle = enableSingle && selectedItems[match][type].single
                }
            }
        }
        enableSingle = num > 0 && enableSingle;
        setSelectedMatchNum(num);
        setSingle(enableSingle);
        setMultiCountAry(_multiCountAry);
        if (num > 1 || enableSingle) {
            setCanDoOrder(true);
        } else {
            setCanDoOrder(false);
        }
    }, [selectedItems]);

    const handleTabChange = async(data: string) => {
        setSelectedItems({});
        setCurrentSubType(data);
    }

    const handleItemClick = async(matchId: string, type: string, target: string, item: any) => {
        if(selectedMatchNum === 8 && !multiCountAry[matchId]) {
            Toast.show("最多只能选择8场比赛");
            return;
        }
        if(selectedItems[matchId]) {
            if(selectedItems[matchId][type]) {
                if (selectedItems[matchId][type].target.has(target)) {
                    selectedItems[matchId][type].target.delete(target);
                } else {
                    selectedItems[matchId][type].target.add(target)
                }
            } else {
                selectedItems[matchId][type] = {
                    type,
                    goalLine: item.goalLine,
                    single: item.single,
                    target: new Set([target]),
                    match: matchId,
                }
            }
        } else {
            multiCountAry[matchId] = 1;
            selectedItems[matchId]= {
                [type]: {
                    type,
                    goalLine: item.goalLine,
                    single: item.single,
                    target: new Set([target]),
                    match: matchId,
                }
            }
        };
        setMultiCountAry({ ...multiCountAry });
        setSelectedItems({ ...selectedItems });
    }

    const handleMixedPopupClick = (match: matchInfo) => {
        setMixedModalItem({...selectedItems[match._id]} || {});
        setMixedModalMatch(match);
        setMixedModalVisible(true);
    }

    const handleMixedModalConfirm = async (matchId: string, item: matchItem) => {
        if(selectedMatchNum === 8 && !multiCountAry[matchId]) {
            Toast.show("最多只能选择8场比赛");
            setMixedModalVisible(false);
            return;
        }
        selectedItems[matchId] = item;
        setSelectedItems({...selectedItems});
        setMixedModalVisible(false);
    };

    const handleMixedModalCancel = async () => {
        setMixedModalVisible(false);
    }

    const handleScorePopupClick = (match: matchInfo) => {
        setScoreModalItem({...selectedItems[match._id]} || {});
        setScoreModalMatch(match);
        setScoreModalVisible(true);
    }

    const handleScoreModalConfirm = async (matchId: string, item: matchItem) => {
        selectedItems[matchId] = item;
        setSelectedItems({...selectedItems});
        setScoreModalVisible(false);
        console.log(selectedItems);
    };

    const handleScoreModalCancel = async () => {
        setScoreModalVisible(false);
    }

    const handleTakeOrderClick = async() => {
        const now = Date.now();
        if(!canDoOrder) {
            Toast.show("请选择一场单关或两场比赛");
            return;
        }
        const _matchIdList = matchIdList?.filter((matchId) => selectedItems[matchId]);
        if(!_matchIdList || _matchIdList.length === 0) {
            Toast.show("请选择一场单关或两场比赛");
            return;
        }
        let items: matchItemMap = {};
        let closeTime = Infinity;
        for (let matchId of _matchIdList) {
            const selectedItem = selectedItems[matchId];
            let types = Object.keys(selectedItem).filter(type => selectedItem[type].target.size);
            if (types.length > 0) {
                closeTime = Math.min(closeTime, matchCloseTime[matchId]);
                items[matchId] = {};
                for (let type of types) {
                    items[matchId][type] = { ...selectedItem[type] };
                    items[matchId][type].target = [...items[matchId][type].target]
                }
            }
        }
        closeTime = closeTime - earlyCloseTime;
        if(now > closeTime) {
            Toast.show("包含已截止比赛");
            return;
        }
        navigate("/person/betsummarize", { state: { items, type: "basketball", subType: currentSubType, closeTime, single } })
    }
    return (<div className="basketball-container">
        <NavBar onBack={back}>
            竞彩篮球
        </NavBar>
        <Tabs defaultActiveKey='mixed' onChange={handleTabChange}>
            <Tabs.Tab title="混合过关" key="mixed">
                <Collapse defaultActiveKey={['0']}>
                    { matchList && matchList.map((match, index) => {
                            const [date, _matchList] = match;
                            return (
                                <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}> 
                                {
                                    _matchList && _matchList.map((match: any) => {
                                        const { odds: { mnl, hdc, hilo } } = match;
                                        return (
                                            <div className="item_body">
                                                <div className="left">
                                                    <p>{ match.matchNumStr }</p>
                                                    <p style={{ color: "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                                    <p style={{ marginTop: "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                                </div>
                                                <div className="right">
                                                    <div className="team">
                                                        <span>
                                                            <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[客]</span>
                                                            {match.awayTeamAbbName}
                                                        </span>
                                                        <span style={{ color: "rgb(153, 153, 153)" }}>VS</span>
                                                        <span>
                                                            {match.homeTeamAbbName}
                                                            <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[主]</span>
                                                        </span>
                                                    </div>
                                                    <div className="game_table">
                                                        <table className="game_data game_data1">
                                                            <tr className="active23" style={{height: "36px"}}>
                                                                <td width="20%" style={{ color: "white", background: "rgb(119, 50, 177)" }} className="">胜负</td>
                                                                {
                                                                    (mnl.goalLine !== undefined) ? (
                                                                        <>
                                                                            <td width="39%" className={selectedItems[match._id]?.["mnl"]?.target.has("lose") ? "active22" : ""}>
                                                                                <div className="flex flex-col" onClick={() => handleItemClick(match._id, "mnl", "lose", mnl)}>
                                                                                    <span>客胜</span>
                                                                                    <div className="c_999">{mnl.lose}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td width="40%" className={selectedItems[match._id]?.["mnl"]?.target.has("win") ? "active22" : ""}>
                                                                                <div className="flex flex-col" onClick={() => handleItemClick(match._id, "mnl", "win", mnl)}>
                                                                                    <span>主胜</span>
                                                                                    <div className="c_999">{mnl.win}</div>
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <td colSpan={2}>胜负未开售</td>
                                                                    )
                                                                }
                                                                
                                                            </tr>
                                                            <tr className="active23">
                                                                <td width="20%" className="c_999"
                                                                    style={{ color: "white", background: "rgb(47, 66, 175)" }}>让分</td>
                                                                {
                                                                    (hdc.goalLine !== undefined) ? (
                                                                        <>
                                                                            <td width="39%" className={selectedItems[match._id]?.["hdc"]?.target.has("lose") ? "active22" : ""}>
                                                                                <div className="flex flex-col" onClick={() => handleItemClick(match._id, "hdc", "lose", hdc)}>
                                                                                    <span>客胜</span>
                                                                                    <div className="c_999">{hdc.lose}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td width="40%" className={selectedItems[match._id]?.["hdc"]?.target.has("win") ? "active22" : ""}>
                                                                                <div className="flex flex-col" onClick={() => handleItemClick(match._id, "hdc", "win", hdc)}>
                                                                                    <span>
                                                                                        <span>主胜</span>
                                                                                        (<span className={Number(hdc.goalLine) > 0 ? "green_score" : "purple_score"}>{hdc.goalLine}</span>)
                                                                                    </span>
                                                                                    <div className="c_999">{hdc.win}</div>
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <td colSpan={2}>让分未开售</td>
                                                                    )
                                                                }
                                                            </tr>
                                                            <tr className="active23">
                                                                <td style={{ background: "rgb(28, 175, 42)", color: "white" }}>大小分</td>
                                                                {
                                                                    (hdc.goalLine !== undefined) ? (
                                                                        <>        
                                                                            <td width="39%" className={selectedItems[match._id]?.["hilo"]?.target.has("high") ? "active22" : ""}>
                                                                                <div className="flex flex-col" onClick={() => handleItemClick(match._id, "hilo", "high", hilo)}>
                                                                                    <span>大于{Number(hilo.goalLine)}</span>
                                                                                    <div className="c_999">{hilo.high}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td width="40%" className={selectedItems[match._id]?.["hilo"]?.target.has("low") ? "active22" : ""}>
                                                                                <div className="flex flex-col" onClick={() => handleItemClick(match._id, "hilo", "low", hilo)}>
                                                                                    <span>小于{Number(hilo.goalLine)}</span>
                                                                                    <div className="c_999">{hilo.low}</div>
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <td colSpan={2}>大小分未开售</td>
                                                                    )
                                                                }
                                                            </tr>
                                                            <tr style={{ height: "43px" }}>
                                                                <td style={{ background: "rgb(255, 95, 39)", color: "white" }}>胜分差</td>
                                                                <td colSpan={2} onClick={() => handleMixedPopupClick(match)}>展开胜分差玩法</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    {
                                                        mnl.single && (
                                                            <div className="jz_item_mark">单关</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <Mask visible={mixedModalVisible}>
                                    <BasketballMixedModal item={mixedModalItem} matchInfo={mixedModalMatch} onConfirm={handleMixedModalConfirm} onCancel={handleMixedModalCancel}/>
                                </Mask>
                                </Collapse.Panel>
                            )
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
            <Tabs.Tab title="胜负(让)" key="mnl">
                <Collapse defaultActiveKey={['0']}>
                    { matchList && matchList.map((match, index) => {
                            const [date, _matchList] = match;
                            return (
                                <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}> 
                                {_matchList && _matchList.map((match: any) => {
                                    const { odds: { mnl, hdc } } = match;
                                    return (
                                        <div className="item_body">
                                            <div className="left">
                                                <p>{ match.matchNumStr }</p>
                                                <p style={{ color: "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                                <p style={{ marginTop: "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                            </div>
                                            <div className="right">
                                                <div className="team">
                                                    <span>
                                                        <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[客]</span>
                                                        {match.awayTeamAbbName}
                                                    </span>
                                                    <span style={{ color: "rgb(153, 153, 153)" }}>VS</span>
                                                    <span>
                                                        {match.homeTeamAbbName}
                                                        <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[主]</span>
                                                    </span>
                                                </div>
                                                <div className="game_table">
                                                    <table className="game_data game_data1">
                                                        <tr className="active23">
                                                            <td width="8%" style={{ "color": "rgb(133, 179, 209)" }} className="c_999 first-child">胜负</td>
                                                            {
                                                                (mnl.goalLine !== undefined) ? (
                                                                    <>
                                                                        <td width="46%" className={selectedItems[match._id]?.["mnl"]?.target.has("lose") ? "active22" : ""} onClick={() => handleItemClick(match._id, "mnl", "lose", mnl)}>客胜<div className="c_999">{mnl.lose}</div></td>
                                                                        <td width="46%" className={selectedItems[match._id]?.["mnl"]?.target.has("win") ? "active22" : ""} onClick={() => handleItemClick(match._id, "mnl", "win", mnl)}>主胜<div className="c_999">{mnl.win}</div></td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan={2}>胜负未开售</td>
                                                                )
                                                            }
                                                        </tr>
                                                        <tr className="active23">
                                                            <td width="8%" className="c_999 bg_blue" style={{ "color": "rgb(255, 255, 255)", "backgroundColor": "rgb(255, 115, 64)" }}>让分</td>
                                                            {
                                                                (hdc.goalLine !== undefined) ? (
                                                                    <>
                                                                        <td width="46%" className={selectedItems[match._id]?.["hdc"]?.target.has("lose") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hdc", "lose", hdc)}>客胜<div className="c_999">{hdc.lose}</div></td>
                                                                        <td width="46%" className={selectedItems[match._id]?.["hdc"]?.target.has("win") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hdc", "win", hdc)}>主胜<div className="c_999">(<span className={Number(hdc.goalLine) > 0 ? "green_score" : "purple_score"}><span>{hdc.goalLine}</span></span>){hdc.win}</div></td>
                                                                    </>
                                                                ) : (
                                                                    <td colSpan={2}>让分未开售</td>
                                                                )
                                                            }
                                                        </tr>
                                                    </table>
                                                </div>
                                                {
                                                    (mnl.single) && (
                                                        <div className="jz_item_mark">单关</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                </Collapse.Panel>
                            );
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
            <Tabs.Tab title="大小分" key="hilo">
                <Collapse defaultActiveKey={['0']}>
                    { matchList && matchList.map((match, index) => {
                        const [date, _matchList] = match;
                        const type = "hilo";
                        return (
                            <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}> 
                            {_matchList && _matchList.filter((match) => match.odds.hilo.goalLine !== undefined).map((match: any) => {
                                const { odds: { hilo } } = match;
                                return (
                                    <div className="item_body">
                                        <div className="left">
                                            <p>{ match.matchNumStr }</p>
                                            <p style={{ color: "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                            <p style={{ marginTop: "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                        </div>
                                        <div className="right">
                                            <div className="team">
                                                <span>
                                                    <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[客]</span>
                                                    {match.awayTeamAbbName}
                                                </span>
                                                <span style={{ color: "rgb(153, 153, 153)" }}>VS</span>
                                                <span>
                                                    {match.homeTeamAbbName}
                                                    <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[主]</span>
                                                </span>
                                            </div>
                                            <div className="game_table">
                                                <table className="game_data game_data1">
                                                    <tr className="game_table-text">
                                                        <td width="50%" className={selectedItems[match._id]?.[type]?.target.has("high") ? "active22" : ""}
                                                            onClick={() => handleItemClick(match._id, type, "high", hilo)}>大于{Number(hilo.goalLine)}<div className="c_999" style={{ "marginLeft": "5px" }}>{hilo.high}</div></td>
                                                        <td width="50%" className={selectedItems[match._id]?.[type]?.target.has("low") ? "active22" : ""}
                                                            onClick={() => handleItemClick(match._id, type, "low", hilo)}>小于{Number(hilo.goalLine)}<div className="c_999" style={{ "marginLeft": "5px" }}>{hilo.low}</div></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            {
                                                hilo.single && (
                                                    <div className="jz_item_mark">单关</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            </Collapse.Panel>
                        );
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
            <Tabs.Tab title="胜分差" key="wnm">
                <Collapse defaultActiveKey={['0']}>
                    { matchList && matchList.map((match, index) => {
                        const [date, _matchList] = match;
                        return (
                            <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}> 
                            {_matchList && _matchList.filter((match) => match.odds.wnm.goalLine !== undefined).map((match: any) => {
                                const { odds: { wnm } } = match;
                                return (
                                    <div className="item_body">
                                        <div className="left">
                                            <p>{ match.matchNumStr }</p>
                                            <p style={{ color: "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                            <p style={{ marginTop: "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                        </div>
                                        <div className="right">
                                            <div className="team">
                                                <span>
                                                    <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[客]</span>
                                                    {match.awayTeamAbbName}
                                                </span>
                                                <span style={{ color: "rgb(153, 153, 153)" }}>VS</span>
                                                <span>
                                                    {match.homeTeamAbbName}
                                                    <span style={{ color: "rgb(153, 153, 153)", fontSize: "13px" }}>[主]</span>
                                                </span>
                                            </div>
                                            <div className="game_btn">
                                                {
                                                    selectedItems[match._id]?.["wnm"]?.target.size ? (
                                                        <div className="jz_select_bf" style={{"width":"85%"}} onClick={() => handleScorePopupClick(match)}>
                                                            <div className="ac">
                                                                {
                                                                    [...selectedItems[match._id]?.["wnm"]?.target].map(val => cnNameMap[val]).join(",")
                                                                }
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div className="jz_select_bf" style={{"width":"85%"}} onClick={() => handleScorePopupClick(match)}>点击选择胜分差</div>
                                                    )
                                                }
                                            </div>
                                            {
                                                wnm.single && (
                                                    <div className="jz_item_mark">单关</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                                <Mask visible={scoreModalVisible}>
                                    <BasketballScoredModal item={scoreModalItem} matchInfo={scoreModalMatch} onConfirm={handleScoreModalConfirm} onCancel={handleScoreModalCancel}/>
                                </Mask>
                            </Collapse.Panel>
                        );
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
        </Tabs>
        <div>
            <div className="order-info-container">
                <Button fill='outline' color="primary" onClick={() => setSelectedItems({})}>清空</Button>
                <div style={{textAlign: "center", width: "200px"}}>
                    <div>已选择{selectedMatchNum}场</div>
                    <div>至少选1场单关或两场比赛</div>
                </div>
                <div>
                    <Button color="primary" onClick={handleTakeOrderClick}>下单</Button>
                </div>
            </div>
        </div>
    </div>
    )
}