import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { NavBar, Radio, Switch, TextArea, Toast } from "antd-mobile";
import "./PublishGroupBuy.css"
import { PlayType } from "../../constant/betPlayType";

export default function PublishGroupBuy() {
    const navigate = useNavigate();
    const location = useLocation();
    // const [amount, setAmount] = useState(120);
    const [totalCopies, setTotalCopies] = useState(20);
    const [costPerCopy, setCostPerCopy] = useState(0);
    const [totalPurchasedCopies, setTotalPurchasedCopies] = useState(10);
    const [guaranteedCopies, setGuaranteedCopies] = useState(0);
    const [message, setMessage] = useState("大家一起中奖吧!");
    const [commissionPercentage, setCommissionPercentage] = useState(0);
    const [visibleRange, setVisibleRange] = useState(3);
    const [guaranteedVisible, setGuaranteedVisible] = useState(false);
    const [isFullGuarantee, setIsFullGuarantee] = useState(false);
    const [commissionPercentageVisible, setCommissionPercentageVisible] = useState(false);
    const items = useMemo(() => {
        return location.state.items;
    }, [location.state])

    const amount = useMemo(() => {
        return location.state.amount
    }, [location.state])

    const times = useMemo(() => {
        return location.state.times
    }, [location.state])

    const type = useMemo(() => {
        return location.state.type
    }, [location.state])

    const subType = useMemo(() => {
        return location.state.subType
    }, [location.state])

    const selectedOptions = useMemo(() => {
        return location.state.selectedOptions
    }, [location.state])

    const closeTime = useMemo(() => {
        return location.state.closeTime
    }, [location.state])

    const handlePurchasedCopies = (num: number) => {
        if (num > totalCopies) {
            Toast.show("超过方案金额");
            return;
        }
        setTotalPurchasedCopies(num);
    }

    const handleFullGuarantee = () => {
        setIsFullGuarantee(true);
        setGuaranteedCopies(totalCopies - totalPurchasedCopies);
    }

    const handleGuaranteedCopiesChange = (e) => {
        let val = Number(e.target.value);
        if (val > totalCopies - totalPurchasedCopies) {
            handleFullGuarantee();
        } else {
            setGuaranteedCopies(val);
        }
    }

    useEffect(() => {
        setCostPerCopy(amount / totalCopies);
    }, [amount, totalCopies])

    useEffect(() => {
        if (totalPurchasedCopies + guaranteedCopies < totalCopies || guaranteedCopies === 0) {
            setIsFullGuarantee(false);
        } else if (totalPurchasedCopies + guaranteedCopies > totalCopies) {
            setGuaranteedCopies(totalCopies - totalPurchasedCopies);
        }
    }, [totalCopies, totalPurchasedCopies, guaranteedCopies])

    const handleOrderClick = async () => {
        let data = {
            type,
            subType,
            playType: PlayType.GroupBuy,
            times,
            closeTime,
            options: selectedOptions.join("_"),
            items,
            groupBuyInfo: {
                amount,
                totalCopies,
                totalPurchasedCopies,
                isFullGuarantee,
                guaranteedCopies,
                commissionPercentage,
                visibleRange,
                closeTime,
                message,
            }
        }
        navigate("/person/pay", { state: { data, type, subType, amount: costPerCopy * (totalPurchasedCopies + guaranteedCopies), closeTime } });
    }
    return (
        <div className="aui-flexView publish-group-buy-container">
            <NavBar
                onBack={() => navigate(-1)}>
                发起合买
            </NavBar>
            <div className="buyView">
                <div className="buyView-top">
                    <div>方案总额</div>
                    <div>{amount}元</div>
                </div>
                <div className="buyView-TopBtn">
                    <div className="buyView-TopBtn-top">
                        <div>分成几份</div>
                        <div>
                            <div onClick={() => setTotalCopies(10)} className={totalCopies === 10 ? "active22 bar1" : "bar1"}>10份</div>
                            <div onClick={() => setTotalCopies(20)} className={totalCopies === 20 ? "active22 bar1" : "bar1"}>20份</div>
                            <div onClick={() => setTotalCopies(50)} className={totalCopies === 50 ? "active22 bar1" : "bar1"}>50份</div>
                            {/* <div>
                                <div className="uni-input">
                                    <div className="uni-input-wrapper">
                                        <div className="uni-input-placeholder input-placeholder">自定义</div>
                                        <input maxLength={6} step="0.000000000000000001" enterKeyHint="done" pattern="[0-9]*" autoComplete="off" type="number" className="uni-input-input" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="buyView-TopText">
                        <div></div>
                        <div>每份{costPerCopy}元</div>
                    </div>
                    <div className="buyView-TopBtn-top">
                        <div>自购几份</div>
                        <div>
                            <div onClick={() => handlePurchasedCopies(10)} className={totalPurchasedCopies === 10 ? "active22 bar1" : "bar1"}>10份</div>
                            <div onClick={() => handlePurchasedCopies(20)} className={totalPurchasedCopies === 20 ? "active22 bar1" : "bar1"}>20份</div>
                            <div onClick={() => handlePurchasedCopies(50)} className={totalPurchasedCopies === 50 ? "active22 bar1" : "bar1"}>50份</div>
                            {/* <div>
                                <div className="uni-input">
                                    <div className="uni-input-wrapper">
                                        <div className="uni-input-placeholder input-placeholder">自定义</div>
                                        <input maxLength={6} step="0.000000000000000001" enterKeyHint="done" pattern="[0-9]*" autoComplete="off" type="number" className="uni-input-input" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="buyView-TopText">
                        <div></div>
                        <div>自购{(costPerCopy * totalPurchasedCopies).toFixed(2)}元</div>
                    </div>
                    <div className="buyView-foot">
                        <div>合买保底</div>
                        <div>
                            <Switch onChange={(val) => setGuaranteedVisible(val)}/>
                        </div>
                    </div>
                    {
                        guaranteedVisible && (
                            <div className="buyView-footShow">
                                <div>保底金额{ (costPerCopy * guaranteedCopies).toFixed(2) }元</div>
                                <div>
                                    <div onClick={handleFullGuarantee} className={ isFullGuarantee ? "active22 full" : "full" }>全额保底</div>
                                    <div className="uni-input">
                                        <div className="uni-input-wrapper">
                                            {/* {
                                                (guaranteedCopies) && (
                                                    <div className="uni-input-placeholder input-placeholder">自定义</div>
                                                )
                                            } */}
                                            <input 
                                                maxLength={6} 
                                                step="0.000000000000000001" 
                                                enterKeyHint="done" 
                                                pattern="[0-9]*" 
                                                autoComplete="off" 
                                                type="number" 
                                                className="uni-input-input" 
                                                value={guaranteedCopies}
                                                onChange={(e) => handleGuaranteedCopiesChange(e)}/>
                                        </div>
                                    </div>份
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="buyView-main">
                    <div className="buyView-main-top">
                        <div>盈利佣金</div>
                        <div>
                            <Switch onChange={(val) => setCommissionPercentageVisible(val)}/>
                        </div>
                    </div>
                    {
                        commissionPercentageVisible && (
                            <div className="buyView-main-btn">
                                <div onClick={() => setCommissionPercentage(0.01)} className={commissionPercentage === 0.01 ? "active22": ""}>1%</div>
                                <div onClick={() => setCommissionPercentage(0.02)} className={commissionPercentage === 0.02 ? "active22": ""}>2%</div>
                                <div onClick={() => setCommissionPercentage(0.03)} className={commissionPercentage === 0.03 ? "active22": ""}>3%</div>
                                <div onClick={() => setCommissionPercentage(0.04)} className={commissionPercentage === 0.04 ? "active22": ""}>4%</div>
                                <div onClick={() => setCommissionPercentage(0.05)} className={commissionPercentage === 0.05 ? "active22": ""}>5%</div>
                            </div>
                        )
                    }
                    <div className="buyView-main-bor">
                        <div>方案内容</div>
                        <div>
                            <div onClick={() => setVisibleRange(1)} className={ visibleRange === 1 ? "active22" : "" }>完全公开</div>
                            <div onClick={() => setVisibleRange(2)} className={ visibleRange === 2 ? "active22" : "" }>截止公开</div>
                            <div onClick={() => setVisibleRange(3)} className={ visibleRange === 3 ? "active22" : "" }>认购可见</div>
                        </div>
                    </div>
                    <div className="buyView-main-text">
                        <div>合买宣言</div>
                        <TextArea
                            defaultValue={message}
                            showCount
                            maxLength={50}
                            onChange={((text) => setMessage(text))}
                        />
                    </div>
                </div>
                <div className="buyView-footBtn">
                    <div className="buyView-footBtn-top">
                        <Radio value='1'>
                            <span style={{ fontSize: "13px" }}>
                                我已年满18岁，已阅读并同意
                                <span style={{ "color": "rgb(41, 121, 255)" }}>
                                    <span>《合买人发起规则》</span>
                                </span>
                            </span>
                        </Radio>
                    </div>
                    <div className="buyView-footBtn-btn">
                        <div>
                            <span>
                                <span>自购+保底金额:</span>
                            </span>
                            <span>
                                <span>{ (costPerCopy * (totalPurchasedCopies + guaranteedCopies)).toFixed(2) }元</span>
                            </span>
                        </div>
                        <div onClick={() => handleOrderClick()}>确认发起</div>
                    </div>
                </div>
            </div>
        </div>
    )
}