import { get } from "./base.js";

/**
 * @param {any} params
 */
export function getChaseNumList(params) {
    return get("/chasenum/list", params)
}

/**
 * @param {any} params
 */
export function getChaseNumDetail(params) {
    return get("/chasenum/detail", params)
}