import React, { useEffect, useState } from "react";
import { Form, NavBar, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getUserShopInfo } from "../../axios";

interface ShopInfo {
    name: string,
    messgae: string,
    privacyInfo: boolean,
    wechat: string,
    realName: string,
    IdCard: string,
    phone: string,
    address: string,
}

export default function ShopInfo() {
    const navigate = useNavigate();
    const [shopInfo, setShopInfo] = useState<ShopInfo>();

    useEffect(() => {
        getUserShopInfo({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorCode);
            } else {
                setShopInfo(data.shop);
            }
        });
    }, [])
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                店铺信息
            </NavBar>
            <Form layout='horizontal' mode='card'>
                <Form.Item label='店铺名称'>
                    { shopInfo?.name }
                </Form.Item>
                <Form.Item label='店主姓名'>
                    { shopInfo?.privacyInfo === false ? shopInfo.realName : "********"}
                </Form.Item>
                <Form.Item label='店铺电话'>
                    { shopInfo?.privacyInfo === false ? shopInfo.phone : "********"}
                </Form.Item>
                <Form.Item label='微信号码'>
                    { shopInfo?.wechat }
                </Form.Item>
                <Form.Item label='店铺地址'>
                    { shopInfo?.privacyInfo === false ? shopInfo.address : "********"}
                </Form.Item>
                <Form.Item label='店铺公告'>
                    { shopInfo?.privacyInfo === false ? shopInfo.messgae : "********"}
                </Form.Item>
            </Form>
        </div>
    )
}