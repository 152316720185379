import { AddOutline, MinusOutline } from "antd-mobile-icons"
import React, { useState, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { cnNameMap } from "../../constant/cnNameMap";

import "./BetSummarizeN.css"
import { getRandom, getTime } from "../../utils";
import InputModal from "../../components/InputModal.tsx";
import ChaseModal from "../../components/ChaseModal.tsx";
import { Modal, NavBar, Toast } from "antd-mobile";
import { PlayType } from "../../constant/betPlayType";
import { useSelector } from "react-redux";
import { postBetOrder } from "../../axios/user.js";

function TargetView(props: { subType: string, target: string }) {
    const { subType, target } = props;
    const [targetArrHundred, targetArrTen, targetArrBit] = target.split("|").map((target) => target.split(","));

    if(subType === "101") {
        return <span className="nub-view" style={{ color: "rgb(255, 0, 0)" }}>{ `${targetArrHundred.join("")} ${targetArrTen.join("")} ${targetArrBit.join("")}` }</span>
    }
    if(["102", "103", "105", "301", "302", "304", "601", "603"].includes(subType)) {
        return <span className="nub-view" style={{ color: "rgb(255, 0, 0)" }}>{ targetArrBit.join(" ") }</span>
    }
    if(["104", "303", "602"].includes(subType)) {
        return (
            <span className="nub-view" style={{ color: "rgb(255, 0, 0)" }}>
                    <span>{ targetArrTen.join(" ") }</span>
                    <span style={{ margin: "0px 5px" }}>#</span>
                    <span style={{ color: "rgb(17, 133, 210)" }}>{ targetArrBit.join(" ") }</span>
            </span>
        )
    }
}

export default function BetSummarizeN() {
    const location = useLocation();
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop.shop);
    const user = useSelector((state) => state.user.user);
    const [inputTimeIndex, setInputTimeIndex] = useState(0);
    const [inputModalVisible, setInputModalVisible] = useState(false);
    const [chaseNum, setChaseNum] = useState(1);
    const [ChaseModalVisible, setChaseModalVisible] = useState(false);
    const [items, setItems] = useState(location.state?.items || []);
    // const term = useMemo(() => location.state?.term, [location.state?.term]);
    const closeTime = useMemo(() => location.state?.closeTime, [location.state?.closeTime]);
    const type = useMemo(() => {
        return location.state?.type;
    }, [location.state?.type])
    const subType = useMemo(() => {
        return location.state?.subType;
    }, [location.state?.subType])
    const amount = useMemo(() => {
        let _amount = 0;
        items.forEach((item) => {
            _amount += item.notes * item.times * 2
        });
        return _amount * chaseNum;
    }, [items, chaseNum]);

    const enableBuy = useMemo(() => {
        return shop.config[type]?.enableBuy || false;
    }, [shop.config, type]);
    const minAmount = useMemo(() => {
        return shop.config[type]?.minAmount || 2;
    }, [shop.config, type]);
    const enableGroupBuy = useMemo(() => {
        return shop.config[type]?.enableGroupBuy || false;
    }, [shop.config, type]);
    const enableFollowBuy = useMemo(() => {
        return shop.config[type]?.enableFollowBuy || false;
    }, [shop.config, type]);
    const enableBonusOptimization = useMemo(() => {
        return shop.config[type]?.enableBonusOptimization || false;
    }, [shop.config, type]);

    const doShowInputModal = (index: number) => {
        setInputTimeIndex(index);
        setInputModalVisible(true);
    }

    const handleSubClick = (index: number) => {
        if(items[index].times > 1) {
            items[index].times--;
            setItems([...items]);
        }
    }

    const handleAddClick = (index: number) => {
        items[index].times++;
        setItems([...items]);
    }
    
    const handleInputConfirm = (val: number, index: number) => {
        items[index].times = val;
        setItems([...items]);
    }

    const handleFollowBuy = () => {
        if(!user.followingStatus) {
            Toast.show("仅特邀用户允许发单！");
            return;
        }
        if(chaseNum > 1) {
            Toast.show("发单不支持追号");
            return;
        }
        navigate("/person/followbuy/publish", 
            { state: 
                {
                    items, 
                    amount: amount, 
                    times: 1, 
                    type, 
                    subType, 
                    closeTime 
                } 
            })
    }

    const handleGroupBuy = () => {
        if(chaseNum > 1) {
            Toast.show("合买不支持追号");
            return;
        }
        navigate("/person/groupbuy/publish",
            { state: 
                {
                    items, 
                    amount, 
                    times: 1, 
                    type, 
                    subType, 
                    closeTime 
                } 
            })
    }

    const handleOrderClick = async() => {
        if(!enableBuy) {
            Toast.show("店主暂停出票");
            return;
        }
        if(minAmount > amount) {
            Toast.show(`最低投注${minAmount}元`);
            return;
        };
        let data = {
            type: type,
            subType: subType,
            playType: chaseNum > 1 ? PlayType.ChaseNum : PlayType.Normal,
            times: 1,
            closeTime,
            items,
            chaseNum: chaseNum > 1 ? chaseNum : null,
        }
        navigate("/person/pay", { state: { data, type, subType: subType, amount: amount, closeTime } });
    }

    const handleSave = () => {
        if(chaseNum > 1) {
            Toast.show("追号不支持保存");
            return;
        }
        Modal.confirm({
            content: '是否保存该方案',
            onConfirm: async () => {
                let data = {
                    type: type,
                    subType: subType,
                    playType: PlayType.Normal,
                    times: 1,
                    closeTime,
                    items,
                    chaseNum,
                    onlySave: true,
                }
                postBetOrder(data).then(({data}) => {
                    if(data.status === 'error') {
                        Toast.show(data.errorMessage);
                        return;
                    }
                    Toast.show("保存成功");
                    navigate(`/person/bet/detail/${data.id}`);
                })
            },
          })
    }

    const handleRandomItemClick = () => {
        const hundred = getRandom(0, 9, 1);
        const ten = getRandom(0, 9, 1);
        const bit = getRandom(0, 9, 1);
        const item = {
            "type": "direct",
            "subType": "101",
            "times": 1,
            "notes": 1,
            "target": `${[...hundred].sort().join(",")}|${[...ten].sort().join(",")}|${[...bit].sort().join(",")}`
        };
        items.push(item);
        setItems([...items]);
    }

    const handleOrderOneMoreClick = () => {
        navigate("/person/three", { state: { items } })
    }

    const back = () => {
        navigate(-1);
    };
    return (
        <div className="bigltView">
            <NavBar
                onBack={back} >
                投注
            </NavBar>
            <div className="nbet-top-stop">{getTime(closeTime)}截止, 请尽快提交投注</div>
            <div className="nbet-top-btn">
                <div onClick={() => handleOrderOneMoreClick()}>+再来一注</div>
                <div onClick={() => handleRandomItemClick()} >+机选一注</div>
            </div>
            <div>
                <div>
                    {
                        items.map((item: any, index: number) => {
                            return (
                                <div className="nbet-top-list">
                                    <div style={{ display: "flex" }}>
                                        <div className="nbet-top-list-icon">
                                            <div data-v-e4d6d362="" className="u-icon u-icon--right">
                                                x
                                            </div>
                                        </div>
                                        <div className="nbet-top-list-right">
                                            <div className="nbet-top-list-right-nub">
                                                <TargetView subType={item.subType} target={item.target} />
                                            </div>
                                            <div className="nbet-top-list-right-money">
                                                <div style={{ color: "rgb(153, 153, 153)" }}>{cnNameMap[item.type]}[{cnNameMap[item.subType]}]</div>
                                                <div style={{ color: "rgb(153, 153, 153)" }}>{item.notes}注</div>
                                                <div style={{ color: "rgb(153, 153, 153)" }}>{ item.times }倍</div>
                                                <div style={{ color: "red" }}>{ item.notes * item.times * 2 }元</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-bar-top-zhuihao-put">
                                        <div className="flex-1 row center align-items-center">
                                            <div className="option-box row center align-items-center"><MinusOutline onClick={() => handleSubClick(index)}/></div>
                                            <div className="option-value-box row center align-items-center" onClick={() => doShowInputModal(index)}>{item.times}</div>
                                            <div className="option-box row center align-items-center"><AddOutline onClick={() => handleAddClick(index)}/></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                inputModalVisible && (
                    <InputModal baseTimes={items[inputTimeIndex].times} height={95} index={inputTimeIndex} onConfirmClick={handleInputConfirm} onClose={() => setInputModalVisible(false)}/>
                )
            }
            {
                ChaseModalVisible && (
                    <ChaseModal baseChaseNum={chaseNum} height={55} onConfirmClick={(num) => { setChaseNum(num); setChaseModalVisible(false) }} onClose={() => setChaseModalVisible(false)}/>
                )
            }
            <div className="qlc-bottom-bar" style={{ bottom: "30px" }}>
                <div className="qlc-bottom-bar-top">
                    <div className="qlc-bottom-bar-top-zhuihao" onClick={() => setChaseModalVisible(true)}>
                        <div className="bottom-bar-top-zhuihao-add" style={{ width: "50%" }}>追号<span className="txt-primary" style={{ margin: "0px 5px"}}>{ chaseNum }</span>期</div>
                    </div>
                </div>
                <div className="qlc-bottom-bar-top" style={{ borderTop: "1px solid rgb(234, 234, 234)", lineHeight: "38px" }}>
                    <div>
                        <span className="fee" style={{margin: "0px 4px"}}>{amount}</span>元
                    </div>
                </div>
            </div>
            <div className="bottom-bar-next">
                <div className="bottom-bar-foot">
                    <div className="bottom-bar-foot-left">
                        {
                            enableFollowBuy && (<div className="plrxj" onClick={() => { handleFollowBuy() }}>发单</div>)
                        }
                        {
                            enableGroupBuy && (<div className="plrxj" onClick={() => { handleGroupBuy() }}>合买</div>)
                        }
                        <div className="plrxj" onClick={() => { handleSave() }}>保存</div>
                    </div>
                    <div className="bottom-bar-foot-next" onClick={handleOrderClick}>确认投注</div>
                </div>
            </div>
        </div>
    )
}