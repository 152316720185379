import React, { useMemo, useState } from "react";
import { Dialog, Form, Input, List, Mask, NavBar, Radio, SearchBar, Space, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./DrawMoney.css";
import { postUserDraw } from "../../axios";
const BankList = [
    "中国工商银行",
    "中国建设银行",
    "中国农业银行",
    "中国银行",
    "交通银行",
    "中国邮政储蓄银行",
    "招商银行",
    "中国光大银行",
    "中信银行",
    "广发银行",
    "浦发银行",
    "兴业银行",
    "平安银行",
    "中国民生银行",
    "网商银行",
    "安徽省农村信用社",
    "鞍山银行",
    "潍坊银行",
    "保定银行",
    "广西北部湾银行",
    "河北银行",
    "北京银行",
    "北京农商行",
    "东营莱商村镇银行",
    "温州民商银行",
    "中银富登村镇银行",
    "朝阳银行",
    "沧州银行",
    "东莞银行",
    "渤海银行",
    "海南省农村信用社",
    "锦州银行",
    "洛阳银行",
    "平顶山银行",
    "青海银行",
    "泉州银行",
    "新韩银行",
    "苏州银行",
    "营口银行",
    "蒙商银行",
    "长安银行",
    "长春朝阳和润村镇银行",
    "重庆三峡银行",
    "成都银行",
    "成都农商银行",
    "四川天府银行",
    "江苏长江商业银行",
    "重庆银行",
    "重庆农村商业银行",
    "长沙银行",
    "常熟农商银行",
    "浙商银行",
    "浙江稠州商业银行",
    "江南农村商业银行",
    "龙江银行",
    "大连银行",
    "大连农村商业银行",
    "东莞农村商业银行",
    "长城华西银行",
    "东营银行",
    "德州银行",
    "恒丰银行",
    "富邦华一银行",
    "富滇银行",
    "福建海峡银行",
    "福建省农村信用社联合社",
    "阜新银行",
    "广州银行",
    "广东省农村信用社联合社",
    "广东华兴银行",
    "桂林银行",
    "广州农村商业银行",
    "甘肃银行",
    "甘肃省农村信用社",
    "广西壮族自治区农村信用社联合社",
    "贵阳银行",
    "赣州银行",
    "贵州省农村信用社联合社",
    "内蒙古银行",
    "韩亚银行",
    "湖北银行",
    "河北省农村信用社",
    "邯郸银行",
    "汉口银行",
    "东亚银行",
    "葫芦岛银行",
    "黑龙江省农村信用社联合社",
    "湖南省农村信用社",
    "河南省农村信用社",
    "哈尔滨银行",
    "华融湘江银行",
    "徽商银行",
    "衡水市商业银行",
    "湖商村镇银行",
    "湖北省农信社",
    "华夏银行",
    "杭州银行",
    "湖州银行",
    "百信银行",
    "海南银行股份有限公司",
    "恒生银行",
    "承德银行",
    "金华银行",
    "晋城银行",
    "九江银行",
    "长春经开融丰村镇银行",
    "吉林银行",
    "吉林省农村信用社联合社",
    "济宁银行",
    "江苏江阴农村商业银行",
    "晋商银行",
    "江苏银行",
    "江苏省农村信用社联合社",
    "嘉兴银行",
    "江西省农村信用社",
    "晋中银行",
    "焦作中旅银行",
    "梅县客家村镇银行",
    "昆仑银行",
    "昆山农村商业银行",
    "廊坊银行",
    "辽宁省农村信用社",
    "莱商银行",
    "临商银行",
    "乐山市商业银行",
    "泸州市商业银行",
    "辽阳银行",
    "长春绿园融泰村镇银行",
    "柳州银行",
    "兰州银行",
    "浙江民泰商业银行",
    "绵阳市商业银行",
    "宁波银行",
    "宁波通商银行",
    "宁波鄞州农商行",
    "江西银行",
    "南海农商银行",
    "南京银行",
    "内蒙古农村信用社联合社",
    "宁夏银行",
    "宁夏黄河农村商业银行",
    "广东南粤银行",
    "鄂尔多斯银行",
    "攀枝花市商业银行",
    "青岛银行",
    "青海省农村信用社",
    "曲靖市商业银行",
    "齐鲁银行",
    "珠海华润银行",
    "日照银行",
    "四川省农村信用社联合社",
    "顺德农商银行",
    "山东省农村信用社联合社",
    "上海银行",
    "上海农商银行",
    "盛京银行",
    "遂宁银行",
    "上饶银行",
    "深圳农村商业银行",
    "绍兴银行",
    "陕西省农信社",
    "山西省农村信用社",
    "石嘴山银行",
    "泰安银行",
    "天津银行",
    "江苏太仓农村商业银行",
    "天津滨海农村商业银行",
    "天津农商银行",
    "台州银行",
    "海口联合农商银行",
    "联合村镇银行",
    "乌鲁木齐银行",
    "乌海银行",
    "威海市商业银行",
    "武汉农村商业银行",
    "苏州农村商业银行",
    "友利银行",
    "无锡农村商业银行",
    "温州银行",
    "西安银行",
    "厦门国际银行",
    "新疆农村信用社",
    "厦门银行",
    "邢台银行",
    "宜宾市商业银行",
    "尧都农商银行村镇银行",
    "营口沿海银行",
    "云南省农村信用社",
    "烟台银行",
    "云南红塔银行",
    "齐商银行",
    "自贡银行",
    "张家口银行",
    "浙江省农村信用社联合社",
    "浙江泰隆商业银行",
    "张家港农村商业银行",
    "中原银行",
    "贵州银行",
    "郑州银行",
    "枣庄银行",
    "企业银行",
    "花旗银行(中国)",
    "温州民商银行",
    "星展银行",
    "贵阳农村商业银行",
]
export default function DrawMoney() {
    const navigate = useNavigate();
    const pd = useMemo(() => 
    {
        try {
            const res = JSON.parse(decodeURIComponent(localStorage.getItem('_pd') || '{}'))
            return res;
        } catch(e) {
            console.log(e)
            return {}
        }
    }, [])
    const user = useSelector((state) => state.user.user);
    const awardsBalance = useMemo(() => Math.floor(user?.awardsBalance), [user]);
    const [realName, setRealName] = useState(pd.realName);
    const [drawWay, setDrawWay] = useState(pd.drawWay || "alipay");
    const [alipayAcount, setAlipayAcount] = useState(pd.alipayAcount || "");
    const [bankCard, setBankCard] = useState(pd.bankCard || "");
    const [bankAddress, setBankAddress] = useState(pd.bankAddress || "");
    const [bankName, setBankName] = useState(pd.bankName || "");
    const [bankInputForSearch, setBankInputForSearch] = useState("");
    const [drawAmount, setDrawAmount] = useState("");
    const [payPassword, setPayPassword] = useState("");

    const bankList = useMemo(() => {
        if (!bankInputForSearch) {
            return BankList
        } else {
            return BankList.filter((bank: String) => bank.search(bankInputForSearch) > -1);
        }
    }, [bankInputForSearch]);
    const [bankListModalVisible, setBankListModalVisible] = useState(false);

    const handleDrawMoney = () => {
        if (!realName) {
            Toast.show("请输入开户名");
            return;
        }
        if (!payPassword) {
            Toast.show("请输入支付密码");
        }
        if (drawWay === "alipay") {
            if (!alipayAcount) {
                Toast.show("请输入支付宝账户");
                return;
            }
        };
        if (drawWay === "bank") {
            if (!bankCard) {
                Toast.show("请输入银行卡");
                return;
            }
            if (!bankAddress) {
                Toast.show("请输入银行地址");
                return;
            }
            if (!bankName) {
                Toast.show("请选择开户银行");
                return;
            }
        }
        if(Number(drawAmount) <= 0) {
            Toast.show(`提现金额需大于0元`);
            return;
        }
        if(Number(drawAmount) > Number(awardsBalance)) {
            Toast.show(`提现金额不能大于:${awardsBalance}元`);
            return;
        }
        const preData = {
            realName,
            drawWay,
            alipayAcount,
            bankCard,
            bankAddress,
            bankName,
        }
        const _pd = encodeURIComponent(JSON.stringify(preData));
        localStorage.setItem('_pd', _pd);
        postUserDraw({
            realName,
            drawWay,
            alipayAcount,
            bankCard,
            bankAddress,
            bankName,
            drawAmount: Number(drawAmount),
            payPassword
        }).then(({ data }) => {
            if (data.status === "error") {
                Toast.show(data.errorMessage);
                if (data.errorCode === 10001) {
                    Dialog.show({
                        content: '是否前往设置支付密码',
                        closeOnAction: true,
                        actions: [
                            {
                                key: 'cancel',
                                text: '取消',
                            },
                            {
                                key: "confirm",
                                text: "确定"
                            }
                        ],
                        onAction: (action) => {
                            if (action.key === "confirm") {
                                navigate("/person/paypassword");
                            }
                        }
                    })
                }
            } else {
                navigate(-1);
            }
        })
    }
    return (
        <div className="draw-money">
            <NavBar onBack={() => navigate(-1)} right={(<div onClick={() => navigate('/person/draw/list')}>提现历史</div>)}>
                提款
            </NavBar>
            <Form layout='horizontal' mode='card'>
                <Form.Item label='开户名'>
                    <Input placeholder='请输入你的开户名' defaultValue={realName} onChange={setRealName} />
                </Form.Item>
                <Form.Item label='提现渠道'>
                    <Radio.Group defaultValue='alipay' value={drawWay} onChange={(val: any) => setDrawWay(val)}>
                        <Space direction="horizontal">
                            <Radio value='alipay'>支付宝</Radio>
                            <Radio value='bank'>银行卡</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                {
                    drawWay === "alipay" && (
                        <Form.Item label='支付宝'>
                            <Input placeholder='支付宝账号' defaultValue={alipayAcount} onChange={setAlipayAcount} />
                        </Form.Item>
                    )
                }
                {
                    drawWay === "bank" && (
                        <>
                            <Form.Item label='银行卡'>
                                <Input placeholder='银行卡号' defaultValue={bankCard} onChange={setBankCard} />
                            </Form.Item>
                            <Form.Item label='开户地'>
                                <Input placeholder='如广东省广州市' defaultValue={bankAddress} onChange={setBankAddress} />
                            </Form.Item>
                            <Form.Item label='开户银行' onClick={() => {
                                setBankListModalVisible(true)
                            }} clickable>
                                <Input onClick={() => setBankListModalVisible(true)} defaultValue={bankName} placeholder='如中国建设银行' value={bankName} disabled />
                            </Form.Item>
                            <Mask visible={bankListModalVisible}>
                                <div style={{ backgroundColor: "white", display: "flex", height: "100vh", flexDirection: "column" }}>
                                    <NavBar onBack={() => setBankListModalVisible(false)}>
                                        选择银行
                                    </NavBar>
                                    <SearchBar
                                        placeholder='请输入内容'
                                        showCancelButton={() => true}
                                        onCancel={() => setBankInputForSearch("")}
                                        onChange={setBankInputForSearch} />
                                    <div style={{ flex: "1 1", overflow: "auto" }}>
                                        <List>
                                            {
                                                bankList.map((bank) => {
                                                    return (
                                                        <div key={bank}>
                                                            <List.Item onClick={() => {
                                                                setBankName(bank);
                                                                setBankListModalVisible(false);
                                                            }}>{bank}</List.Item>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </List>
                                    </div>
                                </div>
                            </Mask>
                        </>
                    )
                }
                <Form.Item label='提现金额'>
                    <Input placeholder={`最多可提款${awardsBalance}`} onChange={(val) => setDrawAmount(Math.floor(Number(val)).toString())} />
                </Form.Item>
                <Form.Item label='支付密码'>
                    <Input placeholder='请输入支付密码' onChange={setPayPassword} />
                </Form.Item>
            </Form>
            <div className="btn-container">
                <div onClick={handleDrawMoney}>申请提款</div>
            </div>
            <div className="tips">
                <div className="txt">
                    <p>温馨提示:</p>
                    {/* <p>1、中奖金额在1小时后方可提现；</p> */}
                    <p><span style={{ "color": "rgb(255, 0, 0)" }}>1、为防止恶意提款、洗钱等不法行为，充值金额必须用于实际消费，不可提现；</span></p>
                    <p>2、为保障您的提款能快速及时到账，每日提款次数<span style={{ "color": "rgb(255, 0, 0)" }}>1</span>次；</p>
                    <p>3、为保障您的资金安全，我们将审核您的提款申请后，再汇款到您的银行卡；<br /></p>
                    <p><br /></p>
                </div>
            </div>
        </div>
    )
}