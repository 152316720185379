import React, { useMemo, useRef, useState } from "react";
import CodePayPNG from "../../assets/codePay.png";
import AliPayPNG from "../../assets/alipay.png";

import "./ChargeMoney.css"
import { Input, List, NavBar, Space, Toast, Image, ImageViewer } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getUserAlipayURL, getUserHuifuURL } from "../../axios";
import { useSelector } from "react-redux";
function generateRandomNumberInRange(M, N) {
    return `${Math.floor(Math.random() * (N - M + 1)) + M}`;
}
export default function ChargeMoney() {
    const navigate = useNavigate();
    const formRef = useRef(null);
    const shop = useSelector(state => state.shop.shop);
    const alipayStatus = useMemo(() => shop.chargeConfig.alipay, [shop]);
    const [qrCodeVisible, setQrCodeVisible] = useState(false);
    const [imgModalVisible, setImgModalVisible] = useState(false);
    const [imgModalSrc, setImgModalSrc] = useState("");
    const amountArr = useMemo(() => {
        const arr = [];
        arr.push(generateRandomNumberInRange(30, 50));
        arr.push(generateRandomNumberInRange(60, 100));
        arr.push(generateRandomNumberInRange(110, 200));
        arr.push(generateRandomNumberInRange(210, 500));
        arr.push(generateRandomNumberInRange(510, 1000));
        arr.push(generateRandomNumberInRange(1010, 1500));
        return arr;
    }, []);
    const [amount, setAmount] = useState(amountArr[0]);

    const doAliPay = () => {
        if(!amount) {
            Toast.show("请选择金额");
        }
        getUserHuifuURL({ amount }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                window.location.href = data.data.expend.qrcode_url;
            }
        })
    }
    return (
        <div className="bigView charge-money">
            <NavBar onBack={() => navigate(-1)}>
                充值
            </NavBar>
            <div ref={formRef}></div>
            <div className="diamond_options" style={{ "padding": "0px 0px 10px" }}>
                <div className="diamond_options_hd">
                    {
                        amountArr.map((_amount, index) => {
                            const hot = index === amountArr.length - 1;
                            return (
                                <div className={`diamond_item ${amount === _amount ? 'cur' : ''}`} onClick={() => setAmount(_amount)}>
                                    <h4>{_amount}元</h4>
                                    {
                                        hot && <div className="discount">热</div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "space-between", "width": "98%", "margin": "0px 5px" }}>
                    <div className="diamond_input_wrap">
                        <span>输入金额 :</span> <div style={{flex: "1 1"}}><Input value={amount} onChange={setAmount}/></div> 
                    </div>
                    <div style={{ "padding": "0px 5px", "width": "40%", "marginRight": "10px" }}>
                        {/* <Button color="primary" onClick={() => { }}>立即支付</Button> */}
                    </div>
                </div>
            </div>
            <div className="chargepayType">
                <span className="d1" style={{ "fontWeight": 500, "fontSize": "15px" }}>
                    <span>请选择支付方式</span>
                </span>
                <div className="choose">
                    <List>    
                        { alipayStatus && (
                            <List.Item clickable>
                                <div className="item" onClick={() => doAliPay()}>
                                    <div className="d1">
                                        <div className="uni-image">
                                            <img src={AliPayPNG} alt="" draggable="false" />
                                        </div>
                                        <div className="desc">
                                            <div className="t1">支付宝收款</div>
                                            {/* <div className="t2">支付成功后请联系店主确认到账 </div> */}
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                        <List.Item clickable onClick={() => setQrCodeVisible(!qrCodeVisible)}>
                            <div className="item">
                                <div className="d1">
                                    <div className="uni-image">
                                        <img src={CodePayPNG} alt="" draggable="false" />
                                    </div>
                                    <div className="desc">
                                        <div className="t1">二维码收款</div>
                                        <div className="t2">支付成功后请联系店主确认到账 </div>
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                        {
                            qrCodeVisible && (
                                <div style={{ padding: '0 28px' }}>
                                    <Space wrap>
                                        {
                                            shop.chargeConfig.qrcode.map((img) => {
                                                return (
                                                    <Image
                                                        src={img}
                                                        width={80}
                                                        height={80}
                                                        fit='cover'
                                                        onClick={() => {
                                                            setImgModalVisible(true);
                                                            setImgModalSrc(img);
                                                        }}
                                                        style={{ borderRadius: 4 }}
                                                    />
                                                )
                                            })
                                        }
                                    </Space>
                                </div>
                            )
                        }
                    </List>
                </div>
            </div>
            <div className="btm">
                <div className="tips">
                    <div className="txt">
                        <p><span style={{ "color": "rgb(0, 0, 0)" }}>温馨提示：</span></p>
                        {/* <p><span style={{ "color": "rgb(255, 0, 0)" }}>1、支付宝首次支付时会获取位置权限，拒绝会导致无法调起支付，如果勾选了“下次不再提示”的选项会导致永远无法使用支付。<br /></span></p> */}
                        <p><span style={{ "color": "rgb(255, 0, 0)" }}>1、充值金额不可提现，只能用于购彩，中奖金额可以提现。</span></p>
                        <p><br /></p>
                    </div>
                </div>
                <div className="remarkTip">如有疑问可联系<span className="txt-primary">在线客服</span>咨询解决。
                    <div className="u-checkbox-group u-clearfix">
                        <div className="u-checkbox" style={{ "width": "auto", "flex": "0 0 auto" }}>
                            <div className="u-checkbox__icon-wrap u-checkbox__icon-wrap--circle u-checkbox__icon-wrap--checked" style={{ "width": "17px", "height": "17px" }}>
                                <div className="u-icon u-checkbox__icon-wrap__icon u-icon--right">
                                    <span hover-className="" className="u-icon__icon uicon-checkbox-mark u-iconfont" style={{ "fontSize": "10px", "fontWeight": "normal", "top": "0px", "color": "rgb(255, 255, 255)" }}>
                                        <span></span>
                                    </span>
                                </div>
                            </div>
                            <div className="u-checkbox__label" style={{ "fontSize": "12px" }}>
                                <span className="txt-primary">我已年满18岁，</span>
                                已阅读并同意
                                <span className="txt-primary"><span>《用户协议及法律声明》</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ImageViewer
                image={imgModalSrc}
                visible={imgModalVisible}
                onClose={() => {
                    setImgModalVisible(false)
                }}
            />
        </div>
    )
}