import { Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";

function deepCopy<T>(obj: T): T {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    if (obj instanceof Set) {
      const copySet = new Set();
      obj.forEach((item) => {
        copySet.add(deepCopy(item));
      });
      return copySet as unknown as T;
    }
  
    if (Array.isArray(obj)) {
      const copyArr: any[] = [];
      obj.forEach((item) => {
        copyArr.push(deepCopy(item));
      });
      return copyArr as unknown as T;
    }
  
    const copyObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      copyObj[key] = deepCopy(obj[key]);
    });
  
    return copyObj as T;
}

export default function BeijingScoreModal(props: any) {
    const { selectedMatchNum, item, matchInfo: {awayTeamAbbName, homeTeamAbbName, _id, odds: { crs }}, onConfirm, onCancel } = props;
    const [selfItem, setSelfItem] = useState<any>({});
    useEffect(() => {
        setSelfItem(deepCopy(item));
    }, [item]);
    const handleItemClick = (type: string, target: string, odd: any) => {
        
        if(selfItem[type]) {
            selfItem[type].target.has(target) ? selfItem[type].target.delete(target) : selfItem[type].target.add(target)
        } else {
            if(selectedMatchNum >= 3) {
                Toast.show(`当前玩法最多选择3场比赛`)
                return;
            }
            selfItem[type] = {
                type,
                goalLine: odd.goalLine,
                single: odd.single,
                target: new Set([target]),
                match: _id,
            }
        }
        setSelfItem({...selfItem});
    }
    const handleConfirmClick = () => {
        onConfirm(_id, selfItem);
    }
    const handleCancelClick = () => {
        onCancel();
    }
    return (
        <div className="popContent" style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <div className="popCenter" style={{ width: "90%" }}>
                <div className="popView">
                    <div className="jz_game_team">
                        <div className="jz_center_team">{homeTeamAbbName}</div>
                        <div className="jz_vs c_999" style={{ color: 'rgb(153, 153, 153)' }}>VS</div>
                        <div className="jz_center_team">{awayTeamAbbName}</div>
                    </div>
                    <div className="bg_fff">
                        <table className="jz_spf_table">
                            <tr>
                                <td width="20" rowSpan={2} className="spf_s">胜</td>
                                {
                                    crs.s01s00 && <td className={selfItem['crs']?.target.has("s01s00") ? "active22" : ""} onClick={() => handleItemClick("crs", "s01s00", crs)}>1:0<div className="c_999">{crs.s01s00}</div></td>
                                }
                                {
                                    crs.s02s00 && <td className={selfItem['crs']?.target.has("s02s00") ? "active22" : ""} onClick={() => handleItemClick("crs", "s02s00", crs)}>2:0<div className="c_999">{crs.s02s00}</div></td>
                                }
                                {
                                    crs.s02s01 && <td className={selfItem['crs']?.target.has("s02s01") ? "active22" : ""} onClick={() => handleItemClick("crs", "s02s01", crs)}>2:1<div className="c_999">{crs.s02s01}</div></td>
                                }
                                {
                                    crs.s03s00 && <td className={selfItem['crs']?.target.has("s03s00") ? "active22" : ""} onClick={() => handleItemClick("crs", "s03s00", crs)}>3:0<div className="c_999">{crs.s03s00}</div></td>
                                }
                                {
                                    crs.s03s01 && <td className={selfItem['crs']?.target.has("s03s01") ? "active22" : ""} onClick={() => handleItemClick("crs", "s03s01", crs)}>3:1<div className="c_999">{crs.s03s01}</div></td>
                                }
                            </tr>
                            <tr>
                                {
                                    crs.s03s02 && <td className={selfItem['crs']?.target.has("s03s02") ? "active22" : ""} onClick={() => handleItemClick("crs", "s03s02", crs)}>3:2<div className="c_999">{crs.s03s02}</div></td>
                                }
                                {
                                    crs.s04s00 && <td className={selfItem['crs']?.target.has("s04s00") ? "active22" : ""} onClick={() => handleItemClick("crs", "s04s00", crs)}>4:0<div className="c_999">{crs.s04s00}</div></td>
                                }
                                {
                                    crs.s04s01 && <td className={selfItem['crs']?.target.has("s04s01") ? "active22" : ""} onClick={() => handleItemClick("crs", "s04s01", crs)}>4:1<div className="c_999">{crs.s04s01}</div></td>
                                }
                                {
                                    crs.s04s02 && <td className={selfItem['crs']?.target.has("s04s02") ? "active22" : ""} onClick={() => handleItemClick("crs", "s04s02", crs)}>4:2<div className="c_999">{crs.s04s02}</div></td>
                                }
                                {
                                    crs.s1sw && <td className={selfItem['crs']?.target.has("s1sw") ? "active22" : ""} onClick={() => handleItemClick("crs", "s1sw", crs)}>胜其它<div className="c_999">{crs.s1sw}</div></td>
                                }
                            </tr>
                            <tr>
                                <td className="spf_p">平</td>
                                {
                                    crs.s00s00 && <td className={selfItem['crs']?.target.has("s00s00") ? "active22" : ""} onClick={() => handleItemClick("crs", "s00s00", crs)}>0:0<div className="c_999">{crs.s00s00}</div></td>
                                }
                                {
                                    crs.s01s01 && <td className={selfItem['crs']?.target.has("s01s01") ? "active22" : ""} onClick={() => handleItemClick("crs", "s01s01", crs)}>1:1<div className="c_999">{crs.s01s01}</div></td>
                                }
                                {
                                    crs.s02s02 && <td className={selfItem['crs']?.target.has("s02s02") ? "active22" : ""} onClick={() => handleItemClick("crs", "s02s02", crs)}>2:2<div className="c_999">{crs.s02s02}</div></td>
                                }
                                {
                                    crs.s03s03 && <td className={selfItem['crs']?.target.has("s03s03") ? "active22" : ""} onClick={() => handleItemClick("crs", "s03s03", crs)}>3:3<div className="c_999">{crs.s03s03}</div></td>
                                }
                                {
                                    crs.s1sd && <td className={selfItem['crs']?.target.has("s1sd") ? "active22" : ""} onClick={() => handleItemClick("crs", "s1sd", crs)}>平其它<div className="c_999">{crs.s1sd}</div></td>
                                }
                            </tr>
                            <tr>
                                <td rowSpan={2} className="spf_f">负</td>
                                {
                                    crs.s00s01 && <td className={selfItem['crs']?.target.has("s00s01") ? "active22" : ""} onClick={() => handleItemClick("crs", "s00s01", crs)}>0:1<div className="c_999">{crs.s00s01}</div></td>
                                }
                                {
                                    crs.s00s02 && <td className={selfItem['crs']?.target.has("s00s02") ? "active22" : ""} onClick={() => handleItemClick("crs", "s00s02", crs)}>0:2<div className="c_999">{crs.s00s02}</div></td>
                                }
                                {
                                    crs.s01s02 && <td className={selfItem['crs']?.target.has("s01s02") ? "active22" : ""} onClick={() => handleItemClick("crs", "s01s02", crs)}>1:2<div className="c_999">{crs.s01s02}</div></td>
                                }
                                {
                                    crs.s00s03 && <td className={selfItem['crs']?.target.has("s00s03") ? "active22" : ""} onClick={() => handleItemClick("crs", "s00s03", crs)}>0:3<div className="c_999">{crs.s00s03}</div></td>
                                }
                                {
                                    crs.s01s03 && <td className={selfItem['crs']?.target.has("s01s03") ? "active22" : ""} onClick={() => handleItemClick("crs", "s01s03", crs)}>1:3<div className="c_999">{crs.s01s03}</div></td>
                                }
                            </tr>
                            <tr>
                                {
                                    crs.s02s03 && <td className={selfItem['crs']?.target.has("s02s03") ? "active22" : ""} onClick={() => handleItemClick("crs", "s02s03", crs)}>2:3<div className="c_999">{crs.s02s03}</div></td>
                                }
                                {
                                    crs.s00s04 && <td className={selfItem['crs']?.target.has("s00s04") ? "active22" : ""} onClick={() => handleItemClick("crs", "s00s04", crs)}>0:4<div className="c_999">{crs.s00s04}</div></td>
                                }
                                {
                                    crs.s01s04 && <td className={selfItem['crs']?.target.has("s01s04") ? "active22" : ""} onClick={() => handleItemClick("crs", "s01s04", crs)}>1:4<div className="c_999">{crs.s01s04}</div></td>
                                }
                                {
                                    crs.s02s04 && <td className={selfItem['crs']?.target.has("s02s04") ? "active22" : ""} onClick={() => handleItemClick("crs", "s02s04", crs)}>2:4<div className="c_999">{crs.s02s04}</div></td>
                                }
                                {
                                    crs.s1sl && <td className={selfItem['crs']?.target.has("s1sl") ? "active22" : ""} onClick={() => handleItemClick("crs", "s1sl", crs)}>负其它<div className="c_999">{crs.s1sl}</div></td>
                                }
                            </tr>
                        </table>
                    </div>
                    <div className="btn">
                        <div className="qx" onClick={handleCancelClick}>取 消</div>
                        <div className="done" onClick={handleConfirmClick}>确 定</div>
                    </div>
                </div>
            </div>
        </div>
    )
}