import React, { useState, useMemo, useRef } from "react";
import { InfiniteScroll, List, NavBar, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getBetList } from "../../axios";
import { cnNameMap } from "../../constant/cnNameMap";
import "./BetListNew.css";
import { getTimeInMonth } from "../../utils";
import { useSelector } from "react-redux";
import { PlayType } from "../../constant/betPlayType";

export default function SaveBetList() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const userId = useMemo(() => user._id, [user]) ;
    const [hasMore, setHasMore] = useState(true);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [betList, setBetList] = useState<any>([]);
    async function handleClick(betId: string) {
        navigate(`/person/bet/detail/${betId}`);
    }
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            status: "9,10",
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        pagenation.current.pageNum++;
        if (userId) {
            query["userId"] = userId;
        }
        try {
            const { data } = await getBetList(query);
            setBetList([...betList, ...data.betList]);
            setHasMore(data.betList.length === 10);
        } catch (e) {
            Toast.show("获取彩票失败");
        }
        pagenation.current.loading = false;
    }

    const back = () => {
        navigate("/person/person");
    }
    return (
        <div className="bet-list">
            <NavBar onBack={back}>
                保存列表
            </NavBar>
            <div style={{ flex: "1 1", overflow: "auto" }}>
                <List>
                    {betList && betList.map((bet: any, index: number) => {
                        const {playType} = bet;
                        return (
                            <List.Item key={index}>
                                <div className="centerBox" onClick={() => handleClick(bet._id)}>
                                    <div className="left">
                                        <div className="t1">
                                            <span style={{ fontWeight: 600 }}>{ cnNameMap[bet.type] }</span>
                                            <span style={{ fontSize: "12px", color: "rgb(153, 153, 153)" }}>
                                                {
                                                    playType === PlayType.Normal && "(自购)"
                                                }
                                                {
                                                    playType === PlayType.BonusOptimization && "(奖金优化)"
                                                }
                                            </span>
                                        </div>
                                        <div className="t2">{cnNameMap[bet.subType]}</div>
                                    </div>
                                    <div className="center">
                                        <div className="t1" style={{ color: "red" }}>{ bet.spend }元</div>
                                    </div>
                                    <div className="right">
                                        <div style={{ fontSize: "12px", color: "rgb(153, 153, 153)", marginTop: "5px" }}>{ getTimeInMonth(bet.userBuyTime) }</div>
                                    </div>
                                </div>
                            </List.Item>
                        )
                    })}
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
        </div>
    )
}