import React from "react";
import { useNavigate } from "react-router-dom";
import { NavBar, Tabs } from "antd-mobile";

export default function HotPacketList() {
    const navigate = useNavigate();
    return (
        <div className="hot-packet-list">
            <NavBar
                onBack={() => navigate(-1)}>
                我的红包
            </NavBar>
            <Tabs defaultActiveKey="1">
                <Tabs.Tab title='未使用' key="1"/>
                <Tabs.Tab title='已使用' key="2"/>
                <Tabs.Tab title='已过期' key="3"/>
            </Tabs>
            <div className="noData">
                <div className="box">
                    <div className="uni-image">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAMAAABG8BK2AAAAM1BMVEUAAACZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlkqvaGAAAAEHRSTlMAFERUjb5kNvAk3Myvf5xz5H7VxgAAAm5JREFUWMOtWFtywyAMNEJgwJBw/9O2g0ukAWSb0P1pk8gLeiwS3i6wWzQuFziDdt/moWzMHYz1zUIa8YJdmyzA6NPCQ3r97RQEEuvyBVwqrhKO8U7yJMyAxBtugBq8+oUHjaKf6XIr2PkMOKTRHcvHLmgh9uFjEZP25b8uVaGawCYCEiYLXpUPxVo1pSLsUsZezMcsUW1PoQf5zife23OkkopRXGCbQOzWfeWCOfmFNpKaWCbQPqPIowmoNt9H+cJuc/BNvoEkNk8DTa7nOPrnNNXuFOhBInXbFwhsO0B5m8XOomNoM9MIn9wo5uBDVZIpfErHkn+Y81s94nhXSZ4FV31CCjz623pBcgCrVxQlm0+89suwvvIJS0GufylgBUZUF5hqE7g8zy0cXaML+vpEd1WAhdaeziGtdpCh3E0P3Z6AphUC0AwA4xYWof3abK4vYZ/qA57L+Q/J94XsNjInqOrZzsyrP2p46PQdC8ywyZtxGtWYxjopxvwXiYaKvE/GII05KZkGc8VrF0u4AnsaT73qTlYeP0vxEFPCT8Z7kauqPZZwXn5JEIEgCuTlx8Vg8XHDA7RcDCTNARQwCK6W5NnhqENdkAMu+7hs4pqBWF6KDtEBzP38S4eoFb3ygbMEL/pkWYMZQTFctF+12u4cebvefFdGAcrlPw4m62PSytBGdQWLI6Slu93CQAu51szKeL0Pbzs4ybPTgbxw9QA2VXx/EXrz0/jba5mKmcIr8mR7l2hiGeJ4cmUNuY3LwgWa52jhOn8jZB8z4eAvF45MiP5e/S7fwNFWronWSIgoSiRRr7+UivayNNdfkf0AwKlXUO4tix4AAAAASUVORK5CYII=" draggable="false" />
                    </div>
                    <span><span>您还没有红包哦</span></span>
                </div>
            </div>
        </div>
    )
}