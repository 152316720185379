import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFollowBuyDetail } from "../../axios";
import "./FollowBuyDetail.css";
import { getTimeInYear } from "../../utils";
import defaultUser from "../../assets/images/user-2.jpg"
import InputModal from "../../components/InputModal";
import { NavBar, Toast } from "antd-mobile";

import MatchBetDetail from "../../components/MatchBetDetail";
import PickThreeBetDetail from "../../components/PickThreeBetDetail";

const status2CN = {
    "0": "未接单",
    "1": "未出票",
    "2": "已出票",
    "3": "未中奖",
    "4": "已中奖",
    "5": "已派奖",
    "6": "已撤单",
}

export default function FollowBuyDetail() {
    const params = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [bet, setBet] = useState<any>({});
    const [task, setTask] = useState({});
    const [inputModalVisible, setInputModalVisible] = useState(false);
    const [followList, setFollowList] = useState([]);
    const [followNum, setFollowNum] = useState(0);
    const [costPerTime, setCostPerTime] = useState(2);
    const [detailVisible, setDetailVisible] = useState(false);
    const [times, setTimes] = useState(1);
    const followBuyTaskId = useMemo(() => {
        return params.id
    }, [params])
    useEffect(() => {
        getFollowBuyDetail({ followBuyTaskId }).then(({ data }) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                const { betDetail, followBuyTask, followBetList, detailVisible } = data;
                setUser(followBuyTask.creator);
                setBet(betDetail || {});
                setTask(followBuyTask);
                setFollowList(followBetList);
                setFollowNum(followBuyTask.followNum);
                setCostPerTime(followBuyTask.amount / betDetail?.times);
                setDetailVisible(detailVisible);
            }
        })
    }, [])

    const toPayPag = () => {
        let data = {
            times,
            followBuyTaskId
        }
        navigate("/person/pay", { state: { data, type: bet.type, amount: costPerTime * times, isFollowBet: true, closeTime: bet.closeTime } });
    }

    const back = () => {
        navigate(-1);
    };

    return (
        <div className="bigView follow-buy-detail">
            <NavBar onBack={back}>
                发单方案详情
            </NavBar>
            <div className="tips">
                <div className="uni-image">
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAM1BMVEUAAAD/pSb/pCb/pCb/pSb/piT/pCX/pib/nib/pSX/pCT/oyf+pCX/oyf/oSb/pCX/pCZ+3kVfAAAAEHRSTlMAk9rq4z7FYw2JVCGxSBucP6qDjAAAALRJREFUKM99k1sOwyAMBE1sCOQ59z9t1TSFOEL4D0ZaLetF6sSSFDSVKO9ZJupMi0NrAgv7IXLswSCtD0XF5nacDY2VwZafQnmDm65KkNcE9Kec2O6rkyq+kS6f2F9zahLZWK6bWRpsrpi+bkx6UIwohdCHgSKJvQ93kihHHx6ogPShwBAOZZ2hoIs3VHyw7ikuhHXOLgQXn3H6+HzwxQXvVpajuJUNlj2qybhg42qOSz38Dh+DwgphgqdH3gAAAABJRU5ErkJggg=="
                        draggable="false" />
                </div>
                比赛变幻莫测，请控制金额理性跟单!
            </div>
            <div className="container">
                <div className="item" style={{ marginTop: "0px" }}>
                    <div className="left">
                        <div className="uni-image">
                            <img
                                src={user?.avatar || defaultUser}
                                draggable="false" />
                        </div>
                        <div className="i1">
                            <span>
                                {user?.username}
                                {
                                    user?.followBuyRecord?.victories > 0 && (<span className="hot-level yellow" style={{ marginLeft: "11px" }}>{user?.followBuyRecord?.victories}连红</span>)
                                }
                                
                            </span>
                        </div>
                    </div>
                    <div className="right">
                        {
                            [3, 4].includes(task?.status) && (<div className="gdyj"><span><span>跟单佣金</span></span><span><span>{task?.commission.toFixed(2)}元</span></span></div>)
                        }
                        {
                            [0, 1, 2].includes(task?.status) && (<span style={{ fontSize: "13px", fontWeight: 400, color: "rgb(102, 102, 102)" }}>{getTimeInYear(task?.closeTime)} 截止</span>)
                        }
                    </div>
                </div>
                <div className="txt" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    <p>{task?.message}</p>
                </div>
                <div className="bottom">
                    <div className="left"
                        style={{ width: "30%", height: "54px", border: "1px solid rgb(212, 212, 212)", borderRadius: "10px" }}>
                        <div className="u-m-t-10" style={{ color: "red", fontWeight: 600 }}>
                            {task?.amount}
                            <span style={{ fontSize: "13px" }}>元</span>
                        </div>
                        <span style={{ fontSize: "13px", color: "rgb(102, 102, 102)" }}>方案金额</span>
                    </div>
                    <div className="left"
                        style={{ width: "30%", height: "54px", border: "1px solid rgb(212, 212, 212)", borderRadius: "10px" }}>
                        <div className="r1">
                            <div className="u-m-t-10">{status2CN[bet.status]}</div>
                            <div style={{ fontSize: "13px", color: "rgb(102, 102, 102)", fontWeight: 400 }}>
                                方案状态</div>
                        </div>
                    </div>
                    <div className="left"
                        style={{ width: "30%", height: "54px", border: "1px solid rgb(212, 212, 212)", borderRadius: "10px" }}>
                        <div className="u-m-t-10" style={{ fontWeight: 900 }}>
                            <span>{bet.bonus > 0 ? bet.bonus.toFixed(2) : "-"}</span>
                        </div>
                        <div style={{ fontSize: "13px", color: "rgb(102, 102, 102)" }}>税后奖金</div>
                    </div>
                </div>
            </div>
            <div className="detail">
                {
                    ["football", "basketball", "beijing"].includes(bet.type) && (
                        <MatchBetDetail bet={bet} detailVisible={detailVisible} />
                    )
                }
                {
                    bet.type === "pickThree" && (
                        <PickThreeBetDetail pickthreeInfo={bet.pickthreeInfo} items={bet.items} detailVisible={detailVisible}/>
                    )
                }
            </div>
            <div className="gdList">
                <div className="gd_title">
                    <span>跟单用户</span>
                    <span>（共<span className="txt-primary">{followNum}</span>人，默认展示前10人）</span>
                </div>
                <table>
                    <tr>
                        <th>用户名</th>
                        <th>跟单金额</th>
                        <th>税后奖金</th>
                        <th>佣金</th>
                    </tr>
                    {
                        followList && followList.map((bet) => {
                            const { spend, userId: user, bonus } = bet;
                            return (
                                <tr>
                                    <td>{user?.username}</td>
                                    <td>{spend}</td>
                                    <td style={{ color: "rgb(3, 3, 3) !important" }}>{ bonus > 0 ? bonus.toFixed(2) : "-"}</td>
                                    <td>{ bonus > 0 ? (bonus * 0.1).toFixed(2) : "-"}</td>
                                </tr>
                            )

                        })
                    }
                </table>
            </div>
            {
                task?.status === 1 && (
                    <div className="footer">
                        <div className="sureBtn">
                            <div className="left">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>投</span>
                                    <div className="mul" onClick={() => { setInputModalVisible(true) }}>{times}</div>
                                    <span>倍</span>
                                </div>
                                <span>
                                    <span className="fee" style={{ fontSize: "17px" }}>{costPerTime * times}</span>
                                    <span style={{ fontSize: "17px", marginLeft: "2px" }}>元</span>
                                </span>
                            </div>
                            <div className="right">
                                <div className="bet-btn" onClick={toPayPag}>立即跟单</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                inputModalVisible && (
                    <InputModal baseTimes={times} height={60} onConfirmClick={setTimes} onClose={() => setInputModalVisible(false)} />
                )
            }
        </div>
    )
}