import React, { useState, useEffect } from "react";
import deletePNG from "../assets/images/del.png";

export default function InputModal(props: {
    baseTimes: number,
    height: number,
    index?: number,
    onConfirmClick: (num: number, index: number) => void;
    onClose:() => void;
}) {
    const { baseTimes, height, index = 1, onConfirmClick, onClose } = props;
    const [times, setTimes] = useState<number>(baseTimes);
    const [flag, setFlag] = useState(true);
    function handleNumClick(num: number) {
        if(num === 0) {
            setTimes(times * 10);
            return;
        }
        if(times === 1 && flag) {
            setTimes(num);
            setFlag(false);
        } else {
            setTimes(times * 10 + num);
        }
    };
    function handleDelete() {
        let _times = Math.floor(times / 10);
        if(_times === 0) {
            setFlag(true);
            setTimes(1);
        } else {
            setTimes(_times);
        }
    }

    const handleConfirmClick = () => {
        onConfirmClick(times, index);
    }

    useEffect(() => {
        setTimes(baseTimes);
    }, [baseTimes]);

    useEffect(() => {
        onConfirmClick(times, index);
    }, [index, onConfirmClick, times])
    return (
        <div className="layer-shade" style={{ height: `calc(100% - ${height}px)` }}>
            <div style={{ height: "100%" }} onClick={onClose}></div>
            <div className="layer-block">
                <div style={{ fontSize: "14px" }}>快乐购彩 理性投注</div>
                <div style={{ textAlign: "center", padding: "9px 0" }}>投<span className="font-money">{times}</span>倍
                    <div className="icon-u" style={{ float: "right" }}></div>
                </div>
                <div className="grid-3-3-3-3 choose-span-1" style={{ columnGap: "12px" }}>
                    <span onClick={() => setTimes(10)}>10倍</span>
                    <span onClick={() => setTimes(50)}>50倍</span>
                    <span onClick={() => setTimes(100)}>100倍</span>
                    <span onClick={() => setTimes(500)}>500倍</span>
                </div>
                <div className="keyboard">
                    <span onClick={() => { handleNumClick(1) }}>1</span>
                    <span onClick={() => { handleNumClick(2) }}>2</span>
                    <span onClick={() => { handleNumClick(3) }}>3</span>
                    <span onClick={() => { handleNumClick(4) }}>4</span>
                    <span onClick={() => { handleNumClick(5) }}>5</span>
                    <span onClick={() => { handleNumClick(6) }}>6</span>
                    <span onClick={() => { handleNumClick(7) }}>7</span>
                    <span onClick={() => { handleNumClick(8) }}>8</span>
                    <span onClick={() => { handleNumClick(9) }}>9</span>
                    <span className="key-del" onClick={handleDelete}> <img src={deletePNG}/></span>
                    <span onClick={() => { handleNumClick(0) }}>0</span>
                    <span className="key-cur" onClick={onClose}>确定</span>
                </div>
            </div>
        </div>
    )
}