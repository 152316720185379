import { get } from "./base.js";

/**
 * @param {any} params
 */
export function getMatch(params) {
    return get("/match/list", params)
}

/**
 * @param {any} params
 */
export function getMatchNum(params) {
    return get("/match/num", params)
}

/**
 * @param {any} params
 */
export function getPickThreeTerm(params) {
    return get("/pickthree", params)
}

/**
 * @param {any} params
 */
export function getMatchListByDay(params) {
    return get("/match/list/day", params)
}
