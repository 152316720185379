import React, { useMemo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavBar, Toast } from "antd-mobile";
import { QRCode } from "antd";
import "./ShareShop.css";
import { getUserShareLink } from "../../../axios";
import BackgroundJPG from "../../../assets/20231116035815.jpg";

export default function ShareShop() {
    const navigate = useNavigate();
    const [shareLink, setShareLink] = useState('');
    const [error, setError] = useState(false);
    const width = useMemo(() => (document.body&&document.body.clientWidth||document.getElementsByTagName("html")[0].offsetWidth)/ 3, [])
    useEffect(() => {
        getUserShareLink({}).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
                setError(true);
            } else {
                setShareLink(data.shareLink);
            }
        })
    }, [])
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            <NavBar onBack={() => navigate(-1)}>
                分享店铺
            </NavBar>
            <div className="com">
                <img src={BackgroundJPG} alt="" style={{ width:"100%" }}/>
                <div className="dhzo" style={{ transform: 'translate(25%,17%)' }}>
                    <div className="map">
                        <div className="shop-qrcode">
                        {
                                error && (<div>加载失败</div>)
                            }
                            {
                                shareLink && (
                                    <QRCode value={shareLink} size={width}/>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}