import React, { useEffect }  from 'react';
import { Button, ErrorBlock, SafeArea } from 'antd-mobile'
import './App.css';

import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";

import Login from "./modules/Login.tsx"
import ActionList from "./modules/person/ActionList.tsx"
import BetList from "./modules/person/BetListNew.tsx"
import Tickets from "./modules/bet/Tickets.tsx";
import ShareShop from './modules/common/sharePage/ShareShop.tsx';


import UserPersonCenter from "./modules/person/PersonalCenter.tsx";
import Three from "./modules/Three/index.tsx"
import Basketball from "./modules/Basketball/index.tsx";
import Football from "./modules/Football/index.tsx";
import Beijing from "./modules/Beijing/index.tsx";
import PayPage from './modules/bet/PayPage.tsx';
import DrawMoney from './modules/person/DrawMoney.tsx';
import DrawHistory from './modules/person/DrawList.tsx';
import HomePage from "./modules/home/HomePage.tsx";
import UserBetDetail from "./modules/bet/BetDetail.tsx";
import BetSummarize from "./modules/bet/BetSummarize.tsx";
import BetSummarizeN from "./modules/bet/BetSummarizeN.tsx";
import BetsummarizeC from './modules/bet/BetSummarizeC.tsx';
import PublishGroupBuy from './modules/bet/PublishGroupBuy.tsx';
import GroupBuyHall from './modules/groupbuy/GroupBuyHall.tsx';
import GroupBuyDetail from './modules/groupbuy/GroupBuyDetail.tsx';
import GroupBuyMe from './modules/groupbuy/GroupBuyMe.tsx';
import PublishFollowBuy from "./modules/bet/PublishFollowBuy.tsx";
import FollowBuyHall from './modules/followbuy/FollowBuyHall.tsx';
import FollowBuyPerson from './modules/followbuy/FollowBuyPerson.tsx';
import FollowBuyMe from "./modules/followbuy/FollowBuyMe.tsx";
import FollowBuyDetail from './modules/followbuy/FollowBuyDetail.tsx';
import FollowBuyCustomize from './modules/followbuy/FollowBuyCustomize.tsx';
import BonusOptimization from './modules/bet/BonusOptimization.tsx';
import ChaseNumList from "./modules/chaseNum/ChaseNumList.tsx";
import ChaseNumDetail from './modules/chaseNum/ChaseNumDetail.tsx';
import AgentSummarize from "./modules/agent/AgentSummarize.tsx";
import AgentMyStatistics from './modules/agent/AgentMyStatistics.tsx';
import AgentMyUsers from './modules/agent/AgentMyUsers.tsx';
import TurnOut from './modules/person/TurnOut.tsx'
import HelpCenter from "./modules/person/HelpCenter.tsx";
import ShopInfo from "./modules/person/ShopInfo.tsx";
import MyInfo from "./modules/person/MyInfo.tsx";
import HotPacketList from './modules/person/HotPacketList.tsx';
import MyMessage from './modules/person/MyMessage.tsx';
import ChargeMoney from "./modules/person/ChargeMoney.tsx";
import RealName from "./modules/person/RealName.tsx";
import PayPassword from './modules/person/PayPassword.tsx';
import Password from './modules/person/Password.tsx';
import ChatGroup from './modules/chat/ChatGroup.tsx';
import ChatUserManage from './modules/chat/UserManagement.tsx';
import PrivateChat from './modules/chat/ContactManager.tsx';
import SaveBetList from './modules/person/SaveBetList.tsx';
import MatchList from './modules/match/MatchList.tsx';
import DailyUpdateDetail from './modules/docking/DailyUpdateDetail.tsx';
import DailyUpdateList from './modules/docking/DailyUpdateList.tsx';
import Champion from './modules/Champion/index.tsx';
import StillnessOutLets from './StillnessOutlets/index.js';
import { StillnessProvider } from 'react-stillness-component';


function Layout() {
  return (
    <div>
      <StillnessOutLets />
    </div>
  )
}

function AppContainer() {
  useEffect(() => {
      const width = 750;
      const fontSize = 100;
      const widthProportion = function(){var p = (document.body&&document.body.clientWidth||document.getElementsByTagName("html")[0].offsetWidth)/width;return p>1?1:p<0.32?0.32:p;};
      const changePage = function(){
          document.getElementsByTagName("html")[0].setAttribute("style","font-size:"+widthProportion()*fontSize+"px !important");
      }
      changePage(); 
      window.addEventListener('resize',function(){changePage();},false);
  }, []);
  return (
      <div className="App">
        <SafeArea position='top' />
        <div className='body'>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="person/bet/save" Component={SaveBetList}></Route>
              <Route path="person/bet/list" Component={BetList}></Route>
              <Route path="person/bet/detail/:id" Component={UserBetDetail}></Route>
            </Route>
            <Route path="/" >
              <Route path="" index Component={HomePage}></Route>
              <Route path="login" Component={Login}></Route>
              <Route path="share/shop" Component={ShareShop}></Route>
              <Route path="person/bet/tickets" Component={Tickets}></Route>
              <Route path="person/pay" Component={PayPage} />
              <Route path="person/three" Component={Three} />
              <Route path="person/person" Component={UserPersonCenter} />
              <Route path="person/basketball" Component={Basketball} />
              <Route path="person/football" Component={Football} />
              <Route path="person/beijing" Component={Beijing} />
              <Route path="person/champion" Component={Champion} />
              <Route path="person/action/list" Component={ActionList}></Route>
              <Route path="person/betsummarize" Component={BetSummarize}></Route>
              <Route path="person/betsummarizen" Component={BetSummarizeN}></Route>
              <Route path="person/betsummarizec" Component={BetsummarizeC}></Route>
              <Route path="person/bonusOptimization" Component={BonusOptimization}></Route>
              <Route path="person/groupbuy/publish" Component={PublishGroupBuy}></Route>
              <Route path="person/groupbuy/hall" Component={GroupBuyHall}></Route>
              <Route path="person/groupbuy/detail/:id" Component={GroupBuyDetail}></Route>
              <Route path="person/groupbuy/me" Component={GroupBuyMe}></Route>
              <Route path="person/followbuy/publish" Component={PublishFollowBuy}></Route>
              <Route path="person/followbuy/hall" Component={FollowBuyHall}></Route>
              <Route path="person/followbuy/person/:id" Component={FollowBuyPerson}></Route>
              <Route path="person/followbuy/me" Component={FollowBuyMe}></Route>
              <Route path="person/followbuy/detail/:id" Component={FollowBuyDetail}></Route>
              <Route path="person/followbuy/customize/:id" Component={FollowBuyCustomize}></Route>
              <Route path="person/chasenum/list" Component={ChaseNumList}></Route>
              <Route path="person/chasenum/detail/:id" Component={ChaseNumDetail}></Route>
              <Route path='/person/agentsummarize' Component={AgentSummarize}></Route>
              <Route path='/person/agentmystatistics' Component={AgentMyStatistics}></Route>
              <Route path='/person/agentmyusers' Component={AgentMyUsers}></Route>
              <Route path='/person/turnout' Component={TurnOut}></Route>
              <Route path='/person/help/center' Component={HelpCenter}></Route>
              <Route path='/person/shopinfo' Component={ShopInfo}></Route>
              <Route path='/person/myinfo' Component={MyInfo}></Route>
              <Route path='/person/hotpacket' Component={HotPacketList}></Route>
              <Route path='/person/mymessage' Component={MyMessage}></Route>
              <Route path='/person/drawmoney' Component={DrawMoney}></Route>
              <Route path='/person/draw/list' Component={DrawHistory}></Route>
              <Route path='/person/chargemoney' Component={ChargeMoney}></Route>
              <Route path='/person/realname' Component={RealName}></Route>
              <Route path='/person/paypassword' Component={PayPassword}></Route>
              <Route path='/person/password' Component={Password}></Route>
              <Route path='/person/match' Component={MatchList}></Route>

              <Route path='/person/chat' Component={PrivateChat}></Route>
              <Route path='/person/chat/group' Component={ChatGroup}></Route>
              <Route path='/person/chat/user' Component={ChatUserManage}></Route>
              <Route path='/docking/update/list' Component={DailyUpdateList}></Route>
              <Route path='/docking/update/detail/:id' Component={DailyUpdateDetail}></Route>
            </Route>
          </Routes>
        </div>
        <SafeArea position='bottom' />
      </div>
  );
}



function ErrorFallback() {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  return (
    <ErrorBlock
        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
        style={{
          '--image-height': '150px',
        }}
      >
        <Button color='primary' onClick={() => {navigate(-1); resetBoundary()}}>返回上一页</Button>
      </ErrorBlock>
  )
}

function App() {
  return (
    <Router>
        <StillnessProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AppContainer />
        </ErrorBoundary>
        </StillnessProvider>
      </Router>
  );
}

export default App;
