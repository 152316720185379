import React, { useEffect, useMemo, useState } from "react";
import "./BetSummarize.css";
import { Button, Grid, Modal, NavBar, Popup, Toast } from "antd-mobile";
import { AddOutline, CloseCircleFill, DownOutline, MinusOutline } from "antd-mobile-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMatch, postBetOrder } from "../../axios/index.js";
import { matchItemMap, matchInfo } from "../../interface";
import { cnNameMap } from "../../constant/cnNameMap";
import { getTime, splitArray, splitArrayByN } from "../../utils";
import InputModal from "../../components/InputModal";
import { PlayType } from "../../constant/betPlayType";
import { calMatchBonus, calSPlimit, roundToTwo } from "../../utils/MatchBonusCal.ts";
import { freeCombine, option2Num, mixedCombine, mixedCombineGroup, mixedCombine2Free } from "../../constant/combine.ts";

export default function FootballResult() {
    const location = useLocation();
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop.shop);
    const user = useSelector((state) => state.user.user);
    const [visible, setVisible] = useState(false);
    const [inputModalVisible, setInputModalVisible] = useState(false);
    const [matchIdList, setMatchIdList] = useState<string[]>([])
    const [matchGroupById, setMatchGroupById] = useState<{ [key: string]: matchInfo }>({});
    const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set());
    const [optionLimit, setOptionLimit] = useState(8);
    const [times, setTimes] = useState(1);
    const [maxSP, setMaxSP] = useState<number>();
    const [minBonus, setMinBonus] = useState<string>();
    const [maxBonus, setMaxBonus] = useState<string>();
    const [amount, setAmount] = useState(0);
    const items: matchItemMap = useMemo(() => {
        return location.state?.items
    }, [location.state])
    const selectedItems: any = useMemo(() => {
        return location.state?.selectedItems
    }, [location.state]);
    const type: string = useMemo(() => {
        return location.state?.type
    }, [location.state])
    const subType: string = useMemo(() => {
        return location.state?.subType
    }, [location.state])
    const closeTime: number = useMemo(() => {
        return location.state?.closeTime
    }, [location.state])
    const single: boolean = useMemo(() => {
        return location.state?.single
    }, [location.state]);
    const enableBuy = useMemo(() => {
        return shop.config[type]?.enableBuy || false;
    }, [shop.config, type]);
    const minAmount = useMemo(() => {
        return shop.config[type]?.minAmount || 2;
    }, [shop.config, type]);
    const enableGroupBuy = useMemo(() => {
        return shop.config[type]?.enableGroupBuy || false;
    }, [shop.config, type]);
    const enableFollowBuy = useMemo(() => {
        return shop.config[type]?.enableFollowBuy || false;
    }, [shop.config, type]);
    const enableBonusOptimization = useMemo(() => {
        return shop.config[type]?.enableBonusOptimization || false;
    }, [shop.config, type]);

    const enableMixedCombine = useMemo(() => {
        return optionLimit === matchIdList.length && ['basketball', 'football'].includes(type)
        // return optionLimit === matchIdList.length && ['basketball', 'football'].includes(type)
    }, [optionLimit, matchIdList.length, type, shop._id])

    const back = () => {
        navigate(-1);
    };

    const toggleSelectedOptions = (option: string) => {
        selectedOptions.has(option) ? selectedOptions.delete(option) : selectedOptions.add(option);
        setSelectedOptions(new Set([...selectedOptions]));
    }

    useEffect(() => {
        const matchIdList = Object.keys(items);
        setMatchIdList(matchIdList);
        let _optionLimit = matchIdList.length;
        for (let i = 0, len = matchIdList.length; i < len; i++) {
            const matchId = matchIdList[i];
            const item = items[matchId];
            const typeList = Object.keys(item);
            for (const itemType of typeList) {
                if (['ttg'].includes(itemType)) {
                    _optionLimit = Math.min(_optionLimit, 6);
                }
                if (['hafu', 'crs', 'wnm'].includes(itemType)) {
                    _optionLimit = Math.min(_optionLimit, 4);
                }
                if(type === "beijing") {
                    if (['ttg', 'hafu', 'sxds'].includes(itemType)) {
                        _optionLimit = Math.min(_optionLimit, 6);
                    }
                }
            }

        }
        selectedOptions.add(`${_optionLimit}^1`);
        setOptionLimit(_optionLimit);
        setSelectedOptions(new Set([...selectedOptions]));
        getMatch({ matchIdList: JSON.stringify([...matchIdList]) }).then(({ data: { matchList } }: { data: { matchList: matchInfo[] } }) => {
            let _matchGroupById: { [key: string]: matchInfo } = {};
            for (let match of matchList) {
                _matchGroupById[match._id] = match;
            }
            setMatchGroupById(_matchGroupById);
        })
    }, [items]);

    useEffect(() => {
        const optionArr = [...selectedOptions];
        const calMaxAry = [];
        const calMinAry = [];
        const multiCountAry: number[][] = [];
        let ticketNum = 1;
        for (let i = 0, len = matchIdList.length; i < len; i++) {
            const matchId = matchIdList[i];
            const item = items[matchId];
            const typeList = Object.keys(item);
            multiCountAry[i] = []
            for (const type of typeList) {
                const targetList = item[type].target as string[];
                multiCountAry[i].push(targetList.length);
            }
            ticketNum *= multiCountAry[i].length;

            const [maxVal, minVal] = calMatchBonus(type, item, matchGroupById[matchId]?.odds);
            calMaxAry.push({ ticket: multiCountAry[i].length, maxVal });
            calMinAry.push({ ticket: multiCountAry[i].length, minVal });
        }
        let maxSP = 0;
        let minSP = 0;
        let mnMount = 0
        for (let option of optionArr) {
            let _optionArr: any[] = [];
            if (freeCombine.includes(option)) {
                _optionArr = [option];
            } else if (mixedCombine.includes(option)) {
                _optionArr = mixedCombine2Free[option];
            }
            for (let _option of _optionArr) {
                const multiArr = splitArray(multiCountAry);
                for (const arr of multiArr) {
                    const splitArr = splitArrayByN(arr, option2Num[_option]);
                    for (const result of splitArr) {
                        mnMount += result.reduce((a, b) => a * b, 1);
                    }
                }
                let _maxSP = 0;
                const resultMaxArr = splitArrayByN(calMaxAry, option2Num[_option]);
                for (const result of resultMaxArr) {
                    let _ticketNum = 1;
                    let maxArr = [];
                    for (let data of result) {
                        _ticketNum *= data.ticket;
                        maxArr.push(data.maxVal);
                    }
                    const time = ticketNum / _ticketNum;
                    const resultSplitArr = splitArray(maxArr);
                    for (let resultSplit of resultSplitArr) {
                        const _bonux = resultSplit.reduce((a, b) => a * b, 1) * 2;
                        maxSP += calSPlimit(option2Num[_option], roundToTwo(_bonux)) * time;
                    }
                }
                let _minSP = 0
                const resultMinArr = splitArrayByN(calMinAry, option2Num[_option]);
                for (const result of resultMinArr) {
                    let _ticketNum = 1;
                    let minArr = [];
                    for (let data of result) {
                        _ticketNum *= data.ticket;
                        minArr.push(data.minVal);
                    }
                    const time = ticketNum / _ticketNum;
                    const resultSplitArr = splitArray(minArr);
                    for (let resultSplit of resultSplitArr) {
                        const _bonux = resultSplit.reduce((a, b) => a * b, 1) * 2;
                        minSP += calSPlimit(option2Num[_option], roundToTwo(_bonux)) * time;
                    }
                }
                minSP += calSPlimit(option2Num[_option], _minSP);
            }
        }

        if (type === 'beijing') {
            maxSP *= 0.65;
            minSP *= 0.65;
        }
        setMaxSP(maxSP);
        setMaxBonus((maxSP * times).toFixed(2));
        setMinBonus((minSP * times).toFixed(2));
        setAmount(mnMount * times * 2);
    }, [items, matchGroupById, matchIdList, selectedOptions, times, type])

    const handleBonusOptimization = () => {
        const _selectedOptions = [...selectedOptions].sort((a, b) => option2Num[a] - option2Num[b]);
        for (const option of _selectedOptions) {
            if (mixedCombine.includes(option)) {
                Toast.show(`无法对混合过关进行奖金优化`);
                return;
            }
        }
        if (amount < 10) {
            Toast.show(`奖金优化最低投注10元`);
            return;
        }
        navigate(
            "/person/bonusOptimization",
            {
                state: {
                    items,
                    amount,
                    times,
                    type,
                    subType,
                    selectedOptions: _selectedOptions.map((option) => option2Num[option]),
                    closeTime,
                    matchGroupById
                }
            }
        )
    }

    const handleOrderClick = async () => {
        const _selectedOptions = [...selectedOptions].sort((a, b) => option2Num[a] - option2Num[b]);
        if (!enableBuy) {
            Toast.show("店主暂停出票");
            return;
        }
        if (selectedOptions.size === 0) {
            Toast.show("请选择过关方式");
            return;
        }
        if (minAmount > amount) {
            Toast.show(`最低投注${minAmount}元`);
            return;
        }
        let data = {
            type,
            subType,
            playType: PlayType.Normal,
            times,
            closeTime,
            options: _selectedOptions.join("_"),
            items
        }
        navigate("/person/pay", { state: { data, type, subType, amount, closeTime } });
    }
    const handleBackToChange = async () => {
        let path;
        switch (type) {
            case "beijing": path = "/person/beijing"; break;
            case "football": path = "/person/football"; break;
            case "basketball": path = "/person/basketball"; break;
        }
        navigate(path as string, { state: { selectedItems, subType } });
    }
    const handleFollowBuy = () => {
        if(!user.followingStatus) {
            Toast.show("仅特邀用户允许发单！");
            return;
        }
        const _selectedOptions = [...selectedOptions].sort((a, b) => option2Num[a] - option2Num[b]);
        if (selectedOptions.size === 0) {
            Toast.show("请选择过关方式");
            return;
        }
        if (amount < 100) {
            Toast.show("发单最低投注100元");
            return;
        }
        navigate("/person/followbuy/publish",
            {
                state:
                {
                    items,
                    amount,
                    times,
                    type,
                    subType,
                    selectedOptions: _selectedOptions,
                    closeTime,
                    maxSP,
                }
            })
    }

    const handleGroupBuy = () => {
        const _selectedOptions = [...selectedOptions].sort((a, b) => option2Num[a] - option2Num[b]);
        if (selectedOptions.size === 0) {
            Toast.show("请选择过关方式");
            return;
        }
        if (amount < 10) {
            Toast.show("合买最低投注10元");
            return;
        }
        navigate("/person/groupbuy/publish",
            {
                state:
                {
                    items,
                    amount,
                    times,
                    type,
                    subType,
                    selectedOptions: _selectedOptions,
                    closeTime
                }
            })
    }

    const handleSave = () => {
        const _selectedOptions = [...selectedOptions].sort((a, b) => option2Num[a] - option2Num[b]);
        Modal.confirm({
            content: '是否保存该方案',
            onConfirm: async () => {
                let data = {
                    type,
                    subType,
                    playType: PlayType.Normal,
                    times,
                    closeTime,
                    options: _selectedOptions.join("_"),
                    items,
                    onlySave: true,
                }
                postBetOrder(data).then(({ data }) => {
                    if (data.status === 'error') {
                        Toast.show(data.errorMessage);
                        return;
                    }
                    Toast.show("保存成功");
                    navigate(`/person/bet/detail/${data.id}`);
                })
            },
        })
    }
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <NavBar
                onBack={back}
                right={enableBonusOptimization && (<div onClick={handleBonusOptimization}>奖金优化</div>)}>
                投注
            </NavBar>
            <div style={{ margin: "4px 12px" }}>
                <div className="row center">{getTime(closeTime)} 截止，请尽快提交投注</div>
                <Button block color='primary' size='small' fill='outline' style={{ height: "30px", marginTop: "4px" }} onClick={handleBackToChange}>
                    <div style={{ "fontSize": "15px" }}>添加比赛/修改投注</div>
                </Button>
            </div>
            <div className="order-list" style={{ paddingBottom: "100px", flex: '1 1', overflow: "auto", height: "100vh" }}>
                {
                    items && Object.keys(items).map((matchId) => {
                        const item = items[matchId];
                        return (
                            <div className="order-item row">
                                <div className="column align-items-center" style={{ marginRight: "12px" }}>
                                    <div>{matchGroupById[matchId]?.matchNumStr}</div>
                                    <CloseCircleFill />
                                </div>
                                <div className="column flex-1">
                                    <div className="row space-between flex-1">
                                        <div>{matchGroupById[matchId]?.homeTeamAbbName}</div>
                                        <div style={{ color: "var(--color)" }}>VS</div>
                                        <div>{matchGroupById[matchId]?.awayTeamAbbName}</div>
                                    </div>
                                    {
                                        item && Object.keys(item).map((type) => {
                                            const { target } = item[type];
                                            const _target = [...target];
                                            return (
                                                <div className="person-ps">{cnNameMap[type]}：
                                                    {
                                                        _target.map((target) => {
                                                            return (
                                                                <span style={{ color: "var(--adm-color-primary)" }}>{cnNameMap[target]}({matchGroupById[matchId]?.odds[type][target]})</span>
                                                            )
                                                        })
                                                    }</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                inputModalVisible && (
                    <InputModal baseTimes={times} height={75} onConfirmClick={setTimes} onClose={() => setInputModalVisible(false)} />
                )
            }
            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false)
                }}
                onClose={() => {
                    setVisible(false)
                }}
                bodyStyle={{ height: '50vh', "--adm-color-primary": "#fa541c" }}
            >
                <div className="column">
                    <div className="column popup-fixed-result-top">
                        <div className="column popup-fixed-result-top-top" style={{ marginBottom: "12px" }}>自由过关（多选）</div>
                        <Grid columns={5} gap={12}>
                            {
                                optionLimit > 0 && single && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("1^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("1^1")}>单关</div></Grid.Item>)
                            }
                            {
                                optionLimit > 1 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("2^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("2^1")}>2串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 2 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("3^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("3^1")}>3串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 3 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("4^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("4^1")}>4串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 4 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("5^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("5^1")}>5串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 5 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("6^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("6^1")}>6串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 6 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("7^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("7^1")}>7串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 7 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("8^1")}>8串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 8 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("9^1")}>9串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 9 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("10^1")}>10串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 10 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("11^1")}>11串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 11 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("12^1")}>12串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 12 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("13^1")}>13串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 13 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("14^1")}>14串1</div></Grid.Item>)
                            }
                            {
                                optionLimit > 14 && (<Grid.Item><div
                                    className={`popup-fixed-result-top-item ${selectedOptions.has("8^1") ? "active" : ""}`}
                                    onClick={() => toggleSelectedOptions("15^1")}>15串1</div></Grid.Item>)
                            }
                            
                        </Grid>
                    </div>
                    {
                        enableMixedCombine && (
                            <div className="column popup-fixed-result-top">
                                <div className="column popup-fixed-result-top-top" style={{ marginBottom: "12px" }}>混合过关（多选）</div>
                                <Grid columns={5} gap={12}>
                                    {
                                        optionLimit === 3 && matchIdList.length === 3 && (
                                            <>
                                                {
                                                    mixedCombineGroup['3'].map((option) => (
                                                        <Grid.Item>
                                                            <div
                                                                className={`popup-fixed-result-top-item ${selectedOptions.has(option) ? "active" : ""}`}
                                                                onClick={() => toggleSelectedOptions(option)}>{cnNameMap[option]}</div>
                                                        </Grid.Item>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        optionLimit === 4 && matchIdList.length === 4 && (
                                            <>
                                                {
                                                    mixedCombineGroup['4'].map((option) => (
                                                        <Grid.Item>
                                                            <div
                                                                className={`popup-fixed-result-top-item ${selectedOptions.has(option) ? "active" : ""}`}
                                                                onClick={() => toggleSelectedOptions(option)}>{cnNameMap[option]}</div>
                                                        </Grid.Item>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        optionLimit === 5 && matchIdList.length === 5 && (
                                            <>
                                                {
                                                    mixedCombineGroup['5'].map((option) => (
                                                        <Grid.Item>
                                                            <div
                                                                className={`popup-fixed-result-top-item ${selectedOptions.has(option) ? "active" : ""}`}
                                                                onClick={() => toggleSelectedOptions(option)}>{cnNameMap[option]}</div>
                                                        </Grid.Item>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        optionLimit === 6 && matchIdList.length === 6 && (
                                            <>
                                                {
                                                    mixedCombineGroup['6'].map((option) => (
                                                        <Grid.Item>
                                                            <div
                                                                className={`popup-fixed-result-top-item ${selectedOptions.has(option) ? "active" : ""}`}
                                                                onClick={() => toggleSelectedOptions(option)}>{cnNameMap[option]}</div>
                                                        </Grid.Item>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        optionLimit === 7 && matchIdList.length === 7 && (
                                            <>
                                                {
                                                    mixedCombineGroup['7'].map((option) => (
                                                        <Grid.Item>
                                                            <div
                                                                className={`popup-fixed-result-top-item ${selectedOptions.has(option) ? "active" : ""}`}
                                                                onClick={() => toggleSelectedOptions(option)}>{cnNameMap[option]}</div>
                                                        </Grid.Item>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        optionLimit === 8 && matchIdList.length === 8 && (
                                            <>
                                                {
                                                    mixedCombineGroup['8'].map((option) => (
                                                        <Grid.Item>
                                                            <div
                                                                className={`popup-fixed-result-top-item ${selectedOptions.has(option) ? "active" : ""}`}
                                                                onClick={() => toggleSelectedOptions(option)}>{cnNameMap[option]}</div>
                                                        </Grid.Item>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                </Grid>
                            </div>
                        )
                    }
                    <div className="fixed">
                        <div className="fixed column" style={{ backgroundColor: "white" }}>
                            <div className="fixed-result-middle">
                                金额 <span style={{ color: "var(--adm-color-primary)" }}>{amount}</span> 元
                                <span className="fixed-result-middle-yellow" style={{ marginLeft: "10px" }}>
                                    预计奖金：{
                                        minBonus === maxBonus ? maxBonus : `${minBonus}-${maxBonus}`
                                    } 元</span>
                            </div>
                            <div className="row" style={{ height: "40px" }}>
                                <div className="row fixed-result-bottom-right flex-1 center align-items-center" onClick={() => setVisible(false)}>确定</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
            <div className="fixed column" style={{ backgroundColor: "white" }}>
                <div className="row fixed-result-top align-items-center">
                    <div className="flex-1 row center align-items-center" style={{ borderRight: "1px solid #F5F5F5" }} onClick={() => { setVisible(true) }}>{[...selectedOptions].map((option) => cnNameMap[option]).join(",") || "请选择"} <DownOutline /></div>
                    <div className="flex-1 row center align-items-center">
                        投 <div className="option-box row center align-items-center"><MinusOutline onClick={() => { if (times > 1) setTimes(times - 1) }} /></div>
                        <div className="option-value-box row center align-items-center" onClick={() => setInputModalVisible(true)}>{times}</div>
                        <div className="option-box row center align-items-center"><AddOutline onClick={() => { setTimes(times + 1) }} /></div> 倍
                    </div>
                </div>
                <div className="fixed-result-middle">
                    金额 <span style={{ color: "var(--adm-color-primary)" }}>{amount}</span> 元
                    <span className="fixed-result-middle-yellow" style={{ marginLeft: "10px" }}>
                        预计奖金：{
                            minBonus === maxBonus ? maxBonus : `${minBonus}-${maxBonus}`
                        } 元</span>
                </div>
                <div className="row" style={{ height: "40px" }}>
                    <div className="row fixed-result-bottom-left align-items-center flex-1">
                        {
                            enableFollowBuy && (<div onClick={() => { handleFollowBuy() }}>发单</div>)
                        }
                        {
                            enableGroupBuy && (<div onClick={() => { handleGroupBuy() }}>合买</div>)
                        }
                        <div onClick={() => { handleSave() }}>保存</div>
                    </div>
                    <div className="row fixed-result-bottom-right center align-items-center" onClick={handleOrderClick}>确认投注</div>
                </div>
            </div>
        </div>
    );
}


