import React, { useEffect, useState, useRef } from "react";
import { InfiniteScroll, NavBar, Tabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getGroupBuyMeInfo, getGroupBuyMeList } from "../../axios";
import { getTime } from "../../utils";
import { cnNameMap } from "../../constant/cnNameMap";
import FootballPNG from "../../assets/407.png";
import BasketballPNG from "../../assets/406.png";
import BeijingPNG from "../../assets/405.png";
import PickThreePNG from "../../assets/202.png";
import defaultUserIcon from "../../assets/account.bdcd4100.png";
import "./GroupBuyMe.css";
const status2CN = ["进行中", "进行中", "已截止", "未中奖", "中奖", "中奖", "已流单"]
export default function GroupBuyMe() {
    const navigate = useNavigate();
    const pagenation = useRef({ pageNum: 0, pageSize: 10, loading: false, hasMore: false });
    const [latestWin, setLatestWin] = useState(0);
    const [latestTotal, setLatestTotal] = useState(0);
    const [groupBuyRecord, setGroupBuyRecord] = useState({});
    const [groupBuyItemList, setGroupBuyItemList] = useState<any>([]);
    const [userInfo, setUserInfo] = useState<any>({});
    const [currentType, setCurrentType] = useState("creator");
    useEffect(() => {
        getGroupBuyMeInfo({}).then(({ data }) => {
            setLatestWin(data.latestWin);
            setLatestTotal(data.latestTotal);
            setGroupBuyRecord(data?.userInfo?.groupBuyRecord || {});
            setUserInfo(data?.userInfo);
        });
        if (!pagenation.current.loading) {
            pagenation.current.loading = true;
            getGroupBuyMeList({
                type: currentType,
                pageNum: pagenation.current.pageNum++,
                pageSize: pagenation.current.pageSize
            }).then(({ data }) => {
                setGroupBuyItemList(data.groupBuyItemList || []);
                pagenation.current.hasMore = data.groupBuyItemList.length === 10;
            }).finally(() => {
                pagenation.current.loading = false;
            })
        }
    }, [])

    useEffect(() => {
        if (!pagenation.current.loading) {
            pagenation.current.loading = true;
            pagenation.current.pageNum = 0;
            getGroupBuyMeList({
                type: currentType,
                pageNum: pagenation.current.pageNum++,
                pageSize: pagenation.current.pageSize
            }).then(({ data }) => {
                setGroupBuyItemList(data.groupBuyItemList || []);
                pagenation.current.hasMore = data.groupBuyItemList.length === 10;
            }).finally(() => {
                pagenation.current.loading = false;
            })
        }
    }, [currentType])

    const loadMore = async () => {
        if (!pagenation.current.loading) {
            pagenation.current.loading = true;
            getGroupBuyMeList({
                type: currentType,
                pageNum: pagenation.current.pageNum++,
                pageSize: pagenation.current.pageSize
            }).then(({ data }) => {
                setGroupBuyItemList([...groupBuyItemList, ...data.groupBuyItemList as any[]]);
                pagenation.current.hasMore = (data.groupBuyItemList.length === 10);
            }).finally(() => {
                pagenation.current.loading = false;
            })
        }
    }
    return (
        <div className="aui-flexView group-buy-me">
            <NavBar
                onBack={() => navigate(-1)}>
                合买详情
            </NavBar>
            <div className="bg-primary-gradient180" style={{ "width": "100%", "height": "245px", "display": "flex", "justifyContent": "center" }}>
                <div className="hemai-top-bg">
                    <div style={{ "display": "flex", "alignItems": "center" }}>
                        <div className="uni-image img1" style={{ "width": "82px", "height": "82px", "marginTop": "-12px" }}>
                            <img src={userInfo.avatar || defaultUserIcon} draggable="false" alt=""/>
                        </div>
                        <div className=" u-m-l-20 u-m-t-40">
                            <div style={{ "display": "flex", "alignItems": "center" }}>
                                <div style={{ "fontWeight": 900, "fontSize": "19px" }}>
                                    <span><span>{userInfo?.username}</span></span>
                                </div>
                                {/* <div className="level_box level3"></div> */}
                            </div>
                            <div className="u-m-t-15" style={{ "fontSize": "12px", "color": "rgb(68, 68, 68)", "display": "flex" }}>
                                <span style={{}}><span>粉丝0人</span></span>
                                <span style={{ "margin": "0px 5px" }}>
                                    <span>|</span>
                                </span>
                                <span><span>关注0人</span></span>
                            </div>
                        </div>
                    </div>
                    <div className="u-m-t-20" style={{ "display": "flex", "flexDirection": "column" }}>
                        <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                            <div className="u-m-l-20" style={{ "width": "50%" }}>
                                <div style={{ "fontSize": "18px", "color": "rgb(34, 34, 34)" }}>
                                    <span><span>{groupBuyRecord?.bonus}元</span></span>
                                </div>
                                <div className="u-m-t-10" style={{ "fontSize": "12px", "color": "rgb(136, 136, 136)" }}>累计中奖</div>
                            </div>
                            <div style={{ "width": "50%" }}>
                                <div style={{ "fontSize": "18px", "color": "rgb(34, 34, 34)" }}>
                                    <span><span>{groupBuyRecord.maxBonus}元</span></span>
                                </div>
                                <div className="u-m-t-10" style={{ "fontSize": "12px", "color": "rgb(136, 136, 136)" }}>
                                    历史最大奖项
                                </div>
                            </div>
                        </div>
                        <div className="u-m-t-20 u-p-t-10" style={{ "display": "flex", "justifyContent": "space-between", "borderTop": "1px solid rgb(235, 232, 232)" }}>
                            <div className="u-m-l-20 u-m-t-10" style={{ "width": "50%" }}>
                                <div style={{ "fontSize": "18px", "color": "rgb(34, 34, 34)" }}>
                                    <span>{ latestTotal > 0 ? `${latestTotal}中${latestWin}` : "--" }</span>
                                </div>
                                <div className="u-m-t-10" style={{ "fontSize": "12px", "color": "rgb(136, 136, 136)" }}>7日命中</div>
                            </div>
                            <div className="u-m-t-10" style={{ "width": "50%" }}>
                                <div style={{ "fontSize": "18px", "color": "rgb(34, 34, 34)" }}>
                                    <span><span>{groupBuyRecord.leadNum}人</span></span>
                                </div>
                                <div className="u-m-t-10" style={{ "fontSize": "12px", "color": "rgb(136, 136, 136)" }}>带红人数</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="u-m-l-20" style={{ "fontSize": "18px", "fontWeight": 900, "margin": "17px" }}>
                合买记录
            </div>
            <Tabs onChange={(type) => setCurrentType(type) }>
                <Tabs.Tab title='发起的' key='creator'></Tabs.Tab>
                <Tabs.Tab title='参与的' key='participator'></Tabs.Tab>
            </Tabs>
            <div className="container">
                {
                    groupBuyItemList.map((item: any) => {
                        const { task = {}, purchasedCopies } = item;
                        return (
                            <>
                                {
                                    task && (
                                        <div className="item" onClick={() => navigate(`/person/groupbuy/detail/${task._id}`)}>
                                        <div className="top">
                                            <div className="item_left">
                                                <div className="uni-image">
                                                    {
                                                        (task.type === "football") && (<img src={FootballPNG} alt="" style={{ width: "40px" }}/>)
                                                    }
                                                    {
                                                        (task.type === "beijing") && (<img src={BeijingPNG} alt="" style={{ width: "40px" }}/>)
                                                    }
                                                    {
                                                        (task.type === "basketball") && (<img src={BasketballPNG} alt="" style={{ width: "40px" }}/>)
                                                    }
                                                    {
                                                        (task.type === "pickThree") && (<img src={PickThreePNG} alt="" style={{ width: "40px" }}/>)
                                                    }
                                                </div>
                                                <div className="d">
                                                    <p style={{ "fontSize": "16px", "fontWeight": 600 }}>{ cnNameMap[task.betType] }</p>
                                                    <p style={{ "marginTop": "10px", "color": "rgb(149, 153, 166)" }}>截止时间：{ getTime(task.closeTime) }</p>
                                                </div>
                                            </div>
                                            <div className="item_right">
                                                <span className="t1 txt-primary"><span>{status2CN[task.status || 0]}</span></span>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div className="info_box">
                                                <div className="info_box_top">{ task.amount }</div>
                                                <div className="info_box_foot">方案金额</div>
                                            </div>
                                            <div className="info_box">
                                                <div className="info_box_top">{ task.commissionPercentage * 100 }%</div>
                                                <div className="info_box_foot">佣金比例</div>
                                            </div>
                                            <div className="info_box">
                                                <div className="info_box_top">{purchasedCopies}/{task.totalCopies}</div>
                                                <div className="info_box_foot">认购份额</div>
                                            </div>
                                            <div className="info_box">
                                                <div className="info_box_top">{ (purchasedCopies * task.amountPerCopy || 0).toFixed(2)  }</div>
                                                <div className="info_box_foot">
                                                    <span><span>参与金额</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    })
                }
                <InfiniteScroll hasMore={pagenation.current.hasMore} loadMore={loadMore} />
            </div>
        </div>
    )
}