import React from "react";
import { Tabs } from "antd-mobile";
import { getTime } from "../../utils";

export default function PickThreeDefault(props: any) {
    const { targetArrHundred, targetArrTen, targetArrBit, onSubTypeChange, onTargetClick, doRandom, currentTerm, closeTime } = props;
    return (
        <Tabs onChange={(type) => onSubTypeChange(type)}>
            <Tabs.Tab title='定位复式' key='101'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div>每位至少选择
                            <span style={{ color: "red" }}>1</span>
                            个号码，奖金
                            <span style={{ color: "red" }}>1040</span>
                            元</div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot">
                        <div className="number-red-foot-text">百位</div>
                        <div className="number-red-foot-view">
                            <div className={`number-red-foot-num ${targetArrHundred.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 0)}>0</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 1)}>1</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrHundred.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("hundred", 9)}>9</div>
                        </div>
                    </div>
                    <div className="number-red-foot">
                        <div className="number-red-foot-text">十位</div>
                        <div className="number-red-foot-view">
                            <div className={`number-red-foot-num ${targetArrTen.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 0)}>0</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 1)}>1</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrTen.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 9)}>9</div>
                        </div>
                    </div>
                    <div className="number-red-foot">
                        <div className="number-red-foot-text">个位</div>
                        <div className="number-red-foot-view">
                            <div className={`number-red-foot-num ${targetArrBit.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 0)}>0</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 1)}>1</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 9)}>9</div>
                        </div>
                    </div>
                </div>
            </Tabs.Tab>
            <Tabs.Tab title='组合复式' key='102'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div>每位至少选择
                            <span style={{ color: "red" }}>3</span>
                            个号码，奖金
                            <span style={{ color: "red" }}>1040</span>
                            元
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot" style={{ justifyContent: "center" }}>
                        <div className="number-red-foot-view"
                            style={{ width: "81vw", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className={`number-red-foot-num ${targetArrBit.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 0)}>0</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 1)}>1</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 9)}>9</div>
                        </div>
                    </div>
                </div>
            </Tabs.Tab>
            <Tabs.Tab title='和值' key='103'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div>每位至少选择
                            <span style={{ color: "red" }}>1</span>
                            个号码，奖金
                            <span style={{ color: "red" }}>1040</span>
                            元
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot" style={{ justifyContent: "center", border: "0px" }}>
                        <div className="number-red-foot-view">
                            <div className={`number-red-foot-num ${targetArrBit.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 0)}>0</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 1)}>1</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 9)}>9</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(10) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 10)}>10</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(11) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 11)}>11</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(12) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 12)}>12</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(13) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 13)}>13</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(14) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 14)}>14</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(15) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 15)}>15</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(16) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 16)}>16</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(17) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 17)}>17</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(18) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 18)}>18</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(19) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 19)}>19</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(20) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 20)}>20</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(21) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 21)}>21</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(22) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 22)}>22</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(23) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 23)}>23</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(24) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 24)}>24</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(25) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 25)}>25</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(26) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 26)}>26</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(27) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 27)}>27</div>
                        </div>
                    </div>
                </div>
            </Tabs.Tab>
            <Tabs.Tab title='组合胆拖' key='104'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div>胆码(选择1-2个),奖金<span style={{ "color": "red" }}><span>1040</span></span>元</div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot" style={{ border: "0px", marginTop: "8px", justifyContent: "center" }}>
                        <div className="number-red-foot-view"
                            style={{ width: "81vw", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className={`number-red-foot-num  ${targetArrTen.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 0)}>0</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 1)}>1</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 2)}>2</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 3)}>3</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 4)}>4</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 5)}>5</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 6)}>6</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 7)}>7</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 8)}>8</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 9)}>9</div>
                        </div>
                    </div>
                    <div className="number-red-top" style={{ marginTop: "8px" }}>
                        <div className="">拖码(胆码与拖码数量之和大于等于4)</div>
                        <div className="number-red-top-last" onClick={doRandom}></div>
                    </div>
                    <div className="number-red-foot" style={{ border: "0px", marginTop: "8px", justifyContent: "center" }}>
                        <div className="number-red-foot-view"
                            style={{ width: "81vw", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className={`number-red-foot-num  ${targetArrBit.has(0) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 0)} style={{ color: "rgb(17, 133, 210)" }}>0</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(1) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 1)} style={{ color: "rgb(17, 133, 210)" }}>1</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(2) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 2)} style={{ color: "rgb(17, 133, 210)" }}>2</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(3) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 3)} style={{ color: "rgb(17, 133, 210)" }}>3</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(4) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 4)} style={{ color: "rgb(17, 133, 210)" }}>4</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(5) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 5)} style={{ color: "rgb(17, 133, 210)" }}>5</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(6) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 6)} style={{ color: "rgb(17, 133, 210)" }}>6</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(7) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 7)} style={{ color: "rgb(17, 133, 210)" }}>7</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(8) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 8)} style={{ color: "rgb(17, 133, 210)" }}>8</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(9) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 9)} style={{ color: "rgb(17, 133, 210)" }}>9</div>
                        </div>
                    </div>
                </div>
            </Tabs.Tab>
            <Tabs.Tab title='跨度复式' key='105'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div>至少选择<span style={{"color":"red"}}><span>1</span></span>个跨度值，奖金<span style={{"color":"red"}}><span>1040</span></span>元</div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot" style={{ justifyContent: "center", border: "0px" }}>
                        <div className="number-red-foot-view"
                            style={{ width: "81vw", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className={`number-red-foot-num ${targetArrBit.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 0)}>0</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 1)}>1</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 9)}>9</div>
                        </div>
                    </div>
                </div>
            </Tabs.Tab>
        </Tabs>
    )
}