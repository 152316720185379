import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Switch, Tabs, Toast } from "antd-mobile";
import defaultUserPNG from "../../assets/default_photo.8b581359.png";
import "./FollowBuyCustomize.css";
import { getFollowBuyCustomize } from "../../axios";

export default function FollowBuyCustomize() {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentType, setCurrentType] = useState("multiple");
    const [times, setTimes] = useState(1);
    const [percent, setPercent] = useState(10);
    const [oneTimeAmountVisible, setOneTimeAmountVisible] = useState(true);
    const [minAmount, setMinAmount] = useState<number | null>(null);
    const [maxAmount, setMaxAmount] = useState<number | null>(null);
    const followUserId = useMemo(() => {
        return location.state?.id
    }, [location.state?.id])
    const handleTabChange = (val: string) => {
        setMinAmount(null);
        setMaxAmount(null);
        setCurrentType(val);
    }

    const handleTimesChange = (time: string) => {
        let _time = Number(time);
        if(_time > 50000) {
            Toast.show("跟单倍数最高为50000倍")
            _time = 50000;
        }
        if(_time <1) {
            Toast.show("跟单倍数最低为1倍")
            _time = 1;
        }
        setTimes(_time);
    }
    const handleSubTimes = () => {
        if(times > 1) {
            setTimes(times-1);
        } else {
            Toast.show("跟单倍数最低为1倍")
        }
    }
    const handleAddTimes = () => {
        if(times <= 50000) {
            setTimes(times+1);
        } else {
            Toast.show("跟单倍数最高为50000倍")
        }
    }

    const handleConfirm = () => {
        if(currentType === "multiple") {
            if(oneTimeAmountVisible) {
                if(minAmount && minAmount >= 2 && maxAmount && maxAmount <= 200000  && minAmount <= maxAmount) {

                } else {
                    Toast.show("金额需要在规定范围内");
                    return;
                }
            }
        } else {
            if(maxAmount && maxAmount <= 200000) {

            } else {
                Toast.show("金额需要在规定范围内");
                return;
            }
        }
        getFollowBuyCustomize({
            type: currentType, followUserId, oneTimeAmountVisible, minAmount, maxAmount, percent
        }).then(({data}) => {
            navigate(-1);
        }).catch(() => {
            Toast.show("定制失败");
        })
    }

    return (
        <div className="aui-flexView follow-buy-customize">
            <Tabs defaultActiveKey={"multiple"} onChange={handleTabChange}>
                <Tabs.Tab title='倍数跟单' key='multiple'>
                    <div>
                        <div className="t-tips">
                            <p>倍数跟单：根据发单人的起投金额，设置倍数进行跟单投注。</p>
                        </div>
                        <div>
                            <div className="gd-box">
                                <div className="title">
                                    <div className="left">
                                        <div className="uni-image">
                                            <img src={defaultUserPNG} draggable="false" />
                                        </div>
                                        <span style={{ marginLeft: "8px" }}>幸运大转轮</span>
                                    </div>
                                    <div className="right">
                                        <div className="uni-image">
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAARVBMVEW1tbW1tbX///+1tbUAAAD5+fju7e3Jx8bc2dnQzc0jIyN7e3sODg7k4+OmpqaPj49dXV1JSUk9PT0xMTG8vLyenp5xcXF9ClRrAAAAAnRSTlPmSXG3YGYAAACkSURBVCjPjdLZDoIwEIXh8bSdQtlBff9H9YyKGBkM/wVLv6RLUrlIcCNIPkgk1AcFCdVBpE7dOpKmfY0mJSXsusdZk0tNjHFwaaAs6tGNUhIcCpEpVsolr3I1qbFS5l/3ksok40M2R6tPafnZYyP0tjIANRmw0fskM1D4avBNbOTgONkDvwQOWxP2hGKywCPbQkku2ZEUPqEP8Mk6T6I+qfy5og8VxgrblTWv9QAAAABJRU5ErkJggg==" draggable="false" />
                                        </div>
                                        <span>关注</span>
                                    </div>
                                </div>
                                <div className="bs">
                                    <div className="left">跟单倍数</div>
                                    <div className="right">
                                        <div className="uni-image" onClick={() => handleSubTimes()}>
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAM1BMVEUAAAD4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEibp0CSAAAAEHRSTlMAvPUTQHBMfJEn3M6tWDBlhAcpAAAAASBJREFUSMeUz1tuwzAQQ1FqpnpYD+fuf7UtkgaOYSdWzq9AcagDHyVU+1NDGa4LHhs7LX7KeDGwkNPiki8pBwMrbyPRIAztjAAWdWZp0FcdrB3aooNk1KRTqWKHpxt01xve4aadCFkfZIi7Uoj6KEJ62WtkXcjYtrzRdanTtrrquuT1ebYbSRMS9vi30DWlU/4LVk1ZHxWRoEnhvqIxNGnQJMc0zXANgqYFhgpZ0zJFgaRpiaDKomkL9b7jaf05s2wBxwSvm85kbeDbgO1OKr+l1ssJACAMBFGFQI72363HIMTDI6nAz2ZmdzfnORJfmp+VP46jweHTePMC8YoqBBgzDDJHpcPYce9CcWWVFGO1EyVF0G43+RN7anUYlROuPxdeCBM23X3HtwAAAABJRU5ErkJggg==" draggable="false" />
                                        </div>
                                        <div className="u-input u-input--border" style={{ padding: "0px 8px", borderColor: "rgb(0, 0, 0)", textAlign: "center" }}>
                                            <div className="u-input__input" style={{ minHeight: "8px" }}>
                                                <div className="uni-input-wrapper">
                                                    <input 
                                                        maxLength={5} 
                                                        step="0.000000000000000001" 
                                                        enterKeyHint="done" 
                                                        pattern="[0-9]*" 
                                                        autoComplete="off" 
                                                        type="number" 
                                                        className="uni-input-input" 
                                                        value={times}
                                                        onChange={(e) => handleTimesChange(e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="u-input__right-icon u-flex"></div>
                                        </div>
                                        <div className="uni-image" onClick={() => handleAddTimes()}>
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAM1BMVEUAAAD4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEj4SEibp0CSAAAAEHRSTlMAvfRBEXpszpFZ3K1OKBswOhRM9wAAATNJREFUSMeVlt2ygzAIhAGJ+Y/7/k97nJ461ibWsJeMn7OQBKBOGgMn2ZU4RKUH6epxkV9/MS0IIOxK1Z2txbEAEhrdyAnAUa/+GBBHI1UP5K2PbxnwtY8XQVpoqCVByndwBfJtepqBtfve0Q+5L6J0fxg4+HBVBY4e5CBn5h6ZHpXhTzjpM6DpsNEEC01ogfyfeegNbcxhZOoVVMHW/wzcAxtEXwVjmgOIsb5KFGeBCE+kEJ0FVNB2imkWIEakADcPOIQdKvNAAVNCnQcqEgn0LPTy1gr45VA7AYUQ8JnTQJccASsgF0uB3/KA8KF4sWRO2lxW88GZr4b58pmvt/0B2Z8oBeQ5ICPctZnmXBy2GWsjM7dKezO2t3v7QLGPLPtQtI9d+2C3rw725aRff4Cb9ecP/ewWlWLWF2kAAAAASUVORK5CYII=" draggable="false" />
                                        </div>
                                    </div>
                                </div>
                                <div className="money">
                                    <div className="set">
                                        <div className="left">单倍金额设置</div>
                                        <div className="right">
                                            <Switch checked={oneTimeAmountVisible} onChange={(checked) => setOneTimeAmountVisible(checked)}/>
                                        </div>
                                    </div>
                                    {
                                        oneTimeAmountVisible && (
                                            <div className="setMoney">
                                                <div className="left">单倍金额设置</div>
                                                <div className="right">
                                                    <div className="u-input u-input--border" style={{ padding: "0px 8px", borderColor: "rgb(0, 0, 0)", textAlign: "center" }}>
                                                        <div className="u-input__input" style={{ minHeight: "17px" }}>
                                                            <div className="uni-input-wrapper">
                                                                {
                                                                    !minAmount && (
                                                                        <div className="uni-input-placeholder input-placeholder" style={{ color: "rgb(192, 196, 204)", fontSize: "6px" }}>最低2元</div>
                                                                    )
                                                                }
                                                                <input 
                                                                    maxLength={140} 
                                                                    step="0.000000000000000001" 
                                                                    enterKeyHint="done" 
                                                                    pattern="[0-9]*" 
                                                                    autoComplete="off" 
                                                                    type="number" 
                                                                    className="uni-input-input" 
                                                                    value={minAmount as number}
                                                                    onChange={(e) => setMinAmount(Number(e.target.value))}
                                                                    />
                                                            </div>
                                                        </div>
                                                        <div className="u-input__right-icon u-flex"></div>
                                                    </div>
                                                    <span>-</span>
                                                    <div className="u-input u-input--border" style={{ padding: "0px 8px", borderColor: "rgb(0, 0, 0)", textAlign: "center" }}>
                                                        <div className="u-input__input" style={{ minHeight: "17px" }}>
                                                            <div className="uni-input-wrapper">
                                                                {
                                                                    !maxAmount && (
                                                                        <div className="uni-input-placeholder input-placeholder" style={{ color: "rgb(192, 196, 204)", fontSize: "6px" }}>最高20万元</div>
                                                                    )
                                                                }
                                                                <input 
                                                                    maxLength={140} 
                                                                    step="0.000000000000000001" 
                                                                    enterKeyHint="done" 
                                                                    pattern="[0-9]*" 
                                                                    autoComplete="off" 
                                                                    type="number" 
                                                                    className="uni-input-input" 
                                                                    value={maxAmount as number}
                                                                    onChange={(e) => setMaxAmount(Number(e.target.value))}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="u-input__right-icon u-flex"></div>
                                                    </div>
                                                    <span>元</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="f-tips">钱包余额不足时，无法进行自动跟单。</div>
                            <div className="btn" onClick={handleConfirm}>
                                <span>确定</span>
                            </div>
                        </div>
                    </div>
                </Tabs.Tab>
                <Tabs.Tab title='比例跟单' key='proportional'>
                    <div>
                        <div className="t-tips"><p>比例跟单：根据发单人的自购金额，设置比例进行跟单投注。</p></div>
                        <div>
                            <div className="gd-box">
                                <div className="title">
                                    <div className="left">
                                        <div className="uni-image">
                                            <img src="https://docqe.oss-cn-hangzhou.aliyuncs.com/upload/memberphoto/20230804/83671691157973092.jpeg" draggable="false" />
                                        </div>
                                        <span style={{ marginLeft: "8px" }}>幸运大转轮</span>
                                    </div><div className="right">
                                        <div className="uni-image">
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAARVBMVEW1tbW1tbX///+1tbUAAAD5+fju7e3Jx8bc2dnQzc0jIyN7e3sODg7k4+OmpqaPj49dXV1JSUk9PT0xMTG8vLyenp5xcXF9ClRrAAAAAnRSTlPmSXG3YGYAAACkSURBVCjPjdLZDoIwEIXh8bSdQtlBff9H9YyKGBkM/wVLv6RLUrlIcCNIPkgk1AcFCdVBpE7dOpKmfY0mJSXsusdZk0tNjHFwaaAs6tGNUhIcCpEpVsolr3I1qbFS5l/3ksok40M2R6tPafnZYyP0tjIANRmw0fskM1D4avBNbOTgONkDvwQOWxP2hGKywCPbQkku2ZEUPqEP8Mk6T6I+qfy5og8VxgrblTWv9QAAAABJRU5ErkJggg==" draggable="false" />
                                        </div>
                                        <span>关注</span>
                                    </div>
                                </div>
                                <div className="bs">
                                    <div className="left">跟单比例</div>
                                    <div className="right">
                                        <div className="u-input u-input--border" style={{ padding: "0px 8px", borderColor: "rgb(0, 0, 0)", textAlign: "center" }}>
                                            <div className="u-input__input" style={{ minHeight: "17px" }}>
                                                <div className="uni-input-wrapper">
                                                    <input 
                                                        maxLength={140} 
                                                        step="0.000000000000000001" 
                                                        enterKeyHint="done" 
                                                        pattern="[0-9]*" 
                                                        autoComplete="off" 
                                                        type="number" 
                                                        className="uni-input-input"
                                                        defaultValue={percent}
                                                        onChange={(e) => setPercent(Number(e.target.value))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="u-input__right-icon u-flex"></div>
                                        </div>
                                        <span>%</span>
                                    </div>
                                </div>
                                <div className="money">
                                    <div className="set">
                                        <div className="left">自购金额设置</div>
                                        <div className="right">
                                            <div data-v-2865af89="" className="u-switch u-switch--on" style={{ fontSize: "15px" }}>
                                                <div data-v-2865af89="" className="u-switch__node node-class" style={{ width: "15px", height: "15px" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="setMoney">
                                        <div className="left">自购金额设置</div>
                                        <div className="right">
                                            <div className="u-input u-input--border" style={{ padding: "0px 8px", borderColor: "rgb(0, 0, 0)", textAlign: "center" }}>
                                                <div className="u-input__input" style={{ minHeight: "17px" }}>
                                                    <div className="uni-input-wrapper">
                                                        <div className="uni-input-placeholder input-placeholder" style={{ color: "rgb(192, 196, 204)", fontSize: "6px" }}>最高20万元</div>
                                                        <input 
                                                            maxLength={140} 
                                                            step="0.000000000000000001" 
                                                            enterKeyHint="done" 
                                                            pattern="[0-9]*" 
                                                            autoComplete="off" 
                                                            type="number" 
                                                            className="uni-input-input"
                                                            onChange={(e) => setMaxAmount(Number(e.target.value))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="u-input__right-icon u-flex"></div>
                                            </div>
                                            <span>元</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="f-tips">钱包余额不足时，无法进行自动跟单。</div>
                            <div className="btn" onClick={handleConfirm}>
                                <span>确定</span>
                            </div>
                        </div>
                    </div>
                </Tabs.Tab>
            </Tabs>
        </div>
    )
}