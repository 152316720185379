import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import { Collapse, NavBar, Tabs, Toast, Button, Mask, Empty } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMatch } from "../../axios/index.js";
import { getDay, getTimeInDay } from "../../utils";
import { matchItemMap, matchInfo, matchItem } from "../../interface/index.tsx";
import FootballMixedModal from "./footballMixedModal.tsx";
import FootballScoreModal from "./footballScoreModal.tsx";
import { cnNameMap } from "../../constant/cnNameMap";

export default function Football() {
    const navigate = useNavigate();
    const location = useLocation();
    const shop = useSelector((state) => state.shop.shop);
    const earlyCloseTime = useMemo(() => ( shop.config?.["football"]?.["earlyCloseTime"] || 5 * 60 * 1000 ),[shop]);
    const _selectedItems = useMemo(() => location.state?.selectedItems || {}, [location.state]);
    const _subType = useMemo(() => location.state?.subType || 'mixed', [location.state]);
    
    const [currentSubType, setCurrentSubType] = useState<string>(_subType);
    const [matchList, setMatchList] = useState<[string, matchInfo[]][]>();
    const [matchIdList, setMatchIdList] = useState<string[]>();
    const [matchCloseTime, setMatchCloseTime] = useState<{ [key: string]: number }>({});
    const [selectedItems, setSelectedItems] = useState<matchItemMap>(_selectedItems);
    const [selectedMatchNum, setSelectedMatchNum] = useState<number>(0);
    const [single, setSingle] = useState(false);
    const [canDoOrder, setCanDoOrder] = useState(false);
    const [mixedModalVisible, setMixedModalVisible] = useState(false);
    const [mixedModalItem, setMixedModalItem] = useState<any>();
    const [mixedModalMatch, setMixedModalMatch] = useState<any>();
    const [scoreModalVisible, setScoreModalVisible] = useState(false);
    const [scoreModalItem, setScoreModalItem] = useState<any>();
    const [scoreModalMatch, setScoreModalMatch] = useState<any>();
    const [multiCountAry, setMultiCountAry] = useState<{ [key: string]: number }>({});
    const back = () => {
        navigate("/");;
    };

    useEffect(() => {
        getMatch({ type: "football" }).then(({ data }: { data: { matchList: matchInfo[] } }) => {
            console.log(data);
            const { matchList } = data;
            const matchGroupByDate: { [key: string]: matchInfo[] } = {};
            const _matchCloseTime: { [key: string]: number } = {};
            for (let match of matchList) {
                const { businessDate, _id, closeTime, odds } = match;
                if (!odds) { continue };
                _matchCloseTime[_id] = closeTime;
                if (matchGroupByDate[businessDate]) {
                    matchGroupByDate[businessDate].push(match)
                } else {
                    matchGroupByDate[businessDate] = [match];
                }
            }
            setMatchCloseTime(_matchCloseTime);
            const _matchList = Object.entries(matchGroupByDate).sort((a, b) => {
                const dateA = new Date(a[0]).getTime();
                const dateB = new Date(b[0]).getTime();
                return dateA - dateB;
            });
            setMatchList(_matchList);
            let matchIdList: string[]  = [];
            for(let matchInfoList of _matchList) {
                matchIdList = matchIdList.concat(matchInfoList[1].map((match) => match._id));
            };
            setMatchIdList(matchIdList);
        });
    }, []);

    useEffect(() => {
        let enableSingle = true;
        let num = 0;
        const _multiCountAry:{[key: string]: number} = {};
        const matchList = Object.keys(selectedItems);
        for (let match of matchList) {
            const item = selectedItems[match];
            let types = Object.keys(item).filter(type => item[type].target.size);
            if (types.length > 0) {
                _multiCountAry[match] = 0;
                num++;
                for(let type of types) {
                    _multiCountAry[match] += item[type].target.size;
                    enableSingle = enableSingle && selectedItems[match][type].single
                }
            }
        }
        enableSingle = num > 0 && enableSingle;
        setSelectedMatchNum(num);
        setSingle(enableSingle);
        setMultiCountAry(_multiCountAry);
        if (num > 1 || enableSingle) {
            setCanDoOrder(true);
        } else {
            setCanDoOrder(false);
        }
    }, [selectedItems]);

    const handleTabChange = async (data: string) => {
        setSelectedItems({});
        setCurrentSubType(data);
    }

    const handleItemClick = async (matchId: string, type: string, target: string, item: any) => {
        if(selectedMatchNum === 8 && !multiCountAry[matchId]) {
            Toast.show("最多只能选择8场比赛");
            return;
        }
        if (selectedItems[matchId]) {
            if (selectedItems[matchId][type]) {
                if (selectedItems[matchId][type].target.has(target)) {
                    selectedItems[matchId][type].target.delete(target);
                } else {
                    selectedItems[matchId][type].target.add(target);
                }
            } else {
                selectedItems[matchId][type] = {
                    type,
                    goalLine: item.goalLine,
                    single: item.single,
                    target: new Set([target]),
                    match: matchId,
                }
            }
        } else {
            multiCountAry[matchId] = 1;
            selectedItems[matchId] = {
                [type]: {
                    type,
                    goalLine: item.goalLine,
                    single: item.single,
                    target: new Set([target]),
                    match: matchId,
                }
            }
        };
        setMultiCountAry({ ...multiCountAry });
        setSelectedItems({ ...selectedItems });
    }

    const handleMixedPopupClick = (match: matchInfo) => {
        setMixedModalItem({ ...selectedItems[match._id] } || {});
        setMixedModalMatch(match);
        setMixedModalVisible(true);
    }

    const handleMixedModalConfirm = async (matchId: string, item: matchItem) => {
        if(selectedMatchNum === 8 && !multiCountAry[matchId]) {
            Toast.show("最多只能选择8场比赛");
            setMixedModalVisible(false);
            return;
        }
        selectedItems[matchId] = item;
        setSelectedItems({ ...selectedItems });
        setMixedModalVisible(false);
    };

    const handleMixedModalCancel = async () => {
        setMixedModalVisible(false);
    }

    const handleScorePopupClick = (match: matchInfo) => {
        setScoreModalItem({ ...selectedItems[match._id] } || {});
        setScoreModalMatch(match);
        setScoreModalVisible(true);
    }

    const handleScoreModalConfirm = async (matchId: string, item: matchItem) => {
        if(selectedMatchNum === 8 && !multiCountAry[matchId]) {
            Toast.show("最多只能选择8场比赛");
            setScoreModalVisible(false);
            return;
        }
        selectedItems[matchId] = item;
        setSelectedItems({ ...selectedItems });
        setScoreModalVisible(false);
    };

    const handleScoreModalCancel = async () => {
        setScoreModalVisible(false);
    }

    const handleTakeOrderClick = async () => {
        const now = Date.now();
        // if(now < startTime || now > endTime) {
        //     Toast.show("未到下单时间");
        //     return;
        // }
        if(!canDoOrder) {
            Toast.show("请选择一场单关或两场比赛");
            return;
        }
        const _matchIdList = matchIdList?.filter((matchId) => selectedItems[matchId]);
        if(!_matchIdList || _matchIdList.length === 0) {
            Toast.show("请选择一场单关或两场比赛");
            return;
        }
        let items: matchItemMap = {};
        let closeTime = Infinity;
        for (let matchId of _matchIdList) {
            const selectedItem = selectedItems[matchId];
            let types = Object.keys(selectedItem).filter(type => selectedItem[type].target.size);
            if (types.length > 0) {
                closeTime = Math.min(closeTime, matchCloseTime[matchId]);
                items[matchId] = {};
                for (let type of types) {
                    items[matchId][type] = { ...selectedItem[type] };
                    items[matchId][type].target = [...items[matchId][type].target]
                }
            }
        }
        closeTime = closeTime - earlyCloseTime;
        if (now > closeTime) {
            Toast.show("包含已截止比赛");
            return;
        }
        navigate("/person/betsummarize", { state: { items, selectedItems, type: "football", subType: currentSubType, closeTime, single } })
    }
    return (<div className="football-container">
        <NavBar onBack={back}>
            竞彩足球
        </NavBar>
        <Tabs defaultActiveKey='mixed' onChange={handleTabChange}>
            <Tabs.Tab title="混合过关" key="mixed">
                <Collapse defaultActiveKey={['0']}>
                    {matchList && matchList.map((match, index) => {
                        const [date, _matchList] = match;
                        return (
                            <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}>
                                {
                                    _matchList && _matchList.map((match: matchInfo, index) => {
                                        const { odds: { had, hhad } } = match || { odds: {} };
                                        return (
                                            <div className="item_body">
                                                <div className="left">
                                                    <p>{match.matchNumStr}</p>
                                                    <p style={{ "color": "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                                    <p style={{ "marginTop": "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                                    {/* <p className="txt-primary">分析</p> */}
                                                </div>
                                                <div className="right">
                                                    <div className="team">
                                                        <span style={{ color: "black" }}>{match.homeTeamAbbName}</span>
                                                        <span style={{ "color": "rgb(153, 153, 153)" }}><span>VS</span></span>
                                                        <span style={{ color: "black" }}>{match.awayTeamAbbName}</span>
                                                    </div>
                                                    <div className="game_table">
                                                        <table className="game_data">
                                                            
                                                            <tr className="">
                                                                {
                                                                    had.win ? (
                                                                        <>
                                                                        <td width="7%" className="c_999 first-child">0</td>
                                                                        <td className={selectedItems[match._id]?.["had"]?.target.has("win") ? "active22" : ""} onClick={() => handleItemClick(match._id, "had", "win", had)}>胜<span className="c_999">{had.win}</span></td>
                                                                        <td className={selectedItems[match._id]?.["had"]?.target.has("draw") ? "active22" : ""} onClick={() => handleItemClick(match._id, "had", "draw", had)}>平<span className="c_999">{had.draw}</span></td>
                                                                        <td className={selectedItems[match._id]?.["had"]?.target.has("lose") ? "active22" : ""} onClick={() => handleItemClick(match._id, "had", "lose", had)}>负<span className="c_999">{had.lose}</span></td>
                                                                        </>
                                                                    ) : (
                                                                        <td colSpan={4} style={{height: "36px"}}>胜平负未开售</td>
                                                                    )
                                                                }
                                                                {
                                                                    multiCountAry[match._id] && multiCountAry[match._id] > 0 ? (
                                                                        <td width="15%" rowSpan={2} className="nonuse c_666 active22" onClick={(e, key = 0) => handleMixedPopupClick(match)}>
                                                                            选择 <br />
                                                                            {multiCountAry[match._id]}项
                                                                        </td>
                                                                    ) : (
                                                                        <td width="15%" rowSpan={2} className="nonuse c_666" style={{ background: "white" }} onClick={(e, key = 0) => handleMixedPopupClick(match)}>
                                                                            更多 <br />
                                                                            玩法
                                                                        </td>
                                                                    )
                                                                }
                                                            </tr>
                                                            <tr className="">
                                                                <td width="7%" className={`c_999  first-child c_999 ${Number(hhad.goalLine) > 0 ? "td_bg_yellow" : "td_bg_blue"}`}>{hhad.goalLine}</td>
                                                                <td className={selectedItems[match._id]?.["hhad"]?.target.has("win") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hhad", "win", hhad)}>胜<span className="c_999">{hhad.win}</span></td>
                                                                <td className={selectedItems[match._id]?.["hhad"]?.target.has("draw") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hhad", "draw", hhad)}>平<span className="c_999">{hhad.draw}</span></td>
                                                                <td className={selectedItems[match._id]?.["hhad"]?.target.has("lose") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hhad", "lose", hhad)}>负<span className="c_999">{hhad.lose}</span></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                {
                                                    (had?.single || hhad?.single) && (<div className="jz_item_mark">单关</div>)
                                                }
                                            </div>
                                        )
                                    })
                                }
                                <Mask visible={mixedModalVisible}>
                                    <FootballMixedModal item={mixedModalItem} matchInfo={mixedModalMatch} onConfirm={handleMixedModalConfirm} onCancel={handleMixedModalCancel} />
                                </Mask>
                            </Collapse.Panel>
                        )
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
            <Tabs.Tab title="胜平负/让球" key="had">
                <Collapse defaultActiveKey={['0']}>
                    {matchList && matchList.map((match, index) => {
                        const [date, _matchList] = match;
                        return (
                            <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}>
                                {
                                    _matchList && _matchList.map((match) => {
                                        const { odds: { had, hhad } } = match;
                                        return (
                                            <div className="item_body">
                                                <div className="left">
                                                    <p>{match.matchNumStr}</p>
                                                    <p style={{ "color": "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                                    <p style={{ "marginTop": "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                                    {/* <p className="txt-primary">分析</p> */}
                                                </div>
                                                <div className="right">
                                                    <div className="team">
                                                        <span style={{ color: "black" }}>{match.homeTeamAbbName}</span>
                                                        <span style={{ "color": "rgb(153, 153, 153)" }}><span>VS</span></span>
                                                        <span style={{ color: "black" }}>{match.awayTeamAbbName}</span>
                                                    </div>
                                                    <div className="game_table">
                                                        <table className="game_data">
                                                            <tr className="">
                                                                {
                                                                    had.win ? (
                                                                        <>
                                                                        <td width="7%" className="c_999 first-child">0</td>
                                                                        <td className={selectedItems[match._id]?.["had"]?.target.has("win") ? "active22" : ""} onClick={() => handleItemClick(match._id, "had", "win", had)}>胜<span className="c_999">{had.win}</span></td>
                                                                        <td className={selectedItems[match._id]?.["had"]?.target.has("draw") ? "active22" : ""} onClick={() => handleItemClick(match._id, "had", "draw", had)}>平<span className="c_999">{had.draw}</span></td>
                                                                        <td className={selectedItems[match._id]?.["had"]?.target.has("lose") ? "active22" : ""} onClick={() => handleItemClick(match._id, "had", "lose", had)}>负<span className="c_999">{had.lose}</span></td>
                                                                        </>
                                                                    ) : (
                                                                        <td colSpan={4} style={{height: "36px"}}>胜平负未开售</td>
                                                                    )
                                                                }
                                                            </tr>
                                                            <tr className="">
                                                                <td width="7%" className={`c_999  first-child c_999 ${Number(hhad.goalLine) > 0 ? "td_bg_yellow" : "td_bg_blue"}`}>{hhad.goalLine}</td>
                                                                <td className={selectedItems[match._id]?.["hhad"]?.target.has("win") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hhad", "win", hhad)}>胜<span className="c_999">{hhad.win}</span></td>
                                                                <td className={selectedItems[match._id]?.["hhad"]?.target.has("draw") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hhad", "draw", hhad)}>平<span className="c_999">{hhad.draw}</span></td>
                                                                <td className={selectedItems[match._id]?.["hhad"]?.target.has("lose") ? "active22" : ""} onClick={() => handleItemClick(match._id, "hhad", "lose", hhad)}>负<span className="c_999">{hhad.lose}</span></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                {
                                                    had?.single && (<div className="jz_item_mark">单关</div>)
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </Collapse.Panel>
                        )
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
            <Tabs.Tab title="总进球" key="ttg">
                <Collapse defaultActiveKey={['0']}>
                    {matchList && matchList.map((match, index) => {
                        const [date, _matchList] = match;
                        const type = "ttg";
                        return (
                            <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}>
                                {
                                    _matchList && _matchList.filter((match) => match.odds.ttg.goalLine !== undefined ).map((match) => {
                                        const { odds: { ttg } } = match;
                                        return (
                                            <div className="item_body">
                                                <div className="left">
                                                    <p>{match.matchNumStr}</p>
                                                    <p style={{ "color": "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                                    <p style={{ "marginTop": "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                                    {/* <p className="txt-primary">分析</p> */}
                                                </div>
                                                <div className="jz_item_mark">单关</div>
                                                <div className="right">
                                                    <div className="team">
                                                        <span style={{ color: "black" }}>{match.homeTeamAbbName}</span>
                                                        <span style={{ "color": "rgb(153, 153, 153)" }}><span>VS</span></span>
                                                        <span style={{ color: "black" }}>{match.awayTeamAbbName}</span>
                                                    </div>
                                                    <div className="game_table">
                                                        <table className="game_data">
                                                            <tr>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s0") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s0", ttg)}>
                                                                    <div className="c000">0</div>
                                                                    <div className="c_999 fs12">{ttg.s0}</div>
                                                                </td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s1") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s1", ttg)}>
                                                                    <div className="c000">1</div>
                                                                    <div className="c_999 fs12">{ttg.s1}</div>
                                                                </td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s2") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s2", ttg)}>
                                                                    <div className="c000">2</div>
                                                                    <div className="c_999 fs12">{ttg.s2}</div>
                                                                </td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s3") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s3", ttg)}>
                                                                    <div className="c000">3</div>
                                                                    <div className="c_999 fs12">{ttg.s3}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s4") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s4", ttg)}>
                                                                    <div className="c000">4</div>
                                                                    <div className="c_999 fs12">{ttg.s4}</div>
                                                                </td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s5") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s5", ttg)}>
                                                                    <div className="c000">5</div>
                                                                    <div className="c_999 fs12">{ttg.s5}</div>
                                                                </td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s6") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s6", ttg)}>
                                                                    <div className="c000">6</div>
                                                                    <div className="c_999 fs12">{ttg.s6}</div>
                                                                </td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("s7") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "s7", ttg)}>
                                                                    <div className="c000">7+</div>
                                                                    <div className="c_999 fs12">{ttg.s7}</div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Collapse.Panel>
                        )
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
            <Tabs.Tab title="半全场" key="hafu">
                <Collapse defaultActiveKey={['0']}>
                    {matchList && matchList.map((match, index) => {
                        const [date, _matchList] = match;
                        const type = "hafu";
                        return (
                            <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}>
                                {
                                    _matchList && _matchList.filter((match) => match.odds.hafu.goalLine !== undefined ).map((match) => {
                                        const { odds: { hafu } } = match;
                                        return (
                                            <div className="item_body">
                                                <div className="left">
                                                    <p>{match.matchNumStr}</p>
                                                    <p style={{ "color": "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                                    <p style={{ "marginTop": "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                                    {/* <p className="txt-primary">分析</p> */}
                                                </div>
                                                <div className="jz_item_mark">单关</div>
                                                <div className="right">
                                                    <div className="team">
                                                        <span style={{ color: "black" }}>{match.homeTeamAbbName}</span>
                                                        <span style={{ "color": "rgb(153, 153, 153)" }}><span>VS</span></span>
                                                        <span style={{ color: "black" }}>{match.awayTeamAbbName}</span>
                                                    </div>
                                                    <div className="game_table">
                                                        <table className="game_data fs12">
                                                            <tr>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("ww") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "ww", hafu)}>胜胜<br /><span className="c_999">{hafu.ww}</span></td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("wd") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "wd", hafu)}>胜平<br /><span className="c_999">{hafu.wd}</span></td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("wl") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "wl", hafu)}>胜负<br /><span className="c_999">{hafu.wl}</span></td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("dw") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "dw", hafu)}>平胜<br /><span className="c_999">{hafu.dw}</span></td>
                                                                <td rowSpan={2} width="16%" className={selectedItems[match._id]?.[type]?.target.has("dd") ? "active22" : ""}
                                                                    onClick={() => handleItemClick(match._id, type, "dd", hafu)}>平平<br /><span className="c_999">{hafu.dd}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("dl") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "dl", hafu)}>平负<br /><span className="c_999">{hafu.dl}</span></td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("lw") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "lw", hafu)}>负胜<br /><span className="c_999">{hafu.lw}</span></td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("ld") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "ld", hafu)}>负平<br /><span className="c_999">{hafu.ld}</span></td>
                                                                <td className={selectedItems[match._id]?.[type]?.target.has("ll") ? "active22" : ""} width="21%"
                                                                    onClick={() => handleItemClick(match._id, type, "ll", hafu)}>负负<br /><span className="c_999">{hafu.ll}</span></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    )
                                }
                            </Collapse.Panel>
                        )
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
            <Tabs.Tab title="全场比分" key="crs">
                <Collapse defaultActiveKey={['0']}>
                    {matchList && matchList.map((match, index) => {
                        const [date, _matchList] = match;
                        return (
                            <Collapse.Panel key={`${index}`} title={`${date} 有 ${_matchList.length} 场比赛`}>
                                {
                                    _matchList && _matchList.filter((match) => match.odds.crs.goalLine !== undefined ).map((match) => {
                                        const { odds: { crs } } = match;
                                        return (
                                            <div className="item_body">
                                                <div className="left">
                                                    <p>{match.matchNumStr}</p>
                                                    <p style={{ "color": "rgb(24, 181, 102)" }}>{match.leagueAbbName}</p>
                                                    <p style={{ "marginTop": "3px" }}>{getTimeInDay(match.closeTime - earlyCloseTime)}截止</p>
                                                    {/* <p className="txt-primary">分析</p> */}
                                                </div>
                                                <div className="jz_item_mark">单关</div>
                                                <div className="right">
                                                    <div className="team">
                                                        <span style={{ color: "black" }}>{match.homeTeamAbbName}</span>
                                                        <span style={{ "color": "rgb(153, 153, 153)" }}><span>VS</span></span>
                                                        <span style={{ color: "black" }}>{match.awayTeamAbbName}</span>
                                                    </div>
                                                    <div className="game_table">
                                                        <div className="game_btn">
                                                            {
                                                                selectedItems[match._id]?.["crs"]?.target.size ? (
                                                                    <div className="jz_select_bf" onClick={() => handleScorePopupClick(match)}>
                                                                        {
                                                                            [...selectedItems[match._id]?.["crs"]?.target].map((val) => (
                                                                                <span className="txt-primary" style={{"margin":"0px 6px"}}>
                                                                                    {cnNameMap[val]}
                                                                                </span>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div className="game_btn-view" onClick={() => handleScorePopupClick(match)}>点击选择比分投注</div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <Mask visible={scoreModalVisible}>
                                    <FootballScoreModal item={scoreModalItem} matchInfo={scoreModalMatch} onConfirm={handleScoreModalConfirm} onCancel={handleScoreModalCancel} />
                                </Mask>
                            </Collapse.Panel>
                        )
                    })}
                    {
                        matchList && matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无数据'
                            />
                        )
                    }
                </Collapse>
            </Tabs.Tab>
        </Tabs>
        <div>
            <div className="order-info-container">
                <Button fill='outline' color="primary" onClick={() => setSelectedItems({})}>清空</Button>
                <div style={{ textAlign: "center", width: "200px" }}>
                    <div>已选择{selectedMatchNum}场</div>
                    <div>至少选1场单关或两场比赛</div>
                </div>
                <div>
                    <Button color="primary" onClick={handleTakeOrderClick}>下单</Button>
                </div>
            </div>
        </div>
    </div>)
}