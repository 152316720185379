import React, { useEffect,useState } from "react";
import { NavBar, Tabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import "./ChaseNumList.css";
import { getChaseNumList } from "../../axios";
import { getTime } from "../../utils";
export default function ChaseNumList() {
    const navigate = useNavigate();
    const [chaseNumTaskList, setChaseNumTaskList] = useState([]);
    useEffect(() => {
      getChaseNumList({}).then(({data}) => {
        setChaseNumTaskList(data?.chaseNumTaskList || []);
      })
    }, [])
    return (
      <div>
        <NavBar onBack={() => navigate(-1)}>
          追号记录
        </NavBar>
        <Tabs>
          <Tabs.Tab title='全部' key='fruits'>
            <div className="listView">
              {
                chaseNumTaskList.map((task) => {
                  return (
                    <div className="listClass" onClick={() => navigate(`/person/chasenum/detail/${task._id}`)}>
                        <div className="listClass-flex">
                            <div className="listClass-flex-top">
                                <div style={{ fontWeight: 400 }}>排列三</div>
                                <div style={{ fontSize: "13px", marginRight: "13px", display: "inline-block" }}>混合投注
                                </div>
                            </div>
                            <div className="listClass-flex-main">
                                <div>{task?.amount}元</div>
                            </div>
                            <div className="listClass-flex-last">
                                <div style={{ color: "black", fontWeight: 900 }}>第{task.complete + 1}期/共{task.total}期</div>
                                <div style={{ fontSize: "13px", color: "rgb(153, 153, 153)", textAlign: "right", marginTop: "10px" }}>{getTime(task.createTime)}</div>
                            </div>
                        </div>
                    </div>
                  )
                })
              }
            </div>
          </Tabs.Tab>
          <Tabs.Tab title='进行中' key='vegetables'>
            
          </Tabs.Tab>
          <Tabs.Tab title='已结束/停止' key='animals'>
            
          </Tabs.Tab>
        </Tabs>
      </div>
    )
}