import React, { useEffect, useState } from "react"
import Footer from "../../components/footer.tsx";
import { getGroupBuyList } from "../../axios/index.js";
import { getTime } from "../../utils";
import { useNavigate } from "react-router-dom";
import defaultUserIcon from "../../assets/account.bdcd4100.png";
import { cnNameMap } from "../../constant/cnNameMap";
import "./GroupBuyHall.css";
import { NavBar, Toast } from "antd-mobile";


export default function GroupBuyHall() {
    const navigate = useNavigate();
    const [groupBuyList, setGroupBuyList] = useState<any[]>([]);
    useEffect(() => {
        getGroupBuyList({}).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setGroupBuyList(data.groupBuyTaskList);
            }
        })
    }, [])
    return (
        <div className="aui-flexView group-buy-hall">
            <div style={{ flex: "1 1", overflow: "auto" }}>
                <NavBar 
                    back={null}
                    right={
                        <div style={{ display: "flex",  justifyContent: "flex-end" }}>
                            <div className="my" onClick={() => navigate("/person/groupbuy/me") }>
                                    <div className="uni-image" style={{ width: "18px" }}>
                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFXRSTlMA/cwfCKidfiyTVT815968ihJpXkqqgHi/AAAA7klEQVQ4y5WSUY7DIAwFPcYkIQmBtL3/WVfaRdsako/OF4gRmKcnn9gzZtC6yDXrQSOeV+cz6COIbQmOMJ4/4NGWQTmGOwI838MoUy9MRK93jxgs3p+9sKNu/6J0XyR2Ix1fChs67B0nnD61JJ7ixrbM1gmb++eEyphU/jdqsx1WQGMpsc4KqwwskTfP4ficAFVoTGeXdIYaRGzZ9iChQt598pTggizw+syZKh31Y9Ad5ssGtlfsaPUZgjnsd5FQuxJMSa1du1yy//UuEeWGSHJlHFjAZEXlFmWV1H+xr436ZoxFzIR7IZAF7F4w+AE2SgXsSYtADQAAAABJRU5ErkJggg==" draggable="false" />
                                    </div>
                                </div>
                        </div>
                    }>
                    合买大厅
                </NavBar>
                {/* <div className="nav-list-normal">
                    <ul>
                        <li className="nav-this-normal">全部</li>
                        <li>双色球</li>
                        <li>福彩3D</li>
                        <li>七乐彩</li>
                        <li>快乐8</li>
                    </ul>
                </div>
                <div className="nav-list-radis">
                    <ul style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
                        <li className="nav-this-radis">进度</li>
                        <li>最新</li>
                        <li>7日命中</li>
                        <li>我的关注</li>
                    </ul>
                </div> */}
                <div className="aui-scrollView content" style={{ margin: 0, padding: 0, height: "auto" }}>
                    {
                        groupBuyList.map((groupBuyTask) => {
                            const { amount, totalCopies, totalPurchasedCopies, closeTime, amountPerCopy, creator, isFullGuarantee, guaranteedCopies, bet } = groupBuyTask;
                            return (
                                <div style={{ display: "flex", justifyContent: "center" }} onClick={() => { navigate(`/person/groupbuy/detail/${groupBuyTask._id}`) }}>
                                    <div className={ `${ isFullGuarantee ? "" : "mainview1" } main-view` }>
                                        <div className="main-view-top">
                                            <div className="main-view-top-left" style={{ lineHeight: "50px" }}>
                                                <div className="main-view-top-left-img">
                                                    <img src={creator.avatar || defaultUserIcon} alt="" />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", lineHeight: "12px", justifyContent: "center" }}>
                                                    <div className="u-m-l-20 u-m-b-10">{creator.username}</div>
                                                    <div className="u-m-l-20 main-view-top-left-text">
                                                        <div className="main-view-top-left-text-tx level3 d"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ position: "absolute", top: "-7px", right: "8px", color: "rgb(255, 255, 255)", fontSize: "16px" }}>
                                                {
                                                    isFullGuarantee ? (
                                                        <span>全额保底</span>
                                                    ) : (
                                                        <span style={{ color: "rgb(102, 102, 102)"}}>保底{guaranteedCopies / totalCopies * 100}%</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="main-view-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span className="main-view-text-one" style={{ fontSize: "18px", color: "rgb(85, 111, 138)", fontWeight: 600 }}>「{cnNameMap[bet.type]}」</span>
                                            <div style={{ color: "rgb(136, 136, 136)", fontSize: "17px" }}>截止:{getTime(closeTime)}</div>
                                        </div>
                                        <div className="main-view-nub" style={{ marginBottom: "1px" }}>
                                            <div className="main-view-nub-view">
                                                <div style={{ marginTop: "10px" }}>{amount}</div>
                                                <div style={{marginBottom: "11px"}}>方案金额</div>
                                            </div>
                                            <div className="main-view-nub-view">
                                                <div style={{ marginTop: "10px" }}>{ totalPurchasedCopies }/{ totalCopies }</div>
                                                <div style={{marginBottom: "11px"}}>进度</div>
                                            </div>
                                            <div className="main-view-nub-view">
                                                <div style={{ marginTop: "10px" }}>{ totalCopies - totalPurchasedCopies }</div>
                                                <div style={{marginBottom: "11px"}}>剩余份数</div>
                                            </div>
                                            <div className="main-view-nub-view">
                                                <div style={{ marginTop: "10px" }}>{ amountPerCopy }</div>
                                                <div style={{marginBottom: "11px"}}>单份金额</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div
                        style={{
                            textAlign: "center",
                            margin: "20px 0",
                            background: "url(images/doubleline.jpg) no-repeat center center",
                            backgroundSize: "180px"
                        }}>
                        <span style={{ color: "#7f7f7f" }}>没有更多了</span>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}