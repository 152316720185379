import React, { useState, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { cnNameMap }  from "../../constant/cnNameMap";
import QuePng from "../../assets/images/que.png";
import { NavBar, Toast } from "antd-mobile";
import { PlayType } from "../../constant/betPlayType";
import "./PublishFollowBuy.css";
export default function PublishFollowBuy() {
    const location = useLocation();
    const navigate = useNavigate();
    const [visibleRange, setVisibleRange] = useState(1);
    const [message, setMessage] = useState("快来跟我一起中大奖吧！");
    const maxSP = useMemo(() => {
        return location.state?.maxSP;
    }, [location.state?.maxSP]);
    const items = useMemo(() => {
        return location.state?.items;
    }, [location.state?.items]);
    const amount = useMemo(() => {
        return location.state?.amount || 0;
    }, [location.state?.amount]);
    const type = useMemo(() => {
        return location.state?.type
    }, [location.state?.type])
    const subType = useMemo(() => {
        return location.state?.subType
    }, [location.state?.subType]);
    const times = useMemo(() => {
        return location.state?.times
    }, [location.state?.times]);
    const selectedOptions = useMemo(() => {
        return location.state?.selectedOptions
    }, [location.state?.selectedOptions]);
    const closeTime = useMemo(() => {
        return location.state?.closeTime
    }, [location.state?.closeTime]);
    
    const toPayPage = async() => {
        if(type !== "pickThree" && visibleRange === 1 && maxSP < 1.6) {
            Toast.show("SP需要大于1.6");
            return;
        }
        let data = {
            type,
            subType,
            playType: PlayType.PublishFollowBuy,
            times,
            closeTime,
            options: selectedOptions.join("_"),
            items,
            followBuyInfo: {
                message,
                visibleRange,
            }
        };
        navigate("/person/pay", { state: { data, type, subType, amount, closeTime } });
    }
    return (
        <div className="aui-flexView">
            <NavBar
                onBack={() => navigate(-1)}>
                立即发单
            </NavBar>
            <div className="aui-scrollView content" style={{ padding: 0, marginTop: 0 }}>
                <div className="block-white">
                    <div className="grid-6-6">
                        <span className="label-2">投注彩种</span>
                        <div style={{ textAlign: "right" }}>
                            <span style={{ color: "#7f7f7f", fontSize: "15px" }}>{ `${cnNameMap[type]}${cnNameMap[subType]}` }</span>
                        </div>
                    </div>
                    <div className="grid-6-6">
                        <span className="label-2">订单金额</span>
                        <div style={{ textAlign: "right" }}>
                            <span style={{ color: "#7f7f7f", fontSize: "15px" }}><span className="font-money">{amount}</span>元</span>
                        </div>
                    </div>
                    <div className="grid-2-10">
                        <span className="label-2">保密设置</span>
                        <div style={{ display: "grid", gridTemplateRows: "repeat(2, 1fr)", rowGap: "12px" }}>
                            <div>
                                <div 
                                    className={`border-chose ${visibleRange === 1 ? "bordercl-orange" : ""}`}
                                    style={{marginRight: "10px"}}
                                    onClick={() => setVisibleRange(1)}>截止后公开</div>
                                <div 
                                    className={`border-chose ${visibleRange === 2 ? "bordercl-orange" : ""}`}
                                    onClick={() => setVisibleRange(2)}>公开</div>
                            </div>
                            {
                                visibleRange === 1 && (
                                    <div style={{ color: "#7f7f7f", fontSize: "14px" }}>
                                        <span>他人复制购彩，可获得中奖金额的 5% 作为佣金!</span>
                                        <img src={QuePng} style={{ width: "20px", filter: "brightness(0.5)", verticalAlign: "middle" }}/>
                                    </div>
                                )
                            }
                            {
                                visibleRange === 2 && (
                                    <div style={{ color: "#7f7f7f", fontSize: "14px" }}>
                                        <span>他人复制购彩，中奖后无佣金!</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="grid-2-10">
                        <span className="label-2">方案宣言</span>
                        <div>
                            <textarea
                                className="text-area"
                                style={{ background: "#ffffff", border: "1px solid #afafaf", borderRadius: "6px" }}
                                placeholder="快来跟我一起中大奖吧！"
                                defaultValue={message}
                                onChange={(e) => setMessage(e.target.value)}></textarea>
                        </div>
                    </div>
                    
                    <div style={{ margin: "9px 14px" }} onClick={toPayPage}><button className="btn-aw btn-orange">确定发单</button></div>
                </div>
            </div>
        </div>
    )
}