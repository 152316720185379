import React from "react";
import { useNavigate } from "react-router-dom";
import { Empty, NavBar } from "antd-mobile";

export default function MyMessage() {
    const navigate = useNavigate();
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                我的消息
            </NavBar>
            <Empty description='暂无数据' />
        </div>
    )
}