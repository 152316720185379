import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserAgentUsers } from "../../axios";
import { getOneDayStart, getOneDayEnd, getDay, getTime } from "../../utils";
import DatePicker from "../../components/DatePicker";
import "./AgentMyUsers.css";
import { Mask, NavBar } from "antd-mobile";
type resultItem = {
    _id: string,
    username: string,
    phone: string,
    balance: number,
    createTime: number,
    amount: number,
}


export default function AgentMyUsers() {
    const navigate = useNavigate();
    const now = useMemo(() => Date.now(), []);
    const [result, setResult] = useState<resultItem[]>([]);
    const [startTime, setStartTime] = useState(now - 24 * 60 * 60 * 1000);
    const [endTime, setEndTime] = useState(now);
    const [totalAmount, setTotalAmount] = useState(0);
    const [datePickerStartVisible, setDatePickerStartVisible] = useState(false);
    const [datePickerEndVisible, setDatePickerEndVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    useEffect(() => {
        getUserAgentUsers({ startTime, endTime }).then(({data}) => {
            const { result = [] } = data;
            setResult(result);
            let _totalAmount = 0;
            for(let item of result) {
                _totalAmount += item.amount;
            };
            setTotalAmount(_totalAmount);
        })
    }, [startTime, endTime])
    const back = () => {
        navigate(-1);
    }
    return (
        <div className="agent-my-users">
            <NavBar onBack={back}>
                用户统计
            </NavBar>
            <div className="topBox">
                <div className="top">
                    <div className="dataBox" onClick={() => setDatePickerStartVisible(true)}>
                        <div>
                            <div className="uni-input">{ getDay(startTime) }</div>
                        </div>
                    </div>
                    <span><span>至</span></span>
                    <div className="dataBox" onClick={() => setDatePickerEndVisible(true)}>
                        <div><div className="uni-input">{ getDay(endTime) }</div></div>
                    </div>
                </div>
                <Mask visible={datePickerStartVisible} onMaskClick={() => setDatePickerStartVisible(false)}>
                    <div>
                        <DatePicker defaultTime={startTime} onChange={(time, precision) => {
                            setDatePickerStartVisible(false);
                            if(precision === "day") {
                                setStartTime(getOneDayStart(time));
                            }
                        }} />
                    </div>
                </Mask>
                <Mask visible={datePickerEndVisible} onMaskClick={() => setDatePickerEndVisible(false)}>
                    <div>
                        <DatePicker defaultTime={startTime} onChange={(time, precision) => {
                            setDatePickerEndVisible(false);
                            if(precision === "day") {
                                setEndTime(getOneDayEnd(time));
                            }
                        }} />
                    </div>
                </Mask>
                {/* <div className="search">
                    <div className="u-search" style={{ "margin": "0px" }}>
                        <div className="u-content" style={{ "backgroundColor": "rgb(255, 255, 255)", "borderRadius": "5px", "border": "1px solid rgb(204, 204, 204)", "height": "36px" }}>
                            <div className="u-icon-wrap">
                                <div className="u-icon u-clear-icon u-icon--right">
                                    <span hover-className="" className="u-icon__icon uicon-search u-iconfont" style={{ "fontSize": "17px", "fontWeight": "normal", "top": "0px", "color": "rgb(96, 98, 102)" }}>
                                        <span></span>
                                    </span>
                                </div>
                            </div>
                            <div  className="uni-input u-input" style={{ "textAlign": "left", "color": "rgb(96, 98, 102)", "backgroundColor": "rgb(255, 255, 255)" }}>
                                <div className="uni-input-wrapper">
                                    <div className="uni-input-placeholder u-placeholder-class" style={{ "color": "rgb(144, 147, 153)" }}>搜索用户</div>
                                    <form action="" className="uni-input-form">
                                        <input maxLength={-1} step="" enterKeyHint="search" autoComplete="off" type="search" className="uni-input-input" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="u-action u-action-active" style={{ "color": "rgb(255, 255, 255)", "padding": "6px", "borderRadius": "4px" }}>搜索</div>
                    </div>
                </div> */}
                <div className="box">
                    <div className="left d">
                        <div className={`title ${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>注册数据</div>
                    </div>
                    <div className="right d">
                        <div className={`title ${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>投注数据</div>
                    </div>
                </div>
            </div>
            <div className="container">
                
                <div>
                    <div className="title">投注金额：{totalAmount}元</div>
                    <div className="cont">
                        <div className="t1">用户名</div>
                        <div className="t2">余额</div>
                        {
                            activeTab === 1 && (
                                <div className="t3">注册时间</div>
                            )
                        }
                        {
                            activeTab === 2 && (
                                <div className="t3">投注金额</div>
                            )
                        }
                        <div className="t4">投注详情</div>
                    </div>
                    <div>
                        {
                            result.map((item) => {
                                return (
                                    <div className="item">
                                        <div className="t1">
                                            <p>{item?.username}</p>
                                            <p style={{ "marginTop": "10px", "marginRight": "5px" }}>
                                                {item?.phone}
                                            </p>
                                        </div>
                                        <div className="t2">
                                            <p className="fee">{item?.balance?.toFixed(2)}元</p>
                                        </div>
                                        {
                                            activeTab === 1 && (
                                                <div className="t3">
                                                    <p>{ getTime(item?.createTime)}</p>
                                                </div>
                                            )
                                        }
                                        {
                                            activeTab === 2 && (
                                                <div className="t3 fee">
                                                    <p>{item?.amount.toFixed(2)}元</p>
                                                </div>
                                            )
                                        }
                                        <div className="t4">
                                            <span className="checkBtn" onClick={() => {navigate("/person/bet/list", { state: {id: item?._id } })}}>查看</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}