import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { cnNameMap } from "../../constant/cnNameMap";
import { getBetDetail } from "../../axios/index.js";
import { NavBar, Space, Toast, Image, ImageViewer } from "antd-mobile";
import JsFileDownloader from 'js-file-downloader';
import MatchBetDetail from "../../components/MatchBetDetail.tsx";
import PickThreeBetDetail from "../../components/PickThreeBetDetail.tsx";
import ChampionBetDetail from "../../components/ChampionBetDetail.tsx";
import "./BetDetail.css";
import WaitPNG from "../../assets/ddkj.5a301c70.png";
import LosePNG from "../../assets/zjzl.6f85eb0a.png";
import WinPNG from "../../assets/zjtb.564284aa.png";
import FootballPNG from "../../assets/407.png";
import BasketballPNG from "../../assets/406.png";
import BeijingPNG from "../../assets/405.png";
import PickThreePNG from "../../assets/202.png";
import ChampionPNG from "../../assets/champion.png";
import { PlayType } from "../../constant/betPlayType";
import { getSTSClient, refreshToken } from "../../sdk/sts.ts";

const status2CN = {
    "0": "未接单",
    "1": "未出票",
    "2": "已出票",
    "3": "未中奖",
    "4": "已中奖",
    "5": "已派奖",
    "6": "已撤单",
    "9": "保存",
}

export default function BetDetail() {
    const params = useParams();
    const location = useLocation();
    const betId = useMemo(() => (params.id), [params.id]);
    const from = useMemo(() => location?.state?.from, [location]);
    const navigate = useNavigate();
    const [betDetail, setBetDetail] = useState<any>({ items: [] });
    const [detailVisible, setDetailVisible] = useState(true);
    const [imgModalVisible, setImgModalVisible] = useState(false);
    const [imgModalSrc, SetImgModalSrc] = useState("");
    const [ossClient, setOssClient] = useState<any>();
    const [isCrossDay, setIsCrossDay] = useState<boolean>(false);
    const isIOS = useMemo(() => !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), [])
    useEffect(() => {
        getBetDetail({ betId })
            .then(({ data }) => {
                if(data.status === "error") {
                    Toast.show(data.errorMessage);
                } else {
                    const { bet, detailVisible } = data;
                    setDetailVisible(detailVisible);
                    setBetDetail(bet);
                    setIsCrossDay(new Date(bet.lastCloseTime).getDay() !== new Date(bet.userBuyTime).getDay());
                }
            })

        let _ossClient = getSTSClient();
        if(_ossClient) {
            setOssClient(_ossClient);
        } else {
            refreshToken().then((data) => {
                setOssClient(data);
            })
        }
    }, []);

    const renderFooter = (image: string) => {
        return (
          <div style={{
            padding: '16px',
            textAlign: 'center'
          }}>
            <div
                style={{
                    fontSize: '14px',
                    color: '#ffffff',
                    lineHeight: '1',
                    padding: '10px 16px',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    borderRadius: '100px',
                    display: 'inline-block',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    const [path, filename] = image.match(/\/tickets\/([a-z0-9-.]*)/) || [];
                    const response = {
                        "content-disposition": `attachment; filename=${encodeURIComponent(
                        filename
                        )}`,
                    };
                    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
                    const url = ossClient.signatureUrl(path, { response });
                    if(isIOS) {
                        SetImgModalSrc(url);
                    } else {
                        new JsFileDownloader({url})
                    }
                }}
            >
            { isIOS ? '加载' : '下载原图' }
            </div>
          </div>
        )
      }

    const back = () => {
        if(from === 'pay') {
            navigate('/');
            return;
        }
        if(betDetail.status === "9") {
            navigate("/person/bet/save")
        } else {
            navigate("/person/bet/list");
        }
    };
    return (
        <div className="bigView user-bet-detail">
            <NavBar onBack={back}>
                { betDetail.status === '9' ? "保存" : "自购" }
                方案详情
                { isCrossDay && "【隔天单】" }
            </NavBar>
            <div className="detail-top">
                {
                    (betDetail.playType === PlayType.PublishFollowBuy || betDetail.playType === PlayType.FollowBuy) && (
                        <div className="topTtps" onClick={() => navigate(`/person/followbuy/detail/${betDetail.followBuyTask}`)}>
                            <span>
                                点击查看此方案的跟单情况和佣金收入 &gt;&gt;&gt;
                            </span>
                        </div>
                    )
                }
                <div className="d1">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="uni-image icon">
                            {
                                (betDetail.type === 'football') && (<img src={FootballPNG} alt="" />)
                            }
                            {
                                (betDetail.type === "beijing") && (<img src={BeijingPNG} alt="" />)
                            }
                            
                            {
                                betDetail.type === "basketball" && (<img src={BasketballPNG} alt="" />)
                            }
                            {
                                betDetail.type === "pickThree" && (<img src={PickThreePNG} alt="" />)
                            }
                            {
                                betDetail.type === "champion" && (<img src={ChampionPNG} alt="" />)
                            }
                        </div>
                        <h3>{cnNameMap[betDetail.type]}</h3>
                    </div>
                    <div style={{ width: "98px", height: "25px", marginRight: "8px" }}>
                        <div className="uni-image" style={{ width: "98px", height: "32px" }}>
                            {
                                ['0', '1', '2'].includes(betDetail.status) && (<img src={WaitPNG} alt="" />)
                            }
                            {
                                ['3'].includes(betDetail.status) && (<img src={LosePNG} alt="" />)
                            }
                            {
                                ['4', '5'].includes(betDetail.status) && (<img src={WinPNG} alt="" />)
                            }
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="left" style={{ width: "30%", height: "54px", border: "1px solid rgb(212, 212, 212)", borderRadius: "8px" }}>
                        <div className="u-m-t-10" style={{ fontWeight: 600, color: "red" }}>
                            {betDetail.spend}
                            <span style={{ fontSize: "13px" }}>元</span>
                        </div>
                        <span style={{ fontSize: "13px", color: "rgb(102, 102, 102)" }}>方案金额</span>
                    </div>
                    <div className="left"
                        style={{ width: "30%", height: "54px", border: "1px solid rgb(212, 212, 212)", borderRadius: "8px" }}>
                        <div className="r1">
                            <div className="u-m-t-10">{status2CN[betDetail.status]}</div>
                            <div style={{ fontSize: "13px", color: "rgb(102, 102, 102)", fontWeight: 400 }}>
                                方案状态
                            </div>
                        </div>
                    </div>
                    <div className="left"
                        style={{ width: "30%", height: "54px", border: "1px solid rgb(212, 212, 212)", borderRadius: "8px" }}>
                        <div className="u-m-t-10" style={{ fontWeight: 900 }}>
                            <span style={{ color: "red" }}>{betDetail.bonus > 0 ? `${betDetail.bonus?.toFixed(2)}元` : "-"}</span>
                        </div>
                        <div style={{ fontSize: "13px", color: "rgb(102, 102, 102)" }}>税后奖金</div>
                    </div>
                </div>
            </div>
            <div>
                {
                    ["basketball", 'football', 'beijing'].includes(betDetail.type) && (
                        <MatchBetDetail bet={betDetail} detailVisible={detailVisible}/>
                    )
                }
                {
                    betDetail.type === "pickThree" && (
                        <PickThreeBetDetail pickthreeInfo={betDetail.pickthreeInfo} items={betDetail.items} detailVisible={detailVisible}/>
                    )
                }
                {
                    betDetail.type === 'champion' && (
                        <ChampionBetDetail bet={betDetail}/>
                    )
                }
            </div>
            <div className="auto-fill"></div>
            {
                ((betDetail.playType === PlayType.BonusOptimization || betDetail.isBonusOptimization) && betDetail.ticketPath?.length > 0) && (
                    <div className="pj"><div style={{ textAlign: "center" }}><span onClick={() => navigate(`/person/bet/tickets`, { state: { bet: betDetail } })}>查看彩票明细</span></div></div>
                )
            }
            {
                (["2", "3", "4", "5"].includes(betDetail.status)) && (
                    <div className="pj">
                        <div className="title"><span>实体票样</span></div>
                        <div className="box">
                                    <Space wrap>
                                        {
                                            betDetail.ticketPath?.map((path: string) => (
                                                <Image src={`${path}?x-oss-process=image/format,heic`} width={100} height={100} fit='fill'
                                                    fallback={
                                                        <Image src={path} width={100} height={100} fit='fill'  onClick={() => {
                                                                setImgModalVisible(true);
                                                                SetImgModalSrc(path)
                                                            }}
                                                        />
                                                    }
                                                    onClick={() => {
                                                    setImgModalVisible(true);
                                                    SetImgModalSrc(path)
                                                }}/>
                                            ))
                                        }
                                    </Space>
                        </div>
                    </div>
                )
            }
            <div className="noticeDetail">
                <div style={{ fontWeight: 900 }}>
                    <span>温馨提示</span>
                </div>
                <div style={{ fontSize: "10px", color: "rgb(153, 153, 153)", lineHeight: "19px" }}>
                    <p>1.此页面只展示订单状态，一切均为实体票为准，本平台不对此订单状态做任何担保；</p>
                    <p>2. 如发现实体票照片与投注内容不符，请及时联系店主；</p>
                    <p>3. 若订单中奖，请您及时核对实体票样的奖金。</p>
                </div>
            </div>
            <div className="btnbox2" style={{ display: "flex", justifyContent: "space-around" }}>
                <div className="bg-primary"
                    style={{ width: "94%", height: "39px", borderRadius: "6px", textAlign: "center", lineHeight: "39px", color: "rgb(255, 255, 255)" }}
                    onClick={() => {
                        if(betDetail.status !== "9") {
                            if(betDetail.type === "basketball") {
                                navigate("/person/basketball")
                            }
                            if(betDetail.type === "football") {
                                navigate("/person/football")
                            }
                            if(betDetail.type === "beijing") {
                                navigate("/person/beijing")
                            }
                            if(betDetail.type === "pickThree") {
                                navigate("/person/three")
                            }
                            if(betDetail.type === 'champion') {
                                navigate("/person/champion")
                            }
                        } else {
                            if ( betDetail.type === "pickThree" ) {
                                navigate("/person/betsummarizeN", { state: { items: betDetail.items, type: betDetail.type, subType: betDetail.subType, closeTime: betDetail.closeTime } });
                            } else {
                                navigate("/person/betsummarize", { state: { items: betDetail.items, type: betDetail.type, subType: betDetail.subType, closeTime: betDetail.closeTime, single: false } });
                            }
                        }
                    }}>
                    继续投注{cnNameMap[betDetail.type]}</div>
            </div>
            <ImageViewer
                image={imgModalSrc}
                visible={imgModalVisible}
                onClose={() => {
                    setImgModalVisible(false)
                }}
                renderFooter={renderFooter}
            />
        </div>
    )
}