import React, { useState, useEffect } from "react";
import deletePNG from "../assets/images/del.png";
import { Toast } from "antd-mobile";

export default function ChaseModal(props: {
    baseChaseNum: number,
    height: number,
    index?: number,
    onConfirmClick: (num: number) => void;
    onClose:() => void;
}) {
    const { baseChaseNum, height, index = 1, onConfirmClick, onClose } = props;
    const [chaseNum, setChaseNum] = useState<number>(baseChaseNum);
    const [flag, setFlag] = useState(true);
    function handleNumClick(num: number) {
        let _chaseNum;
        if(num === 0) {
            _chaseNum = chaseNum * 10;
        } else {
            if(chaseNum === 1 && flag) {
                _chaseNum = num;
                setFlag(false);
            } else {
                _chaseNum = chaseNum * 10 + num;
            }
        }
        if(_chaseNum > 20) {
            _chaseNum = 20;
            Toast.show("最高追号20期");
        }
        setChaseNum(_chaseNum);
    };
    function handleDelete() {
        let _chaseNum = Math.floor(chaseNum / 10);
        if(_chaseNum === 0) {
            setFlag(true);
            setChaseNum(1);
        } else {
            setChaseNum(_chaseNum);
        }
    }

    const handleConfirmClick = () => {
        onConfirmClick(chaseNum);
    }

    useEffect(() => {
        setChaseNum(baseChaseNum);
    }, [baseChaseNum])
    return (
        <div className="layer-shade" style={{ height: `calc(100% - ${height}px)` }}>
            <div style={{ height: "100%" }} onClick={onClose}></div>
            <div className="layer-block">
                <div style={{ fontSize: "14px" }}>
                    快乐购彩 理性投注
                </div>
                <div style={{ textAlign: "center", padding: "9px 0" }}>追号<span className="font-money">{chaseNum}</span>期
                    <div className="icon-u" style={{ float: "right" }}></div>
                </div>
                <div className="grid-3-3-3-3 choose-span-1" style={{ columnGap: "12px" }}>
                    <span onClick={() => setChaseNum(5)}>5期</span>
                    <span onClick={() => setChaseNum(10)}>10期</span>
                    <span onClick={() => setChaseNum(15)}>15期</span>
                    <span onClick={() => setChaseNum(20)}>20期</span>
                </div>
                <div className="keyboard">
                    <span onClick={() => { handleNumClick(1) }}>1</span>
                    <span onClick={() => { handleNumClick(2) }}>2</span>
                    <span onClick={() => { handleNumClick(3) }}>3</span>
                    <span onClick={() => { handleNumClick(4) }}>4</span>
                    <span onClick={() => { handleNumClick(5) }}>5</span>
                    <span onClick={() => { handleNumClick(6) }}>6</span>
                    <span onClick={() => { handleNumClick(7) }}>7</span>
                    <span onClick={() => { handleNumClick(8) }}>8</span>
                    <span onClick={() => { handleNumClick(9) }}>9</span>
                    <span className="key-del" onClick={handleDelete}> <img src={deletePNG}/></span>
                    <span onClick={() => { handleNumClick(0) }}>0</span>
                    <span className="key-cur" onClick={handleConfirmClick}>确定</span>
                </div>
            </div>
        </div>
    )
}