import { Button, Form, Input, Mask, NavBar, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { postUserTurnOut } from "../../axios";
import { useSelector } from "react-redux";

export default function TurnOut() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const balance = useMemo(() => `${Math.floor((user?.balance || 0) * 100) / 100}`, [user]);
    const [phone, setPhone] = useState<string>();
    const [amount, setAmount] = useState<string>();
    const [payPassword, setPayPassword] = useState<string>();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const handleSubmit = () => {
        if(!phone) {
            Toast.show('请填写手机号');
        }
        const _amount = Number(amount);
        if(_amount <= 0) {
            Toast.show('转款金额不能小于或等于0');
        }
        setConfirmModalVisible(true);
    }

    const handleConfirm = () => {
        setConfirmModalVisible(false);
        Toast.show('转账中');
        postUserTurnOut({ phone, amount: Number(amount), payPassword }).then(({ data }) => {
            setAmount('');
            setPayPassword('');
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                Toast.show('转款成功');
                navigate('/person/action/list', { state: { tab: 'turnin,turnout' } })
            }
        })
    }
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                代理转账
            </NavBar>
            <Form layout='horizontal'>
                <Form.Item
                    label='手机号'
                    name='phone'>
                    <Input
                        placeholder='请输入手机号'
                        clearable
                        value={phone}
                        onChange={(val) => setPhone(val)}/>
                </Form.Item>
                <Form.Item label='转账金额' >
                    <Input type="number" value={amount} placeholder={`最多可划转${balance}元`} onChange={setAmount}/>
                </Form.Item>
            </Form>
            <Button block color='primary' size='large' onClick={handleSubmit}>
                提交
            </Button>
            <div className="message" style={{
                padding: "16px"
            }}>
                <div className="title">您可以在用户中心》交易明细》转入/转出查看</div>
            </div>
            <Mask visible={confirmModalVisible}>
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "60vh",
                        height: "auto",
                        backgroundColor: "white",
                        padding: "30px",
                        borderRadius: "10px",
                    }}>
                        <div style={{ textAlign: "center", fontSize: "18px" }}>您将向手机号:{phone}转入:{amount}元，如核验无误请输入支付密码</div>
                        <div style={{
                            margin: "10px 0"
                        }}>
                            <div style={{ display: "flex", margin: "10px" }}>
                                <div style={{ width: "100px" }}>支付密码</div>
                                <Input placeholder='请输入密码' value={payPassword} clearable type='password' onChange={setPayPassword} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-around"
                        }}>
                            <div
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={() => {
                                    setConfirmModalVisible(false);
                                }}>取消</div>
                            <div 
                                style={{ flex: "1 1", textAlign: "center", height: "40px", lineHeight: '40px', border: "solid 1px black" }}
                                onClick={handleConfirm}>确认</div>
                        </div>
                    </div>
                </div>
            </Mask>
        </div>
    )
}