import { get, post, postForm } from "./base.js";

/**
 * @param {any} params
 */
export function getGroupBuyList(params) {
    return get("/groupbuy/list", params)
}

/**
 * @param {any} params
 */
export function getGroupBuyDetail(params) {
    return get("/groupbuy/detail", params)
}

/**
 * @param {any} params
 */
export function getGroupBuyMeInfo(params) {
    return get("/groupbuy/me/detail", params)
}

/**
 * @param {any} params
 */
export function getGroupBuyMeList(params) {
    return get("/groupbuy/me/list", params)
}

/**
 * @param {any} data
 */
export function postParticipateGroupBuy(data) {
    return post("/groupbuy/buy", data)
}