import React, { useEffect, useState } from "react";

function deepCopy<T>(obj: T): T {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    if (obj instanceof Set) {
      const copySet = new Set();
      obj.forEach((item) => {
        copySet.add(deepCopy(item));
      });
      return copySet as unknown as T;
    }
  
    if (Array.isArray(obj)) {
      const copyArr: any[] = [];
      obj.forEach((item) => {
        copyArr.push(deepCopy(item));
      });
      return copyArr as unknown as T;
    }
  
    const copyObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
      copyObj[key] = deepCopy(obj[key]);
    });
  
    return copyObj as T;
}
export default function BasketballScoredModal(props: any) {
    const { item, matchInfo: {awayTeamAbbName, homeTeamAbbName, _id, odds: { mnl, hdc, hilo, wnm }}, onConfirm, onCancel } = props;
    const [selfItem, setSelfItem] = useState<any>({});
    useEffect(() => {
        setSelfItem(deepCopy(item));
    }, [item])
    const handleItemClick = (type: string, target: string, odd: any) => {
        if(selfItem[type]) {
            selfItem[type].target.has(target) ? selfItem[type].target.delete(target) : selfItem[type].target.add(target)
        } else {
            selfItem[type] = {
                type,
                goalLine: odd.goalLine,
                single: odd.single,
                target: new Set([target]),
                match: _id,
            }
        }
        setSelfItem({...selfItem});
    }
    const handleConfirmClick = () => {
        onConfirm(_id, selfItem);
    }
    const handleCancelClick = () => {
        onCancel();
    }
    return (
        <div className="popContent" style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <div className="popCenter" style={{ width: "90%" }}>
                <div className="popView">
                    <div className="jz_game_team">
                        <div className="jz_center_team">[客]{awayTeamAbbName}</div>
                        <div className="jz_vs c_999">VS</div>
                        <div className="jz_center_team">{homeTeamAbbName}[主]</div>
                    </div>
                    <div className="bg_fff min-view">
                        <table className="jz_spf_table">
                            <tr>
                                <td width="8%" rowSpan={3} className="bg_e190cd c_fff">胜<br />分<br />差</td>
                                <td colSpan={3} className="h23 tdbg"><span className="fs12 c_999">{awayTeamAbbName}（客）胜</span></td>
                            </tr>
                            <tr>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("l1") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "l1", wnm)}>1-5<div className="c_999">{wnm.l1}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("l2") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "l2", wnm)}>6-10<div className="c_999">{wnm.l2}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("l3") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "l3", wnm)}>11-15<div className="c_999">{wnm.l3}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("l4") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "l4", wnm)}>16-20<div className="c_999">{wnm.l4}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("l5") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "l5", wnm)}>21-25<div className="c_999">{wnm.l5}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("l6") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "l6", wnm)}>26+<div className="c_999">{wnm.l6}</div>
                                </td>
                            </tr>
                            <tr>
                                <td width="8%" rowSpan={3} className="bg_90ddcf c_fff">胜<br />分<br />差</td>
                                <td colSpan={3} className="h23 tdbg">
                                    <span className="fs12 c_999">{homeTeamAbbName}（主）胜</span>
                                </td>
                            </tr>
                            <tr>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("w1") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "w1", wnm)}>1-5<div className="c_999">{wnm.w1}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("w2") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "w2", wnm)}>6-10<div className="c_999">{wnm.w2}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("w3") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "w3", wnm)}>11-15<div className="c_999">{wnm.w3}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("w4") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "w4", wnm)}>16-20<div className="c_999">{wnm.w4}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("w5") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "w5", wnm)}>21-25<div className="c_999">{wnm.w5}</div>
                                </td>
                                <td className={`tdbg ${selfItem['wnm']?.target.has("w6") ? "active22" : ""}`} onClick={() => handleItemClick("wnm", "w6", wnm)}>26+<div className="c_999">{wnm.w6}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="btn">
                        <div className="qx" onClick={handleCancelClick}>取 消</div>
                        <div className="done" onClick={handleConfirmClick}>确 定</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
