import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Image, ImageUploadItem, ImageUploader, List, NavBar, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { postUserUpdateAvatar } from "../../axios";
import DefaultUserPNG from "../../assets/store-info.png"

export default function MyInfo() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user?.user || {});
    const [fileList, setFileList] = useState<ImageUploadItem[]>([]);
    const [defaultAvatarVisible, setDefaultAvatarVisible] = useState(true);
    const [avatar, setAvatar] = useState(user.avatar);
    const handleFileUpload = async (file: File) => {
        try {
            const { data } = await postUserUpdateAvatar({ image: file });
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                setAvatar(data.result.url);
                setDefaultAvatarVisible(false);
                return { url: data.result.url }
            }
        } catch(e) {
            Toast.show("票据上传失败，请稍后重试");
        }
    }

    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                我的信息
            </NavBar>
            <List>
                <List.Item extra={
                    <div style={{ display: "flex" }}>
                        {
                            defaultAvatarVisible && <Image src={avatar || DefaultUserPNG} width={50} height={50}/>
                        }
                        <ImageUploader
                            style={{ '--cell-size': '50px' }}
                            value={fileList}
                            onChange={setFileList}
                            preview={false}
                            deleteIcon={false}
                            maxCount={1}
                            upload={handleFileUpload as any}
                        />
                    </div>
                }>
                    头像
                </List.Item>
                <List.Item extra={user.username}>
                昵称
                </List.Item>
                <List.Item extra={user.phone}>
                手机号码
                </List.Item>
                <List.Item clickable onClick={() => navigate("/person/password")} extra={'重置密码'}>
                登录密码
                </List.Item>
                <List.Item clickable onClick={() => navigate("/person/paypassword")} extra={'重置支付密码'}>
                支付密码
                </List.Item>
            </List>
            {/* <List>
                <List.Item clickable>
                真实姓名
                </List.Item>
                <List.Item clickable>
                身份证号码
                </List.Item>
                
            </List> */}
        </div>
    )
}