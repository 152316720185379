import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button, Dropdown, DropdownRef, NavBar, Toast } from "antd-mobile";
import './index.css'
import { useNavigate, useLocation } from "react-router-dom";
import { getPickThreeTerm } from "../../axios/index.js";
import DirectComponent from "./Direct.tsx";
import Group3Component from "./Group3.tsx";
import Group6Component from "./Group6.tsx";
import notesMap from "./notes.ts";
import { splitArrayByN, getRandom } from "../../utils";
import { cnNameMap } from "../../constant/cnNameMap";
import { useSelector } from "react-redux";

export default function Three() {
    const navigate = useNavigate();
    const location = useLocation();
    const shop = useSelector((state) => state.shop.shop);
    const earlyCloseTime = useMemo(() => (shop.config?.["pickThree"]?.["earlyCloseTime"] || 5 * 60 * 1000), [shop])
    const dropDownRef = useRef<DropdownRef>(null)
    const back = () => {
        navigate("/");
    };
    const preItems = useMemo(() => {
        return location.state?.items || [];
    }, [location.state?.items]);
    const [closeTime, setCloseTime] = useState(0);
    const [preTerm, setPreTerm] = useState<any>({});
    const [currentTerm, setCurrentTerm] = useState("");
    const [type, setType] = useState("direct");
    const [subType, setSubType] = useState("101");
    const [targetArrHundred, setTargetArrHundred] = useState<Set<number>>(new Set());
    const [targetArrTen, setTargetArrTen] = useState<Set<number>>(new Set());
    const [targetArrBit, setTargetArrBit] = useState<Set<number>>(new Set());
    const [notesNum, setNotesNum] = useState(0);

    const handleConfirmClick = async () => {
        let items;
        const now = Date.now();
        if (now > closeTime) {
            Toast.show("投注已截止");
            return;
        }
        items = [{
            type,
            subType,
            times: 1,
            notes: notesNum,
            target: `${[...targetArrHundred].sort().join(",")}|${[...targetArrTen].sort().join(",")}|${[...targetArrBit].sort().join(",")}`
        }];
        if (notesNum === 0) {
            Toast.show("请选择投注");
            return;
        }
        navigate("/person/betsummarizen", { state: { items: [...preItems, ...items], closeTime, type: "pickThree", subType: type } });
    };

    useEffect(() => {
        getPickThreeTerm({}).then(({ data }) => {
            let { latestTerm = [] } = data;
            setCurrentTerm(latestTerm[0].term);
            setCloseTime(latestTerm[0].closeTime - earlyCloseTime);
        })
    }, [earlyCloseTime]);

    const handleSubTypeClick = (subType: string) => {
        setSubType(subType);
        setTargetArrBit(new Set());
        setTargetArrTen(new Set());
        setTargetArrHundred(new Set());
    };

    const handleTypeClick = (type: string) => {
        setType(type);
        switch (type) {
            case "direct": setSubType("101"); break;
            case "group3": setSubType("301"); break;
            case "group6": setSubType("601"); break;
        }
        setTargetArrBit(new Set());
        setTargetArrTen(new Set());
        setTargetArrHundred(new Set());
        dropDownRef.current?.close();
    }

    const handleTargetClick = (targetType: string, target: number) => {
        if (type === 'group3' && subType === "301") {
            if (targetType === "ten") {
                if (targetArrBit.has(target)) {
                    setTargetArrBit(new Set());
                }
                setTargetArrTen(new Set([target]))
            }
            if (targetType === "bit") {
                if (targetArrTen.has(target)) {
                    setTargetArrTen(new Set());
                }
                setTargetArrBit(new Set([target]))
            }
            return;
        }
        if (type === "direct" && subType === "104") {
            if (targetType === 'ten') {
                if (targetArrBit.has(target)) {
                    Toast.show("已选择此球为蓝码");
                    return;
                }
                if (targetArrTen.has(target)) {
                    targetArrTen.delete(target);
                } else {
                    if (targetArrTen.size >= 2) {
                        Toast.show("最多只可选择两个胆码");
                        return;
                    }
                    targetArrTen.add(target);
                }
                setTargetArrTen(new Set(targetArrTen));
            }
            if (targetType === "bit") {
                if (targetArrTen.has(target)) {
                    Toast.show("已选择此球为红码");
                    return;
                }
                if (targetArrBit.has(target)) {
                    targetArrBit.delete(target);
                } else {
                    targetArrBit.add(target);
                };
                setTargetArrBit(new Set(targetArrBit));
            }
            return;
        }
        if (type === "group3" && subType === "303") {
            if (targetType === 'ten') {
                if (targetArrBit.has(target)) {
                    Toast.show("已选择此球为蓝码");
                    return;
                }
                if (targetArrTen.has(target)) {
                    targetArrTen.delete(target);
                } else {
                    if (targetArrTen.size >= 1) {
                        Toast.show("最多只可选择两个胆码");
                        return;
                    }
                    targetArrTen.add(target);
                }
                setTargetArrTen(new Set(targetArrTen));
            }
            if (targetType === "bit") {
                if (targetArrTen.has(target)) {
                    Toast.show("已选择此球为红码");
                    return;
                }
                if (targetArrBit.has(target)) {
                    targetArrBit.delete(target);
                } else {
                    targetArrBit.add(target);
                };
                setTargetArrBit(new Set(targetArrBit));
            }
            return;
        }
        if (type === "group6" && subType === "602") {
            if (targetType === 'ten') {
                if (targetArrBit.has(target)) {
                    Toast.show("已选择此球为蓝码");
                    return;
                }
                if (targetArrTen.has(target)) {
                    targetArrTen.delete(target);
                } else {
                    if (targetArrTen.size >= 2) {
                        Toast.show("最多只可选择两个胆码");
                        return;
                    }
                    targetArrTen.add(target);
                }
                setTargetArrTen(new Set(targetArrTen));
            }
            if (targetType === "bit") {
                if (targetArrTen.has(target)) {
                    Toast.show("已选择此球为红码");
                    return;
                }
                if (targetArrBit.has(target)) {
                    targetArrBit.delete(target);
                } else {
                    targetArrBit.add(target);
                };
                setTargetArrBit(new Set(targetArrBit));
            }
            return;
        }
        if (targetType === "hundred") {
            if (targetArrHundred.has(target)) {
                targetArrHundred.delete(target);
            } else {
                targetArrHundred.add(target);
            }
            setTargetArrHundred(new Set(targetArrHundred));
        } else if (targetType === 'ten') {
            if (targetArrTen.has(target)) {
                targetArrTen.delete(target);
            } else {
                targetArrTen.add(target);
            }
            setTargetArrTen(new Set(targetArrTen));
        } else {
            if (targetArrBit.has(target)) {
                targetArrBit.delete(target);
            } else {
                targetArrBit.add(target);
            }
            setTargetArrBit(new Set(targetArrBit));
        }
    }

    useEffect(() => {
        if (subType === "101") {
            setNotesNum(targetArrHundred.size * targetArrTen.size * targetArrBit.size);
        }
        if (subType === "103" || subType === '105' || subType === '304' || subType === '603') {
            let _notes = 0;
            for (let target of targetArrBit) {
                _notes += notesMap[type][subType][target];
            };
            setNotesNum(_notes);
        }
        if (subType === "104") {
            if (targetArrTen.size === 0 || targetArrTen.size + targetArrBit.size < 4) {
                setNotesNum(0);
            } else {
                let n = 3 - targetArrTen.size;
                let com = splitArrayByN([...targetArrBit], n);
                setNotesNum(com.length * 6);
            }
        }
        if (subType === "301") {
            setNotesNum(targetArrTen.size * targetArrBit.size);
        }
        if (subType === "303") {
            if (targetArrTen.size === 0 || targetArrTen.size + targetArrBit.size < 3) {
                setNotesNum(0);
            } else {
                setNotesNum(targetArrBit.size * 2);
            }
        }
        if (subType === "602") {
            if (targetArrTen.size === 0 || targetArrTen.size + targetArrBit.size < 4) {
                setNotesNum(0);
            } else {
                let n = 3 - targetArrTen.size;
                let com = splitArrayByN([...targetArrBit], n);
                setNotesNum(com.length);
            }
        }
        if (["102", "302", "601"].includes(subType)) {
            setNotesNum(notesMap[type][subType][targetArrBit.size])
        }
    }, [targetArrHundred, targetArrTen, targetArrBit, subType, type]);

    const handleRandom = () => {
        if (subType === '101') {
            const hundred = getRandom(0, 9, 1);
            const ten = getRandom(0, 9, 1);
            const bit = getRandom(0, 9, 1);
            setTargetArrHundred(hundred);
            setTargetArrTen(ten);
            setTargetArrBit(bit);
        }
        if (subType === "102") {
            setTargetArrBit(getRandom(0, 9, 3));
        }
        if (subType === "103") {
            setTargetArrBit(getRandom(0, 27, 1));
        }
        if (subType === "104") {
            const ten = getRandom(0, 9, 2);
            const bit = getRandom(0, 9, 2, [...ten]);
            setTargetArrTen(ten);
            setTargetArrBit(bit);
        }
        if (subType === "105") {
            setTargetArrBit(getRandom(0, 9, 1));
        }
        if (subType === "301") {
            const ten = getRandom(0, 9, 1);
            const bit = getRandom(0, 9, 1, [...ten]);
            setTargetArrTen(ten);
            setTargetArrBit(bit);
        }
        if (subType === "302") {
            setTargetArrBit(getRandom(0, 9, 2));
        }
        if (subType === "303") {
            const ten = getRandom(0, 9, 1);
            const bit = getRandom(0, 9, 2, [...ten]);
            setTargetArrTen(ten);
            setTargetArrBit(bit);
        }
        if (subType === "304") {
            const bit = getRandom(1, 9, 1);
            setTargetArrBit(bit);
        }
        if (subType === "601") {
            const bit = getRandom(0, 9, 3);
            setTargetArrBit(bit);
        }
        if (subType === "602") {
            const ten = getRandom(0, 9, 2);
            const bit = getRandom(0, 9, 2, [...ten]);
            setTargetArrTen(ten);
            setTargetArrBit(bit);
        }
        if (subType === "603") {
            const bit = getRandom(2, 9, 1);
            setTargetArrBit(bit);
        }
    }

    return (
        <div className="three-background">
            <NavBar onBack={back}>
                <Dropdown ref={dropDownRef} style={{ backgroundColor: "transparent" }}>
                    <Dropdown.Item key='sorter' title={cnNameMap[type]} style={{ "--adm-font-size-main": "18px" }}>
                        <div className="changeTis">
                            <div className={`changeTis-view ${type === "direct" ? "active" : ""}`} onClick={() => handleTypeClick("direct")}>直选</div>
                            <div className={`changeTis-view ${type === "group3" ? "active" : ""}`} onClick={() => handleTypeClick("group3")}>组三</div>
                            <div className={`changeTis-view ${type === "group6" ? "active" : ""}`} onClick={() => handleTypeClick("group6")}>组六</div>
                            {/* <div className="changeTis-view">组选</div> */}
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            </NavBar>
            <div style={{
                flex: "1 1",
                paddingBottom: "60px",
                overflow: "auto",
            }}>
                {
                    type === "direct" && <DirectComponent
                        targetArrHundred={targetArrHundred}
                        targetArrTen={targetArrTen}
                        targetArrBit={targetArrBit}
                        onSubTypeChange={handleSubTypeClick}
                        onTargetClick={handleTargetClick}
                        doRandom={handleRandom}
                        currentTerm={currentTerm}
                        closeTime={closeTime}
                    />
                }
                {
                    type === "group3" && <Group3Component
                        targetArrHundred={targetArrHundred}
                        targetArrTen={targetArrTen}
                        targetArrBit={targetArrBit}
                        onSubTypeChange={handleSubTypeClick}
                        onTargetClick={handleTargetClick}
                        doRandom={handleRandom}
                        currentTerm={currentTerm}
                        closeTime={closeTime}
                    />
                }
                {
                    type === "group6" && <Group6Component
                        targetArrHundred={targetArrHundred}
                        targetArrTen={targetArrTen}
                        targetArrBit={targetArrBit}
                        onSubTypeChange={handleSubTypeClick}
                        onTargetClick={handleTargetClick}
                        doRandom={handleRandom}
                        currentTerm={currentTerm}
                        closeTime={closeTime}
                    />
                }
            </div>
            <div>
                <div className="order-info-container">
                    <Button fill='outline' color="primary" onClick={() => {
                        setTargetArrBit(new Set());
                        setTargetArrTen(new Set());
                        setTargetArrHundred(new Set());
                    }}>清空</Button>
                    <div style={{ textAlign: "center", width: "200px" }}>
                        已选
                        <span className="txt-primary" style={{ margin: "0px 3px" }}>{notesNum}</span>注，
                        <span className="txt-primary" style={{ margin: "0px 3px" }}>{notesNum * 2}</span>元
                    </div>
                    <div>
                        <Button color="primary" onClick={handleConfirmClick}>下单</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}