import React, { useEffect, useMemo, useState } from "react";
import "./index.css";
import { NavBar, Tabs, Toast, Button } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { getMonthDate } from "../../utils";
import { Team } from "../../interface/index.js";
import { getChampionTeams } from "../../axios/champion.js";

export default function Champion() {
    const navigate = useNavigate();
    const location = useLocation();
    const _subType = useMemo(() => location.state?.subType || 'chp', []);
    const _items = useMemo(() => location.state?.items || [], []);
    const _itemsId = useMemo(() => location.state?.items.map((val) =>val._id) || [], []);
    const now = useMemo(() => Date.now(), []);
    console.log(_subType);
    const [currentSubType, setCurrentSubType] = useState<string>(_subType);
    const [chpTeamsList, setChpTeamsList] = useState<Team[]>();
    const [fnlTeamsList, setFnlTeamsList] = useState<Team[]>();
    const [selectedTeamId, setSelectedTeamId] = useState<string[]>(_itemsId);
    const [selectedTeam, setSelectedTeam] = useState<Team[]>(_items);
    const back = () => {
        navigate("/");;
    };

    useEffect(() => {
        getChampionTeams({}).then(({ data }: { data: {status: string, errorMessage: string , teams: Team[]} }) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setChpTeamsList(data.teams.filter((team) => team.type === 'chp'));
                setFnlTeamsList(data.teams.filter((team) => team.type === 'fnl'));
            }
        });
    }, []);

    const handleTabChange = async (data: string) => {
        setSelectedTeam([]);
        setSelectedTeamId([]);
        setCurrentSubType(data);
    }

    const handleItemClick = (team: Team) => {
        if(selectedTeamId.includes(team._id)) {
            const _selectedTeamId = selectedTeamId.filter((id) => id !== team._id);
            const _selectedTeam = selectedTeam.filter((_team) => _team._id !== team._id);
            setSelectedTeam(_selectedTeam);
            setSelectedTeamId(_selectedTeamId);
        } else {
            const _selectedTeamId = [...selectedTeamId, team._id];
            const _selectedTeam = [...selectedTeam, team];
            setSelectedTeam(_selectedTeam);
            setSelectedTeamId(_selectedTeamId);
        }
    }

    const handleClearSelected = () => {
        setSelectedTeam([]);
        setSelectedTeamId([]);
    }

    const handleTakeOrderClick = async () => {
        if(selectedTeam.length === 0) {
            Toast.show('请至少选择一个选项');
            return;
        }
        const closeTime = selectedTeam[0].closeTime;

        navigate("/person/betsummarizec", { state: { items: selectedTeam.sort((a, b) => a.selectionNum - b.selectionNum), type: 'champion', subType: currentSubType, closeTime } })
    }
    return (<div className="champion-container">
        <NavBar onBack={back}>
            冠亚军
        </NavBar>
        <Tabs defaultActiveKey={_subType} onChange={handleTabChange}>
            <Tabs.Tab title="冠军" key="chp">
                {chpTeamsList && chpTeamsList.map((team, index) => {
                    const {cnName, enName, odds, _id} = team;
                    return (
                        <div className="item_body" key={_id}>
                            <div className="left">
                                <p>{getMonthDate(now)}</p>
                                <p style={{ "marginTop": "3px" }}>欧洲杯</p>
                            </div>
                            <div className="right">
                                <div className="game_table">
                                    <table className="game_data">
                                        <tr className="" onClick={() => handleItemClick(team)}>
                                            <td className={selectedTeamId.includes(_id) ? 'active22' : ''} style={{ width: "50%" }}>
                                                {cnName}
                                            </td>
                                            <td className={selectedTeamId.includes(_id) ? 'active22' : ''}>{odds.toFixed(2)}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Tabs.Tab>
            <Tabs.Tab title="冠亚军" key="fnl">
                {fnlTeamsList && fnlTeamsList.map((team) => {
                    const { cnName, enName, odds, _id } = team;
                    return (
                        <div className="item_body">
                            <div className="left">
                                <p>{getMonthDate(now)}</p>
                                <p style={{ "marginTop": "3px" }}>欧洲杯</p>
                            </div>
                            <div className="right">
                                <div className="game_table">
                                    <table className="game_data" onClick={() => handleItemClick(team)}>
                                        <tr className="">
                                        <td className={selectedTeamId.includes(_id) ? 'active22' : ''} style={{ width: "50%" }}>
                                                {cnName}
                                            </td>
                                            <td className={selectedTeamId.includes(_id) ? 'active22' : ''}>{odds.toFixed(2)}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Tabs.Tab>
        </Tabs>
        <div>
            <div className="order-info-container">
                <Button fill='outline' color="primary" onClick={() => {handleClearSelected()}}>清空</Button>
                <div style={{ textAlign: "center", width: "200px" }}>
                    <div>已选择{selectedTeamId.length}场</div>
                    {
                        currentSubType === 'chp' && <div>请至少选择一个冠军队伍</div>
                    }
                    {
                        currentSubType === 'fnl' && <div>请至少选择一组冠亚队伍</div>
                    }
                </div>
                <div>
                    <Button color="primary" onClick={handleTakeOrderClick}>下单</Button>
                </div>
            </div>
        </div>
    </div>)
}