import React, { useEffect, useState } from "react";
import { NavBar, Tabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { getFollowBuyMeInfo, getFollowBuyMeList } from "../../axios";
import { getTimeInYear } from "../../utils";
import { cnNameMap } from "../../constant/cnNameMap";
import defaultUserPNG from "../../assets/default_photo.8b581359.png";

import "./FollowBuyMe.css";
const status2CN = {
    "0": "未接单",
    "1": "未出票",
    "2": "已出票",
    "3": "未中奖",
    "4": "已中奖",
    "5": "已派奖",
    "6": "已撤单",
}
export default function FollowBuyMe() {
    const navigate = useNavigate();
    const [listType, setListType] = useState("0");
    const [userInfo, setUserInfo] = useState({});
    const [followList, setFollowList] = useState<any[]>([]);
    const [publishList, setPublishList] = useState<any[]>([]);
    const [latestResult, setLatestResult] = useState([]);
    const [followBuySum, setFollowBuySum] = useState({});
    useEffect(() => {
        getFollowBuyMeInfo({}).then(({data}) => {
            setUserInfo(data?.userInfo);
            setFollowBuySum(data?.followBuySum);
            setLatestResult(data?.latestResult);
        });
        getFollowBuyMeList({}).then(({data}) => {
            setFollowList(data?.followList || []);
            setPublishList(data?.publishList || []);
            const _latestResult = data?.latestResult || [];
            for(let i = 0; i < 5; i++) {
                if(!_latestResult[i]) {
                    _latestResult[i] = 0;
                }
            }
            setLatestResult(_latestResult)
        })
    }, []);
    return (
        <div className="bigView follow-buy-me">
            <NavBar onBack={() => navigate(-1)}>
                我的战绩
            </NavBar>
            <div className="myInfo">
                <div className="t">
                    <div className="uni-image">
                        <img
                            src={userInfo?.avatar || defaultUserPNG}
                            draggable="false" />
                    </div>
                    <div className="t1">
                        <span><span>{userInfo?.username}</span></span>
                        <div style={{ "fontWeight": "bold", "fontSize": "12px" }}>
                            <span style={{ "marginRight": "11px" }}>
                                <span>粉丝：{userInfo?.followerNum}人</span>
                            </span>
                            <span><span>关注：{userInfo?.followingNum}人</span></span></div>
                    </div>
                </div>
                <div className="title" style={{ "padding": "11px", "borderBottom": "1px solid rgb(245, 245, 245)" }}>我的战绩</div>
                <div className="c">
                    <div className="c1"><span><span>{userInfo?.followBuyRecord?.bonus || 0}元</span></span>
                        <div>累计中奖</div>
                    </div>
                    <div className="c1"><span><span>{((followBuySum?.profitMargin || 0) * 100).toFixed(2)}%</span></span>
                        <div>7日盈利</div>
                    </div>
                    <div className="c1"><span><span>{followBuySum?.total || 0}中{followBuySum?.totalWin || 0}</span></span>
                        <div>7日命中</div>
                    </div>
                    <div className="c1"><span><span>{userInfo?.followBuyRecord?.leadNum || 0}</span></span>
                        <div>带红人数</div>
                    </div>
                </div>
                <div className="f">
                    <span className="f1" style={{ "color": "rgb(153, 153, 153)" }}><span>近5期</span></span>
                    {
                        latestResult?.map((val) => {
                            if(val === 0) {
                                return (<span className="f2"></span>)
                            } else if(val === 3) {
                                return (<span className="f2">未</span>)
                            } else {
                                return (<span className="f2 active">中</span>)
                            }
                        })
                    }
                </div>
            </div>
            <div>
                <Tabs activeKey={listType} onChange={setListType}>
                    <Tabs.Tab title='我的跟单' key="0" />
                    <Tabs.Tab title='我的方案' key="1" />
                    {/* <Tabs.Tab title='我的定制' key="2" />
                    <Tabs.Tab title='我的关注' key="3" /> */}
                </Tabs>
            </div>
            <div className="container">
                <div className="content">
                    {
                        listType === "0" && (
                            followList?.length ? (
                                <>
                                    {
                                        followList?.map((res) => (
                                            <div className="item">
                                                <div className="ttop">
                                                    <div className="left">
                                                        <div className="uni-image" >
                                                            <img src="https://docqe.oss-cn-hangzhou.aliyuncs.com/upload/memberphoto/20230730/59971690703892147.jpeg" draggable="false" />
                                                        </div>
                                                        <span>{res?.followBuyTask?.creatorName}</span>
                                                    </div>
                                                    <div className="right"></div>
                                                </div>
                                                <div className="footer">
                                                    <div className="left">
                                                        <span>{getTimeInYear(res?.followBuyTask?.createTime)}</span>
                                                        <span>{cnNameMap[res?.type]}</span>
                                                        <span style={{ "marginLeft": "6px" }}>2串1</span>
                                                        <span>跟单{res?.spend || 0}元</span>
                                                    </div>
                                                    <div className="right" style={{display: "flex"}}>
                                                        <span>{status2CN[res?.status]}</span>
                                                        <div className="uni-image" style={{ "width": "9px", "height": "12px" }}>
                                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAdCAMAAACUsxyNAAAAM1BMVEUAAACZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlkqvaGAAAAEHRSTlMA8qTFRTDTUiKYYAi8O7N6nn3qdAAAAFBJREFUGNOdkEESgCAMA1tQUaiQ/7/Wq8mFgd52Mzk0VvNhdA/wkmihxjJwrpvYal3we2LaljFHECeAtujyd5c8zbnIskXzxb5y/fMQtuHMH4+hBMSCqMInAAAAAElFTkSuQmCC" draggable="false" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                        )
                                    }
                                </>
                            ) : (
                                <div className="noData">
                                    <div className="box">
                                        <div className="uni-image">
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAMAAABG8BK2AAAAM1BMVEUAAACZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlkqvaGAAAAEHRSTlMAFERUjb5kNvAk3Myvf5xz5H7VxgAAAm5JREFUWMOtWFtywyAMNEJgwJBw/9O2g0ukAWSb0P1pk8gLeiwS3i6wWzQuFziDdt/moWzMHYz1zUIa8YJdmyzA6NPCQ3r97RQEEuvyBVwqrhKO8U7yJMyAxBtugBq8+oUHjaKf6XIr2PkMOKTRHcvHLmgh9uFjEZP25b8uVaGawCYCEiYLXpUPxVo1pSLsUsZezMcsUW1PoQf5zife23OkkopRXGCbQOzWfeWCOfmFNpKaWCbQPqPIowmoNt9H+cJuc/BNvoEkNk8DTa7nOPrnNNXuFOhBInXbFwhsO0B5m8XOomNoM9MIn9wo5uBDVZIpfErHkn+Y81s94nhXSZ4FV31CCjz623pBcgCrVxQlm0+89suwvvIJS0GufylgBUZUF5hqE7g8zy0cXaML+vpEd1WAhdaeziGtdpCh3E0P3Z6AphUC0AwA4xYWof3abK4vYZ/qA57L+Q/J94XsNjInqOrZzsyrP2p46PQdC8ywyZtxGtWYxjopxvwXiYaKvE/GII05KZkGc8VrF0u4AnsaT73qTlYeP0vxEFPCT8Z7kauqPZZwXn5JEIEgCuTlx8Vg8XHDA7RcDCTNARQwCK6W5NnhqENdkAMu+7hs4pqBWF6KDtEBzP38S4eoFb3ygbMEL/pkWYMZQTFctF+12u4cebvefFdGAcrlPw4m62PSytBGdQWLI6Slu93CQAu51szKeL0Pbzs4ybPTgbxw9QA2VXx/EXrz0/jba5mKmcIr8mR7l2hiGeJ4cmUNuY3LwgWa52jhOn8jZB8z4eAvF45MiP5e/S7fwNFWronWSIgoSiRRr7+UivayNNdfkf0AwKlXUO4tix4AAAAASUVORK5CYII=" draggable="false" />
                                        </div>
                                        <span>暂无数据</span>
                                    </div>
                                </div>
                            )
                        )
                    }
                    {
                        listType === "1" && (
                            publishList?.length ? (
                                <>
                                    {
                                        publishList?.map((res) => (
                                            <div className="item">
                                                <div className="top1">
                                                    <div className="left">
                                                        <span>{getTimeInYear(res?.createTime)}</span>
                                                        <span>{ cnNameMap[res?.bet?.type] }</span>
                                                        <span>自购{ res?.amount.toFixed(0) || 0 }元</span>
                                                    </div>
                                                    {
                                                        res?.bet?.bonus > 0 && (<div className="right">{res?.bet?.bonus}元</div>)
                                                    }
                                                </div>
                                                <div className="footer">
                                                    <div className="left">
                                                        <span>跟单：{res?.followNum || 0}人</span>
                                                    </div>
                                                    <div className="right" style={{display: "flex"}}>
                                                        <span>{status2CN[res?.bet?.status]}</span>
                                                        <div className="uni-image" style={{ "width": "9px", "height": "12px" }}>
                                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAdCAMAAACUsxyNAAAAM1BMVEUAAACZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlkqvaGAAAAEHRSTlMA8qTFRTDTUiKYYAi8O7N6nn3qdAAAAFBJREFUGNOdkEESgCAMA1tQUaiQ/7/Wq8mFgd52Mzk0VvNhdA/wkmihxjJwrpvYal3we2LaljFHECeAtujyd5c8zbnIskXzxb5y/fMQtuHMH4+hBMSCqMInAAAAAElFTkSuQmCC" draggable="false" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            ) : (
                                <div className="noData">
                                    <div className="box">
                                        <div className="uni-image">
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAMAAABG8BK2AAAAM1BMVEUAAACZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlkqvaGAAAAEHRSTlMAFERUjb5kNvAk3Myvf5xz5H7VxgAAAm5JREFUWMOtWFtywyAMNEJgwJBw/9O2g0ukAWSb0P1pk8gLeiwS3i6wWzQuFziDdt/moWzMHYz1zUIa8YJdmyzA6NPCQ3r97RQEEuvyBVwqrhKO8U7yJMyAxBtugBq8+oUHjaKf6XIr2PkMOKTRHcvHLmgh9uFjEZP25b8uVaGawCYCEiYLXpUPxVo1pSLsUsZezMcsUW1PoQf5zife23OkkopRXGCbQOzWfeWCOfmFNpKaWCbQPqPIowmoNt9H+cJuc/BNvoEkNk8DTa7nOPrnNNXuFOhBInXbFwhsO0B5m8XOomNoM9MIn9wo5uBDVZIpfErHkn+Y81s94nhXSZ4FV31CCjz623pBcgCrVxQlm0+89suwvvIJS0GufylgBUZUF5hqE7g8zy0cXaML+vpEd1WAhdaeziGtdpCh3E0P3Z6AphUC0AwA4xYWof3abK4vYZ/qA57L+Q/J94XsNjInqOrZzsyrP2p46PQdC8ywyZtxGtWYxjopxvwXiYaKvE/GII05KZkGc8VrF0u4AnsaT73qTlYeP0vxEFPCT8Z7kauqPZZwXn5JEIEgCuTlx8Vg8XHDA7RcDCTNARQwCK6W5NnhqENdkAMu+7hs4pqBWF6KDtEBzP38S4eoFb3ygbMEL/pkWYMZQTFctF+12u4cebvefFdGAcrlPw4m62PSytBGdQWLI6Slu93CQAu51szKeL0Pbzs4ybPTgbxw9QA2VXx/EXrz0/jba5mKmcIr8mR7l2hiGeJ4cmUNuY3LwgWa52jhOn8jZB8z4eAvF45MiP5e/S7fwNFWronWSIgoSiRRr7+UivayNNdfkf0AwKlXUO4tix4AAAAASUVORK5CYII=" draggable="false" />
                                        </div>
                                        <span>暂无数据</span>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}