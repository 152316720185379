import React, { useEffect, useState } from 'react';
import { Button, Mask, Radio, Space, SpinLoading, Toast } from 'antd-mobile';
import { useDispatch } from 'react-redux';
import LogoPNG from '../assets/20f6a6d89da8b1637b6d05d8de1d06f.png';
import { set } from "../store/userSlice.js";
import { getUser, login } from '../axios/index.js';
import { useNavigate } from 'react-router-dom';
import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('ph') || '');
  const [password, setPassword] = useState('');
  const [shopList, setShopList] = useState([]);
  const [selectedShop, setSelectedShop] = useState<string>();
  const [shopSelectMaskVisible, setShopSelectMaskVisible] = useState(false);

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleLogin = () => {
    login({
        phone:phoneNumber, password, shop: selectedShop,
    }).then(({data}) => {
      if(data.status === 'error') {
        Toast.show(data.errorMessage);
      } else {
        if(data.multiShops) {
          setShopList(data.shopList);
          setShopSelectMaskVisible(true);
        } else {
          localStorage.setItem('ph', phoneNumber);
          dispatch(set(data));
          navigate("/");
        }
      }
    })
  };

  useEffect(() => {
    getUser({}).then(({data}) => {
      if(data.status === 'error') {
          setLoading(false);
      } else {
          dispatch(set(data.user));
          navigate("/");
      }
    });
  }, [dispatch, navigate])

  return (
      <div className="aui-flexView">
        {
          loading ? (
            <div style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
          }}>
              <SpinLoading style={{ '--size': '68px' }} />
          </div>
          ) : (
            <>
              <div className="aui-navBar aui-navBar-fixed" style={{
                height: "300px",
                background: "linear-gradient(#ff4800, #ffffff)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                  <div>
                      <span className="aui-center-title">
                        <img src={LogoPNG} alt="" width={70}/>
                      </span>
                  </div>
              </div>
              <div style={{ backgroundColor: "#fff", borderRadius: "12px", boxShadow: "#ff48003f 0 3px 6px", padding: "30px 20px", margin: "0 15px", marginTop: "-30px", position: "relative", zIndex: 3 }}>
                <input
                  type="text" 
                  className="login-input" 
                  name="username" 
                  style={{ background: "url(images/login-user.jpg) no-repeat 9px center", backgroundSize: "22px" }} 
                  placeholder="手机号" 
                  value={phoneNumber}
                  onChange={(e) => handlePhoneNumberChange(e.target.value)}/>
                <input 
                  type="password" 
                  className="login-input" 
                  name="password" 
                  style={{ background: "url(images/login-lock.jpg) no-repeat 9px center", backgroundSize: "22px", letterSpacing: "9px" }} 
                  placeholder="密码"
                  onChange={(e) => handlePasswordChange(e.target.value)}/>
                <div style={{ margin: "6px 9px" }}>
                    <input type="checkbox" className="check-orange" style={{ verticalAlign: "middle" }}/>
                    <span style={{ color: "#9f9f9f" }}>记住账号</span>
                    <div style={{ textAlign: "right", float: "right" }}><a href="javascript:;" style={{ color: "#9f9f9f" }} onClick={() => navigate("/person/password")}>忘记密码？</a></div>
                </div>
                <div style={{ textAlign: "center", margin: "20px 0" }}>
                  <button className="btn-orange btn-main" onClick={handleLogin}>立即登录</button>
                </div>
              </div>
              <div style={{ position: "absolute", bottom: "20px", width: "100%", textAlign: "center" }}><a href="javascript:;" className="font-orange">在线客服</a></div>
            </>
          )
        }
        <Mask visible={shopSelectMaskVisible} onMaskClick={() => setShopSelectMaskVisible(false)}>
          <div style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <div style={{ height: '50vh', width: '80%', background: 'white', display: "flex", flexDirection: "column" }}>
              <div style={{ textAlign: "center", 'fontSize': '18px' }}>请选择您要登录的店铺</div>
              <div style={{ flex: '1 1', padding: '10px' }}>
                <Radio.Group onChange={(val) => setSelectedShop(val as string)}>
                  <Space direction='vertical' block>
                    {
                      shopList.map((shop) => (
                        <Radio value={shop._id} block>
                          {shop.name}
                        </Radio>
                      ))
                    }
                  </Space>
                </Radio.Group>
              </div>
              <div style={{ display: "flex" }}>
                <Button block color='danger' onClick={() => {setShopSelectMaskVisible(false); setSelectedShop('')}}>取消</Button>
                <Button block color='success' onClick={() => handleLogin()}>确定</Button>
              </div>
            </div>
          </div>
        </Mask>
      </div>
  );
};

export default Login;