import React, { useEffect, useMemo, useState } from "react";
import "./MatchList.css";
import { getMatchListByDay } from "../../axios";
import { JumboTabs, Toast, Empty } from "antd-mobile";
import HomeTeamPNG from "../../assets/teamHome.png"
import AwayTeamPNG from "../../assets/teamAway.png"
import Footer from "../../components/footer";
import { getTime } from "../../utils";
import { cnNameMap } from "../../constant/cnNameMap";
function getDateFormat(time: number) {
    const date = new Date(time);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}/${day}`;
}

function getDayInWeek(time: number) {
    const date = new Date(time);
    const dayList = ['周日', '周一','周二','周三','周四','周五','周六'];
    return dayList[date.getDay()];
}
export default function MatchList() {
    const now = useMemo(() => Date.now(), []);
    const [type, setType] = useState("football");
    const [time, setTime] = useState(`${now}`);
    const [matchList, setMatchList] = useState([]);
    const dateList = useMemo(() => {
        const _dateList = [];
        for(let i = -7; i <= 7; i++) {
            const _time = now + i * 24 * 60 * 60 * 1000;
            _dateList.push({
                time: `${_time}`,
                date: getDateFormat(_time),
                day: getDayInWeek(_time),
            })
        };
        return _dateList
    }, [now]);
    useEffect(() => {
        getMatchListByDay({type, time}).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setMatchList(data.matchList);
            }
        })
    }, [time, type])
    return (
        <div className="match-list-day" style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div className="top">
                <div className="wzgg">开奖公告</div>
                <div className="zqnr">
                    <ul>
                        <li className={(type==="football" || type==='beijing') ? "cur" : ""} onClick={() => {setMatchList([]);setType('football')}}><div>足球</div></li>
                        <li className={ type==="basketball" ? "cur" : "" } onClick={() => {setMatchList([]);setType('basketball')}}><div>篮球</div></li>
                    </ul>
                </div>
                {
                    type !== 'basketball' && (
                        <div className="jcfl">
                            <ul>
                                <li className={ type==="football" ? "cur" : "" } onClick={() => {setMatchList([]);setType('football')}}><div>竞彩</div></li>
                                <li className={ type==="beijing" ? "cur" : "" } onClick={() => {setMatchList([]);setType('beijing')}}><div>北单</div></li>
                                {/* <li><div>足彩</div></li> */}
                            </ul>
                        </div>
                    )
                }
            </div>
            <div className="kjrq">
                <div className="swiper mySwiper">
                    <div className="swiper-wrapper">
                        <JumboTabs defaultActiveKey={`${now}`} onChange={(val) => setTime(val)}>
                            {
                                dateList.map((data) => (
                                    <JumboTabs.Tab title={data.day} description={data.date} key={`${data.time}`} />
                                ))
                            }
                        </JumboTabs>
                    </div>
                </div>
            </div>
            <div className="bsnr">
                <ul>
                    {
                        matchList.map((match) => {
                            let status = 0;
                            const { matchTime, finished, result = {}, liveStatus, liveMins } = match;
                            const now = Date.now();
                            if(matchTime < now) {
                                if(liveStatus === 5) status = 1;
                                if(finished || liveStatus === 6) status = 2;
                            }
                            return (
                                <li>
                                    <div className="bsnrbox">
                                        <h2>
                                            <span>{match.matchNumStr}</span>
                                            {

                                                status === 0 && `未开赛 ${getTime(matchTime)}`
                                            }
                                            {
                                                status === 1 && (<>比赛中 <span style={{color:"red"}}>{liveMins}'</span></>)
                                            }
                                            {
                                                status === 2 && `完赛 ${getTime(matchTime)}`
                                            }
                                        </h2>
                                        {
                                            type !== 'basketball' ? (
                                            <div className="bstext">
                                                <div className="bs_left">
                                                    <h3><img src={HomeTeamPNG}/>{match.homeTeamAbbName}</h3>
                                                </div>
                                                {
                                                    status === 0 ? (
                                                        <div className="bcbs">
                                                            <h4>VS</h4>
                                                        </div>
                                                    ) : (
                                                        <div className="bcbs">
                                                            <h3>{match.score || "0:0"}</h3>
                                                            <p>上半场{match.halfScore}</p>
                                                        </div>
                                                    )
                                                }
                                                <div className="bs_right">
                                                    <h3><img src={AwayTeamPNG} />{match.awayTeamAbbName}</h3>
                                                </div>
                                            </div>
                                            ) : (
                                                <div className="bstext">
                                                    <div className="bs_left">
                                                        <h3><img src={AwayTeamPNG} />{match.awayTeamAbbName}</h3>
                                                    </div>
                                                    {
                                                        status < 2 ? (
                                                            <div className="bcbs">
                                                                <h4>VS</h4>
                                                            </div>
                                                        ) : (
                                                            <div className="bcbs">
                                                                <h3>{match.score}</h3>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="bs_right">
                                                        <h3><img src={HomeTeamPNG}/>{match.homeTeamAbbName}</h3>
                                                        
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                        {
                                            type === 'football' && (
                                                <div className="bstext" style={{ marginTop: ".35rem" }}>
                                                    <div className="odds-box">
                                                        <div>胜平负</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.had] || '--'}</div>
                                                            <div>{result.hadOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>让球</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.hhad] || '--'}</div>
                                                            <div>{result.hhadOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>总进球</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.ttg] || '--'}</div>
                                                            <div>{result.ttgOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>半全场</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.hafu] || '--'}</div>
                                                            <div>{result.hafuOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>比分</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.crs] || '--'}</div>
                                                            <div>{result.crsOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            type === 'beijing' && (
                                                <div className="bstext" style={{ marginTop: ".35rem" }}>
                                                    <div className="odds-box">
                                                        <div>胜平负</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.had] || '--'}</div>
                                                            <div>{result.hadOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>总进球</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.ttg] || '--'}</div>
                                                            <div>{result.ttgOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>半全场</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.hafu] || '--'}</div>
                                                            <div>{result.hafuOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>比分</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.crs] || '--'}</div>
                                                            <div>{result.crsOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>上下单双</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.sxds] || '--'}</div>
                                                            <div>{result.sxdsOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            type === 'basketball' && (
                                                <div className="bstext" style={{ marginTop: ".35rem" }}>
                                                    <div className="odds-box">
                                                        <div>胜负</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.mnl] || '--'}</div>
                                                            <div>{result.mnlOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>让分胜负</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.hdc] || '--'}</div>
                                                            <div>{result.hdcOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>胜分差</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.wnm] || '--'}</div>
                                                            <div>{result.wnmOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="odds-box">
                                                        <div>大小分</div>
                                                        <div className="odds-detail">
                                                            <div>{cnNameMap[result.hilo] || '--'}</div>
                                                            <div>{result.hiloOdds || '--'}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </li>
                            )
                        })
                    }
                    {
                        matchList.length === 0 && (
                            <Empty
                                style={{ padding: '64px 0' }}
                                imageStyle={{ width: 128 }}
                                description='暂无赛事'
                            />
                        )
                    }
                </ul>
            </div>
            <Footer />
        </div>
    )
}