import { Button, Form, Input, NavBar, NoticeBar } from "antd-mobile";
import { ExclamationOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

export default function RealName() {
    const navigate = useNavigate();
    const [realName, setRealName] = useState<string>();
    const [IdCard, setIdCard] = useState<string>();
    return (
        <div>
            <NavBar
                onBack={() => navigate(-1)}>
                实名认证
            </NavBar>
            <NoticeBar
                color='alert'
                icon={<ExclamationOutline />}
                content={'真实姓名和身份证是购彩和领取奖金的唯一凭证，提交后不可更改!'}
            />
            <Form layout='horizontal'>
                <Form.Item
                    name='realName'
                    label='真实姓名'
                >
                    <Input onChange={setRealName} placeholder='请输入您的真实姓名' />
                </Form.Item>
                <Form.Item
                    name='IdCard'
                    label='身份证号码'
                >
                    <Input onChange={setIdCard} placeholder='请输入您的身份证号' />
                </Form.Item>
            </Form>
            {/* <div data-v-5bdaa59c="" className="ph">你绑定的手机号码为：1658375****</div> */}
            <div className="wring"><span>提醒：禁止未成年人购彩</span></div>
            <Button block type='submit' color='primary' size='large'>
                提交
            </Button>
        </div>
    )
}