import React, { useEffect, useMemo, useState } from "react";
import "./BetSummarize.css";
import { Button, NavBar, Toast } from "antd-mobile";
import { AddOutline, MinusOutline } from "antd-mobile-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Team } from "../../interface";
import { cnNameMap } from "../../constant/cnNameMap";
import { getTime } from "../../utils";
import InputModal from "../../components/InputModal";
import { PlayType } from "../../constant/betPlayType";


export default function BetsummarizeC() {
    const location = useLocation();
    const navigate = useNavigate();
    const shop = useSelector((state) => state.shop.shop);
    const [inputModalVisible, setInputModalVisible] = useState(false);
    const [times, setTimes] = useState(1);
    const [maxSP, setMaxSP] = useState<number>();
    const [maxBonus, setMaxBonus] = useState<string>();
    const [amount, setAmount] = useState(0);
    const items: Team[] = useMemo(() => {
        return location.state?.items
    }, [location.state])
    const type: string = useMemo(() => {
        return location.state?.type
    }, [location.state])
    const subType: string = useMemo(() => {
        return location.state?.subType
    }, [location.state])
    const closeTime: number = useMemo(() => {
        return location.state?.closeTime
    }, [location.state])
    const enableBuy = useMemo(() => {
        return shop.config[type]?.enableBuy || true;
    }, [shop.config, type]);
    const minAmount = useMemo(() => {
        return shop.config[type]?.minAmount || 10;
    }, [shop.config, type]);

    useEffect(() => {
        setAmount(items.length * times * 2);
        let maxBonus = 0;
        for(let item of items) {
            maxBonus = Math.max(item.odds, maxBonus);
        }
        setMaxBonus((maxBonus * times * 2).toFixed(2));
    }, [items, times]);

    const back = () => {
        navigate("/person/champion");
    };

    const handleOrderClick = async () => {
        if (!enableBuy) {
            Toast.show("店主暂停出票");
            return;
        }
        if (minAmount > amount) {
            Toast.show(`最低投注${minAmount}元`);
            return;
        }
        let data = {
            type,
            subType,
            playType: PlayType.Normal,
            times,
            closeTime,
            options: '1^1',
            items: items.map(item => item._id)
        }
        navigate("/person/pay", { state: { data, type, subType, amount, closeTime } });
    }
    const handleBackToChange = async () => {
        let path;
        switch (type) {
            case "champion": path = "/person/champion"; break;
            default: break;
        }
        navigate(path as string, { state: { items, subType } });
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <NavBar onBack={back} >
                投注
            </NavBar>
            <div style={{ margin: "4px 12px" }}>
                <div className="row center">{getTime(closeTime)} 截止，请尽快提交投注</div>
                <Button block color='primary' size='small' fill='outline' style={{ height: "30px", marginTop: "4px" }} onClick={handleBackToChange}>
                    <div style={{ "fontSize": "15px" }}>添加/修改投注</div>
                </Button>
            </div>
            <div className="order-list" style={{ paddingBottom: "100px", flex: '1 1', overflow: "auto", height: "100vh" }}>
                {
                    items && items.map((item) => {
                        return (
                            <div className="order-item row" style={{ justifyContent: "space-between", padding: '10px 20px' }}>
                                <div>{item.cnName}</div>
                                <div>{item.odds.toFixed(2)}</div>
                            </div>
                        )
                    })
                }
            </div>
            {
                inputModalVisible && (
                    <InputModal baseTimes={times} height={75} onConfirmClick={setTimes} onClose={() => setInputModalVisible(false)} />
                )
            }
            <div className="fixed column" style={{ backgroundColor: "white" }}>
                <div className="row fixed-result-top align-items-center" style={{ justifyContent: 'end' }}>
                    <div className="row align-items-center" style={{ paddingRight: "10px" }}>
                        投 <div className="option-box row center align-items-center"><MinusOutline onClick={() => { if (times > 1) setTimes(times - 1) }} /></div>
                        <div className="option-value-box row center align-items-center" onClick={() => setInputModalVisible(true)}>{times}</div>
                        <div className="option-box row center align-items-center"><AddOutline onClick={() => { setTimes(times + 1) }} /></div> 倍
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="fixed-result-middle">
                        金额 <span style={{ color: "var(--adm-color-primary)" }}>{amount}</span> 元
                        <span className="fixed-result-middle-yellow" style={{ marginLeft: "10px" }}>
                            预计最高奖金：{maxBonus} 元</span>
                    </div>
                    <div className="row" style={{ height: "40px" }}>
                        <div className="row fixed-result-bottom-right center align-items-center" onClick={handleOrderClick}>确认投注</div>
                    </div>
                </div>
            </div>
        </div>
    );
}


