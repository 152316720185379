import { get, post, postForm } from "./base.js";

/**
 * @param {any} params
 */
export function getDailyUpdateList(params) {
    return get("/docking/daily/list", params)
}

/**
 * @param {any} params
 */
export function getDailyUpdateDetail(params) {
    return get("/docking/daily/detail", params)
}

/**
 * @param {any} params
 */
export function postDailyUpdate(params) {
    return post("/docking/daily/update", params)
}