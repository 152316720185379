import { Tabs } from "antd-mobile";
import React from "react";
import { getTime } from "../../utils";

export default function PickThreeGroup6(props: any) {
    const { targetArrTen, targetArrBit, onSubTypeChange, onTargetClick, doRandom, currentTerm, closeTime } = props;
    return (
        <Tabs onChange={(type) => onSubTypeChange(type)}>
            <Tabs.Tab title='复式' key='601'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div>每位至少选择
                            <span style={{ color: "red" }}>3</span>
                            个号码，奖金
                            <span style={{ color: "red" }}>173</span>
                            元
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot" style={{ justifyContent: "center" }}>
                        <div className="number-red-foot-view" style={{ width: "81vw", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className={`number-red-foot-num ${targetArrBit.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 0)}>0</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 1)}>1</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 9)}>9</div>
                        </div>
                    </div>
                    <div className="number-red-top">
                        <div className="">快速选号</div>
                    </div>
                    <div className="number-red-btn">
                        <div className="">10选8 赔率 1.54</div>
                        <div className="btnClass">10选7 赔率 2.47</div>
                        <div className="">10选6 赔率 4.32</div>
                        <div className="">10选5 赔率 8.65</div>
                    </div>
                </div>
            </Tabs.Tab>
            <Tabs.Tab title='胆拖' key='602'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div data-v-c0f5359e="">胆码(选择1-2个)</div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot" style={{ border: "0px", marginTop: "8px", justifyContent: "center" }}>
                        <div className="number-red-foot-view"
                            style={{ width: "81vw", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className={`number-red-foot-num  ${targetArrTen.has(0) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 0)}>0</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(1) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 1)}>1</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 2)}>2</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 3)}>3</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 4)}>4</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 5)}>5</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 6)}>6</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 7)}>7</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 8)}>8</div>
                            <div className={`number-red-foot-num  ${targetArrTen.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("ten", 9)}>9</div>
                        </div>
                    </div>
                    <div className="number-red-top" style={{ marginTop: "8px" }}>
                        <div className="">拖码(胆码与拖码数量之和大于等于4)</div>
                        <div className="number-red-top-last" onClick={doRandom}></div>
                    </div>
                    <div className="number-red-foot" style={{ border: "0px", marginTop: "8px", justifyContent: "center" }}>
                        <div className="number-red-foot-view"
                            style={{ width: "81vw", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className={`number-red-foot-num  ${targetArrBit.has(0) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 0)} style={{ color: "rgb(17, 133, 210)" }}>0</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(1) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 1)} style={{ color: "rgb(17, 133, 210)" }}>1</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(2) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 2)} style={{ color: "rgb(17, 133, 210)" }}>2</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(3) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 3)} style={{ color: "rgb(17, 133, 210)" }}>3</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(4) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 4)} style={{ color: "rgb(17, 133, 210)" }}>4</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(5) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 5)} style={{ color: "rgb(17, 133, 210)" }}>5</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(6) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 6)} style={{ color: "rgb(17, 133, 210)" }}>6</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(7) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 7)} style={{ color: "rgb(17, 133, 210)" }}>7</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(8) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 8)} style={{ color: "rgb(17, 133, 210)" }}>8</div>
                            <div className={`number-red-foot-num  ${targetArrBit.has(9) ? "theBlue" : ""}`} onClick={() => onTargetClick("bit", 9)} style={{ color: "rgb(17, 133, 210)" }}>9</div>
                        </div>
                    </div>
                </div>
            </Tabs.Tab>
            <Tabs.Tab title='跨度复式' key='603'>
                <div className="number-red">
                    <div style={{
                        padding: "10px 15px",
                        background: "#fbf5f6"
                    }}>
                        第{currentTerm}期 截止时间：{getTime(closeTime)}
                    </div>
                    <div className="number-red-top">
                        <div>至少选择<span style={{"color":"red"}}><span>1</span></span>个跨度值，奖金<span style={{"color":"red"}}><span>173</span></span>元</div>
                        <div style={{ display: "flex" }}>
                            <div className="number-red-top-last" onClick={doRandom}>
                                <div>机选</div>
                            </div>
                        </div>
                    </div>
                    <div className="number-red-foot">
                        <div className="number-red-foot-view" style={{ width: "81vw" }}>
                            <div className={`number-red-foot-num ${targetArrBit.has(2) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 2)}>2</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(3) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 3)}>3</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(4) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 4)}>4</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(5) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 5)}>5</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(6) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 6)}>6</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(7) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 7)}>7</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(8) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 8)}>8</div>
                            <div className={`number-red-foot-num ${targetArrBit.has(9) ? "theRed" : ""}`} onClick={() => onTargetClick("bit", 9)}>9</div>
                        </div>
                    </div>
                </div>
            </Tabs.Tab>
        </Tabs>
    )
}