
import * as math from "mathjs";

export function calMatchBonus(type: string, item: any, odds: any) {
    if (!odds) return [0, 0];
    const subTypeList = Object.keys(item);
    let minOddsPlus = [Infinity]; let maxOddsPlus = [0];
    if (type === 'football') {
        const goalLine = Number(odds['hhad']?.goalLine || 0);
        let maxScore = Math.abs(goalLine) + 2;
        if (subTypeList.includes('ttg')) {
            maxScore = 7
        } else if (subTypeList.includes('crs')) {
            maxScore = 6;
        }
        for (let home = 0; home <= maxScore; home++) {
            for (let guest = 0; guest <= maxScore; guest++) {
                let _minOddsPlus: number[]  = [];
                let _maxOddsPlus: number[] = [];
                if (subTypeList.includes('had')) {
                    let res = '';
                    if (home > guest) res = 'win';
                    if (home === guest) res = 'draw';
                    if (home < guest) res = 'lose';
                    if (item['had'].target.includes(res)) {
                        _minOddsPlus.push(odds['had'][res]);
                        _maxOddsPlus.push(odds['had'][res]);
                    }
                }
                if (subTypeList.includes('hhad')) {
                    let res = '';
                    if (home + goalLine > guest) res = 'win';
                    if (home + goalLine === guest) res = 'draw';
                    if (home + goalLine < guest) res = 'lose';
                    if (item['hhad'].target.includes(res)) {
                        _minOddsPlus.push(odds['hhad'][res]);
                        _maxOddsPlus.push(odds['hhad'][res]);
                    }
                }
                if (subTypeList.includes('crs')) {
                    let res = '';
                    if ((home + guest) < 8 && home < 6 && guest < 6 && !((home === 4 && guest === 3) || (home === 3 && guest === 4))) {
                        res = `s0${home}s0${guest}`;
                    } else {
                        if (home > guest) res = 's1sw';
                        else if (home === guest) res = 's1sd';
                        else res = 's1sl';
                    }
                    if (item['crs'].target.includes(res)) {
                        _minOddsPlus.push(odds['crs'][res]);
                        _maxOddsPlus.push(odds['crs'][res]);
                    }
                }
                if (subTypeList.includes('ttg')) {
                    let res = '';
                    res = ((home + guest) > 6 ? 's7' : `s${home + guest}`);
                    if (item['ttg'].target.includes(res)) {
                        _minOddsPlus.push(odds['ttg'][res]);
                        _maxOddsPlus.push(odds['ttg'][res]);
                    }
                }
                if (subTypeList.includes('hafu')) {
                    const bqcStr1: string[] = [];
                    const bqcStr2 = (home > guest ? 'w' : home === guest ? 'd' : 'l');
                    if (home !== 0) bqcStr1.push(`w${bqcStr2}`);
                    bqcStr1.push(`d${bqcStr2}`);
                    if (guest !== 0) bqcStr1.push(`l${bqcStr2}`);
                    let _minOdds = 1000000;
                    let _maxOdds = 0;
                    for (const res of bqcStr1) {
                        if (item['hafu'].target.includes(res)) {
                            _minOdds = Math.min(_minOdds, odds['hafu'][res]);
                            _maxOdds = Math.max(_maxOdds, odds['hafu'][res]);
                        }
                    }
                    if(_maxOdds !== 0) {
                        _minOddsPlus.push(_minOdds);
                        _maxOddsPlus.push(_maxOdds);
                    }
                }
                if (minOddsPlus.reduce((a,b) => a+b, 0) > _minOddsPlus.reduce((a,b) => a+b, 0) && _minOddsPlus.reduce((a,b) => a+b, 0) > 0) {
                    minOddsPlus = _minOddsPlus;
                }
                if (maxOddsPlus.reduce((a,b) => a+b, 0) < _maxOddsPlus.reduce((a,b) => a+b, 0)) {
                    maxOddsPlus = _maxOddsPlus;
                }
            }
        }
    }
    if (type === 'basketball') {
        const goalLine = Number(odds['hdc']?.goalLine || 0);
        const rf = goalLine + (goalLine < 0 ? -2 : 2);
        const zf = Number(odds['hilo']?.goalLine || 4);
        let maxSmallScore = 0; let maxBigScore = 0;
        const averageScore = Math.round(zf / 2);
        if (subTypeList.includes('wnm')) {
            maxSmallScore = Math.min(-13, -Math.abs(rf));
            maxBigScore = Math.max(13, Math.abs(rf));
        } else if (subTypeList.includes('hdc')) {
            maxSmallScore = -Math.abs(rf);
            maxBigScore = Math.abs(rf);
        } else {
            maxSmallScore = -2;
            maxBigScore = 2;
        }
        for (var home = maxSmallScore + averageScore; home <= maxBigScore + averageScore; home++) {
            for (var guest = maxSmallScore + averageScore; guest <= maxBigScore + averageScore; guest++) {
                let _minOddsPlus: number[] = [];
                let _maxOddsPlus: number[] = [];
                if (subTypeList.includes('mnl')) {
                    let res = (home > guest ? 'win' : 'lose');
                    if (item['mnl'].target.includes(res)) {
                        _minOddsPlus.push(odds['mnl'][res]);
                        _maxOddsPlus.push(odds['mnl'][res]);
                    }
                }
                if (subTypeList.includes('hdc')) {
                    let res = (home + rf > guest ? 'win' : 'lose');
                    if (item['hdc'].target.includes(res)) {
                        _minOddsPlus.push(odds['hdc'][res]);
                        _maxOddsPlus.push(odds['hdc'][res]);
                    }
                }
                if (subTypeList.includes('wnm')) {
                    let res = '';
                    if (home > guest) {
                        if (home - guest <= 5) res = 'w1';
                        else if (home - guest <= 10) res = 'w2';
                        else if (home - guest <= 15) res = 'w3';
                        else if (home - guest <= 20) res = 'w4';
                        else if (home - guest <= 25) res = 'w5';
                        else res = 'w6';
                    } else {
                        if (guest - home <= 5) res = 'l1';
                        else if (guest - home <= 10) res = 'l2';
                        else if (guest - home <= 15) res = 'l3';
                        else if (guest - home <= 20) res = 'l4';
                        else if (guest - home <= 25) res = 'l5';
                        else res = 'l6';
                    }
                    if (item['wnm'].target.includes(res)) {
                        _minOddsPlus.push(odds['wnm'][res]);
                        _maxOddsPlus.push(odds['wnm'][res]);
                    }
                }
                if (subTypeList.includes('hilo')) {
                    let res = (home + guest > zf ? 'high' : 'low');
                    if (item['hilo'].target.includes(res)) {
                        _minOddsPlus.push(odds['hilo'][res]);
                        _maxOddsPlus.push(odds['hilo'][res]);
                    }
                }
                if (minOddsPlus.reduce((a,b) => a+b, 0) > _minOddsPlus.reduce((a,b) => a+b, 0) && _minOddsPlus.reduce((a,b) => a+b, 0) > 0) {
                    minOddsPlus = _minOddsPlus;
                }
                if (maxOddsPlus.reduce((a,b) => a+b, 0) < _maxOddsPlus.reduce((a,b) => a+b, 0)) {
                    maxOddsPlus = _maxOddsPlus;
                }
            }
        }
    }
    if (type === 'beijing') {
        let subType = subTypeList[0];
        const targetList = item[subType].target;
        for (const target of targetList) {
            let _minOddsPlus = [odds[subType][target]];
            let _maxOddsPlus = [odds[subType][target]];
            if (minOddsPlus.reduce((a,b) => a+b) > _minOddsPlus.reduce((a,b) => a+b)) {
                minOddsPlus = _minOddsPlus;
            }
            if (maxOddsPlus.reduce((a,b) => a+b) < _maxOddsPlus.reduce((a,b) => a+b)) {
                maxOddsPlus = _maxOddsPlus;
            }
        }
    }
    return [maxOddsPlus, minOddsPlus];
}

export function calSPlimit(option: number, val: number) {
    if(option === 1) {
        return Math.min(250*10000, val);
    }
    if(option === 2 || option === 3) {
        return Math.min(10 * 10000, val)
    }
    if(option === 4 || option === 5) {
        return Math.min(25 * 10000, val)
    }
    if(option > 5) {
        return Math.min(50 * 10000, val)
    }
    return val;
}

function floatFloor(x: number, n: number) {
    return Number(math.floor(math.bignumber(x), n))
}

function floatRound(x: number, n: number) {
    return Number(math.round(math.bignumber(x), n))
  }

export function roundToTwo(num: number): number {
    num = floatRound(num, 4);
    // 将数字转换为字符串
    let numStr = num.toString();
    // 找到小数点的位置
    let decimalIndex = numStr.indexOf('.');

    // 如果没有小数点，直接返回结果
    if (decimalIndex === -1) {
        return floatFloor(num, 2);
    }

    // 提取小数点后的部分
    let decimalPart = numStr.slice(decimalIndex + 1);

    // 如果小数点后少于3位，直接返回结果
    if (decimalPart.length < 3) {
        return floatFloor(num, 2);
    }

    // 小数点后第2位和第3位数字
    let secondDigit = parseInt(decimalPart[1]);
    let thirdDigit = parseInt(decimalPart[2]);

    // 根据规则进行处理
    let res = 0;
    if (thirdDigit >= 0 && thirdDigit <= 4) {
        // 规则1
        res = floatFloor(num, 2);
    } else if (thirdDigit >= 6 && thirdDigit <= 9) {
        // 规则2
        res = floatFloor(num + 0.01, 2);
    } else if (thirdDigit === 5) {
        if (secondDigit % 2 === 0) {
            // 规则3
            res = floatFloor(num, 2);
        } else {
            // 规则4
            res = floatFloor(num + 0.01, 2);
        }
    };
    return res;
}