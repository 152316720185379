import { get } from "./base.js";

/**
 * @param {any} params
 */
export function getUserAlipayURL(params) {
    return get("/user/alipay/payurl", params);
}

/**
 * @param {any} params
 */
export function getUserHuifuURL(params) {
    return get("/user/huifu/payurl", params);
}