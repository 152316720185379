import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer.tsx";
import { cnNameMap } from "../../constant/cnNameMap";
import "./FollowBuyHall.css"

import { getFollowBuyList, getFollowBuyRank } from "../../axios/index.js";
import defaultUser from "../../assets/images/user-2.jpg";
import { getTimeInYear, options2Str } from "../../utils";
import { Empty, NavBar, Tabs, Toast } from "antd-mobile";
export default function FollowBuyHall() {
    const [topBonus, setTopBonus] = useState([]);
    const [topWinRate, setTopWinRate] = useState([]);
    const [topProfitMargin, setTopProfitMargin] = useState([]);
    const [listType, setListType] = useState("winrate");
    const [followBuyTaskList, setFollowBuyTaskList] = useState([]);
    const [showRanker, setShowRanker] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getFollowBuyRank({}).then(({ data }) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setTopBonus(data.topBonus || []);
                setTopWinRate(data.topWinRate || []);
                setTopProfitMargin(data.topProfitMargin || []);
                if(data.topBonus.length || data.topWinRate.length || data.topProfitMargin.length) {
                    setShowRanker(true);
                }
            }
        })
    }, []);
    useEffect(() => {
        getFollowBuyList({type: listType}).then(({ data }) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                setFollowBuyTaskList(data.followBuyTaskList);
            }
        })
    }, [listType])
    const doFollowBuy = (e, _id) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        navigate(`/person/followbuy/detail/${_id}`);
    }
    return (
        <div className="aui-flexView follow-buy-hall" style={{ marginBottom: 0 }}>
            <NavBar 
            back={null}
            right={
                <div style={{ display: "flex",  justifyContent: "flex-end" }}>
                    <div className="my" onClick={() => navigate("/person/followbuy/me") }>
                            <div className="uni-image" style={{ width: "18px" }}>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFXRSTlMA/cwfCKidfiyTVT815968ihJpXkqqgHi/AAAA7klEQVQ4y5WSUY7DIAwFPcYkIQmBtL3/WVfaRdsako/OF4gRmKcnn9gzZtC6yDXrQSOeV+cz6COIbQmOMJ4/4NGWQTmGOwI838MoUy9MRK93jxgs3p+9sKNu/6J0XyR2Ix1fChs67B0nnD61JJ7ixrbM1gmb++eEyphU/jdqsx1WQGMpsc4KqwwskTfP4ficAFVoTGeXdIYaRGzZ9iChQt598pTggizw+syZKh31Y9Ad5ssGtlfsaPUZgjnsd5FQuxJMSa1du1yy//UuEeWGSHJlHFjAZEXlFmWV1H+xr436ZoxFzIR7IZAF7F4w+AE2SgXsSYtADQAAAABJRU5ErkJggg==" draggable="false" />
                            </div>
                        </div>
                </div>
            }>
                跟单大厅
            </NavBar>
            <div style={{ flex: 1, overflow: "auto" }}>
                <div className="tips">
                    <div className="uni-image">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMA81lQN8i+4kUmha4e2AqZkHy2ZaTEK8yqAAAAy0lEQVQoz32TCw6EIAxEW4UiH9F1t/e/6hZ/oCKTaGpe0gzDCKeUI4NoyCm46zvyqbG7IKtZhOQ9YZq0zWxAIbPa10/payiZD3AqLJlaYZ91itbGdXBCt836YNAx93BQvfqUnXCHIJuTZ8MYnjAgj+KGeYYnhIlZwSyvGlTMTuwYqEFApvTUIbER+KtDz9iE72vFzMVQb8xQGspHycpHKUOAGKEMoRXfa/AkwbeurHnZuSZLWRO6lwinasFyNbX3eqtmgFJdrdTN3+EPl5cQkia8dacAAAAASUVORK5CYII=" draggable="false" />
                    </div>
                    比赛变幻莫测，请控制金额理性跟单!
                </div>
                {
                    showRanker && (
                        <div>
                            <div className="item">
                                <div className="left">
                                    <span><span>一战成名</span></span>
                                </div>
                                <div className="right">
                                    {topBonus?.map((val) => {
                                        const { user } = val;
                                        return (
                                            <div className="v1" style={{ "position": "relative" }} onClick={() => navigate(`/person/followbuy/person/${user._id}`)}>
                                                <div className="uni-image">
                                                    <img src={user.avatar || defaultUser} draggable="false" />
                                                </div>
                                                <span><span>{user.username}</span></span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span><span>命中榜</span></span>
                                </div>
                                <div className="right">
                                    {topWinRate?.map((val) => {
                                        const { user } = val;
                                        return (
                                            <div className="v1" style={{ "position": "relative" }} onClick={() => navigate(`/person/followbuy/person/${user._id}`)}>
                                                <div className="uni-image">
                                                    <img src={user.avatar || defaultUser} draggable="false" />
                                                </div>
                                                <span><span>{user.username}</span></span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="item">
                                <div className="left">
                                    <span><span>盈利榜</span></span>
                                </div>
                                <div className="right">
                                    {topProfitMargin?.map((val) => {
                                        const { user } = val;
                                        return (
                                            <div className="v1" style={{ "position": "relative" }} onClick={() => navigate(`/person/followbuy/person/${user._id}`)}>
                                                <div className="uni-image">
                                                    <img src={user.avatar || defaultUser} draggable="false" />
                                                </div>
                                                <span><span>{user.username}</span></span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                }
                <Tabs onChange={(tab) => setListType(tab)}>
                    <Tabs.Tab title='命中率' key='winrate' />
                    <Tabs.Tab title='跟单人气' key='popularity' />
                    <Tabs.Tab title='自购金额' key='amount' />
                    <Tabs.Tab title='我的关注' key='follow' />
                </Tabs>
                {
                    followBuyTaskList?.length > 0 ? (
                        <div className="content block-container" style={{ flex: "1 1" }}>
                            <div className="block-body">
                                {
                                    followBuyTaskList?.map((task) => {
                                        const { creator, closeTime, bet, amount, _id, message } = task;
                                        return (
                                            <div key={_id} className="list" onClick={() => navigate(`/person/followbuy/person/${creator?._id}`)}>
                                                <div className="top">
                                                    <div className="left">
                                                        <div className="uni-image">
                                                            <img src={creator.avatar || defaultUser} draggable="false" />
                                                        </div>
                                                        <div className="l1">
                                                            <div className="user">
                                                                <span><span>{creator?.username}</span>
                                                                </span>
                                                            </div>
                                                            <span style={{ "color": "rgb(189, 189, 190)", "fontSize": "12px" }}>
                                                                <span>预计回报{ `${bet.maxSP?.toFixed(2) || "--"}`}倍</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="r1">
                                                            <span style={{ "color": "rgb(189, 189, 190)" }}>
                                                                <span>截止时间</span>
                                                            </span>
                                                            <span className="txt-primary" style={{ "fontSize": "12px" }}>
                                                                <span>{getTimeInYear(closeTime)}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c">
                                                    <span><span>{message}</span></span>
                                                </div>
                                                <div className="foot">
                                                    <div className="left">
                                                        <div className="f1">
                                                            <span className="head"><span>{cnNameMap[bet.type]}</span></span>
                                                            <span className="t1 play-type">
                                                                <span>{options2Str(bet.options)}</span>
                                                            </span>
                                                        </div>
                                                        <div className="f1">
                                                            <span className="head">
                                                                <span>自购</span>
                                                            </span>
                                                            <span className="t1">
                                                                <span>{amount}元</span>
                                                            </span>
                                                        </div>
                                                        <div className="f1">
                                                            <span className="head">
                                                                <span>人气</span>
                                                            </span>
                                                            <div className="t1">
                                                                <p>
                                                                    <span className="gdRqOut">
                                                                        <span><span className="t2 " style={{ "width": "70px" }}><span></span>
                                                                        </span>
                                                                        </span>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="gd">
                                                            <div className="f2" onClick={(e) => doFollowBuy(e, _id)}>
                                                                <span><span>跟单</span></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : (
                        <Empty description='暂无数据' style={{ flex: "1 1" }}/>
                    )
                }
            </div>
            <Footer />
        </div>
    )
}