import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDrawList } from "../../axios";
import { NavBar, Toast, Image, List, InfiniteScroll } from "antd-mobile";
import { getTime } from "../../utils";
import DefaultUser from "../../assets/default_photo.8b581359.png";
const status2CN = ['未处理', '已通过', '已拒绝'];
export default function DrawHistory() {
    const navigate = useNavigate();
    const [drawList, setDrawList] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const pagenation = useRef({ pageNum: 0, loading: false });
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
            status: '1,2',
        };

        try {
            const { data } = await getUserDrawList(query);
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                pagenation.current.pageNum++;
                setDrawList([...drawList, ...data.result]);
                setHasMore(data.result.length === 10);
            }
        } catch (e) {
            Toast.show("网络错误");
        }
        pagenation.current.loading = false;
    }

    return (
        <div>
            <NavBar onBack={() => navigate(-1)}  right={(<div onClick={() => navigate('/person/drawmoney')}>立即提现</div>)}>
                提现历史
            </NavBar>
            <div>
                <List>
                    { drawList.map((drawInfo) => {
                        return (
                            <List.Item>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>{ getTime(drawInfo.createTime) }</div>
                                        <div>
                                            {drawInfo.amount}元 {status2CN[drawInfo.status]}
                                            {
                                                drawInfo.status === 2 && (
                                                    <div>拒绝原因:{drawInfo.refuseReason}</div>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "start",
                                        flexDirection: "column"
                                    }}>
                                        <div>渠道：{drawInfo.way}</div>
                                        {
                                            drawInfo.way === "alipay" && (
                                                <div>支付宝账号：{drawInfo.alipayAcount}</div>
                                            )
                                        }
                                        {
                                            drawInfo.way === "bank" && (
                                                <>
                                                    <div>银行卡号：{drawInfo.bankCard}</div>
                                                    <div>开户行：{drawInfo.bankName}</div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </List.Item>
                        )
                    }) }
                </List>
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
            </div>
        </div>
    )
}