import { get, post } from "./base.js";

/**
 * @param {any} params
 */
export function getFollowBuyList(params) {
    return get("/followbuy/list", params)
}

/**
 * @param {any} params
 */
export function getFollowBuyRank(params) {
    return get("/followbuy/rank", params)
}

/**
 * @param {any} params
 */
export function getFollowBuyPerson(params) {
    return get("/followbuy/person", params)
}

/**
 * @param {any} params
 */
export function getFollowBuyDetail(params) {
    return get("/followbuy/detail", params)
}

/**
 * @param {any} params
 */
export function getFollowBuyMeInfo(params) {
    return get("/followbuy/me/detail", params)
}

/**
 * @param {any} params
 */
export function getFollowBuyMeList(params) {
    return get("/followbuy/me/list", params)
}

/**
 * @param {any} body
 */
export function postFollowBuyOrder(body) {
    return post("/followbuy/order", body)
}

/**
 * @param {any} body
 */
export function getFollowBuyCustomize(body) {
    return post("/followbuy/customize", body)
}
