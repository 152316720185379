import React, { useEffect, useState } from "react";
import { Button, Form, Input, NavBar, Stepper, Toast } from "antd-mobile";
import { useNavigate, useParams } from "react-router-dom";
import { getDailyUpdateDetail, postDailyUpdate } from "../../axios/index.js";

export default function DailyUpdateDetail() {
    const navigate = useNavigate();
    const params = useParams();
    const [dateStr, setDateStr] = useState('');
    const [alipayCashAmount, setAlipayCashAmount] = useState(0);
    const [weChatCashAmount, setWeChatCashAmount] = useState(0);
    const [chargeCommission, setChargeCommission] = useState(0);
    const [otherExpenses, setOtherExpenses] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [status, setStatus] = useState(0);

    useEffect(() => {
        getDailyUpdateDetail({ dockingDailyUpdateId: params.id }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                const { result } = data;
                setDateStr(result.dateStr);
                setAlipayCashAmount(result.alipayCashAmount);
                setWeChatCashAmount(result.weChatCashAmount);
                setChargeCommission(result.chargeCommission);
                setOtherExpenses(result.otherExpenses);
                setRemarks(result.remarks);
                setStatus(result.status);
            }
        })
    }, [params])

    const handleSubmit = () => {
        postDailyUpdate({
            dockingDailyUpdateId: params.id, 
            alipayCashAmount,
            weChatCashAmount,
            chargeCommission,
            otherExpenses,
            remarks,
        }).then(({data}) => {
            if(data.status === 'error') {
                Toast.show(data.errorMessage);
            } else {
                Toast.show('上传成功，请等待审核');
            }
            navigate(-1);
        })
    }

    return (
        <div>
            <NavBar onBack={() => navigate(-1)}>
                {dateStr} 上报
            </NavBar>
            <Form name='form' layout='horizontal'>
                <Form.Item name='alipayCashAmount' label='支付宝余额' childElementPosition='right'>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                        <Stepper value={alipayCashAmount} step={2} onChange={setAlipayCashAmount}/> 元
                    </div>
                </Form.Item>
                <Form.Item name='weChatCashAmount' label='微信余额' childElementPosition='right'>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                        <Stepper value={weChatCashAmount} step={2} onChange={setWeChatCashAmount}/> 元
                    </div>
                </Form.Item>
                <Form.Item name='chargeCommission' label='充值手续费' childElementPosition='right'>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                        <Stepper value={chargeCommission} step={2} onChange={setChargeCommission}/> 元
                    </div>
                </Form.Item>
                <Form.Item name='otherExpenses' label='其他花费' childElementPosition='right'>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                        <Stepper value={otherExpenses} step={2} onChange={setOtherExpenses}/> 元
                    </div>
                </Form.Item>
                <Form.Item name='remarks' label='备注' childElementPosition='right'>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                        <Input value={remarks} onChange={setRemarks} placeholder="如果有额外花费可备注"/>
                    </div>
                </Form.Item>
            </Form>
            {
                status === 0 && (
                    <Button block color="primary" style={{ margin: "20px 0" }} onClick={handleSubmit}>提交</Button>
                )
            }
        </div>
    )
}

