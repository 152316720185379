import React, { useEffect, useRef, useState } from "react";
import "./AgentMyStatistics.css";
import { getUserAgentStatic } from "../../axios";
import { InfiniteScroll, NavBar, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
export default function AgentMyStatistics() {
    const navigate = useNavigate();
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [hasMore, setHasMore] = useState(false);
    const [result, setResult] = useState<any[]>([]);
    const [thisMonthAmount, setThisMonthAmount] = useState(0);
    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        pagenation.current.pageNum++;
        try {
            const { data } = await getUserAgentStatic(query);
            setResult([...result, ...data.result]);
            setHasMore(data.result.length === 10);
        } catch (e) {
            Toast.show("获取彩票失败");
        }
        pagenation.current.loading = false;
    }

    useEffect(() => {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        setResult([]);
        pagenation.current.pageNum = 0;
        let query: any = {
            pageNum: pagenation.current.pageNum++,
            pageSize: 10,
        };

        getUserAgentStatic(query).then(({ data }: any) => {
            setResult(data.result);
            setHasMore(data.result.length === 10);
            setThisMonthAmount(data.thisMonthAmount);
        }).finally(() => {
            pagenation.current.loading = false;
        })
    }, []);
    const back = () => {
        navigate(-1);
    }
    return (
        <div className="agent-my-statistics">
            <NavBar onBack={back}>
                我的统计
            </NavBar>
            <div className="container">
                <div className="top-tips">
                    <p className="tip">本月累计购彩总额:{thisMonthAmount}元</p>
                </div>
                {
                    result.map((item) => {
                        return (
                            <div className="item">
                                <div className="left">{item.dateStr}</div>
                                <div className="right">
                                    <div className="fr">下级用户购彩金额<span className="pice">
                                        <span>{item.amount?.toFixed(2)}</span>
                                    </span>元
                                    </div>
                                    {/* <div className="fr" style={{ "marginTop": "11px" }}>提成总额<span className="pice">
                                        <span>0.16</span>
                                    </span>元
                                    </div> */}
                                </div>
                            </div>
                        )
                    })
                }
                <InfiniteScroll hasMore={hasMore} loadMore={loadMore}/>
            </div>
        </div>
    )
}