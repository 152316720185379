import { Avatar, Dialog, InfiniteScroll, NavBar, SafeArea, SearchBar, Tabs, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAgentUserList } from "../../axios/index.js";
import { getTime } from "../../utils/index.ts";
import { SearchOutline } from "antd-mobile-icons";
import defaultUser from "../../assets/default_avatar.png";
import { postUserChatStatus } from "../../axios/chat.js";

const chatStatus2CN = ['正常', '禁言', '拉黑'];

export default function UserManage() {
    const navigate = useNavigate();
    const [userList, setUserList] = useState<any[]>([]);
    const [phone, setPhone] = useState("");
    const [showSearchBar, setShowSearchBar] = useState(false);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const [hasMore, setHasMore] = useState(true);
    async function loadMore() {
        if (pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            phone,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        getAgentUserList(query).then(({ data }) => {
            if (data.status === "error") {
                Toast.show(data.errorMessage);
                setHasMore(false);
            } else {
                pagenation.current.pageNum++;
                setUserList([...userList, ...data.userList]);
                setHasMore(data.userList.length === 10);
            }
        }).finally(() => {
            pagenation.current.loading = false;;
        })
    }
    useEffect(() => {
        pagenation.current.pageNum = 0;
        setUserList([]);
        setHasMore(true);
    }, [phone]);
    const handleUserClick = (id: string, index: number) => {
        Dialog.show({
            content: '更改状态',
            closeOnAction: true,
            onAction: (action) => {
                if(action.key !== 'cancel') {
                    postUserChatStatus({ userId: id, status: Number(action.key) }).then(({data}) => {
                        if(data.status === 'error') {
                            Toast.show(data.errorMessage)
                        } else {
                            userList[index].chatStatus = Number(action.key);
                            setUserList([...userList]);
                        }
                    })
                }
            },
            actions: [
              {
                key: '0',
                text: '正常',
              },
              {
                key: '1',
                text: '禁言',
              },
              {
                key: '2',
                text: '拉黑',
              },
              {
                key: 'cancel',
                text: '取消',
              }
            ]
        })
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <NavBar onBack={() => navigate(-1)} right={<SearchOutline onClick={() => setShowSearchBar(!showSearchBar)} />}>
                用户管理
            </NavBar>
            <div style={{ display: "flex", flexDirection: "column", flex: '1', background: "#f0f0f0", overflow: "auto" }}>
                {
                    showSearchBar && (
                        <SearchBar
                            style={{ margin: "2px 0" }}
                            onSearch={(data) => setPhone(data)}
                            placeholder='请输入用户手机号'
                            onClear={() => { phone && setPhone("") }}
                        />
                    )
                }
                <div style={{ flex: "1 1", overflowY: "auto", padding: "0 10px" }}>
                    {
                        userList && userList.map((user, index) => (
                            <div
                                key={user._id}
                                style={{ display: "flex", gap: "10px", padding: "10px", margin: '10px 0', borderRadius: "10px", background: "white" }}
                                onClick={() => handleUserClick(user._id, index)}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Avatar src={user.avatar || defaultUser} />
                                </div>
                                <div style={{ flex: "1 1" }}>
                                    {user.username} <br />
                                    <span style={{ color: "gray", fontSize: "13px" }}>{getTime(user.createTime)}</span>
                                </div>
                                <div>
                                    {chatStatus2CN[user.chatStatus]} <span style={{ color: 'blue' }}>更改</span>
                                </div>
                            </div>
                        ))
                    }
                    <InfiniteScroll hasMore={hasMore} loadMore={loadMore} />
                    <SafeArea position='bottom' />
                </div>
            </div>
        </div>
    )
}