import { NavBar } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "antd-mobile";
import { postFollowBuyOrder, getUser, postBetOrder, postParticipateGroupBuy } from "../../axios";
import { cnNameMap }  from "../../constant/cnNameMap";
import { set } from "../../store/userSlice.js";
import WalletJPG from "../../assets/images/wallet.jpg";
export default function PayPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const [loading, setLoading] = useState(false);
    const closeTime = useMemo(() => {
        return location.state?.closeTime || 0;
    }, [location.state?.closeTime]);
    const amount = useMemo(() => {
        return location.state?.amount || 0;
    }, [location.state?.amount]);
    const type = useMemo(() => {
        return location.state?.type
    }, [location.state?.type])
    const subType = useMemo(() => {
        return location.state?.subType
    }, [location.state?.subType]);
    const isFollowBet = useMemo(() => {
        return location.state?.isFollowBet
    }, [location.state?.isFollowBet]);
    const isParticipateGroupBuy = useMemo(() => {
        return location.state?.isParticipateGroupBuy
    }, [location.state?.isParticipateGroupBuy]);
    const data = useMemo(() => {
        return location.state?.data
    }, [location.state?.data]);

    useEffect(() => {
        if(!user._id) {
            getUser({}).then(({data}) => {
                if(data.status === 'error') {
                    Toast.show(data.errorMessage);
                } else {
                    dispatch(set(data.user));
                }
            })
        }
    }, [])

    const doPayNow = async() => {
        if(loading) {return};
        if(closeTime < Date.now()) {
            Toast.show("已超过截止时间");
            return;
        }
        setLoading(true)
        Toast.show('下单中');
        if(isFollowBet) {
            postFollowBuyOrder(data).then(({data}) => {
                if(data.status === 'error') {
                    Toast.show(data.errorMessage);
                } else {
                    Toast.show("下单成功");
                    navigate(`/person/bet/detail/${data.id}`, { state: { from: 'pay' } });
                }
            }).finally(() => {
                setLoading(false);
            })
        } else if(isParticipateGroupBuy) {
            postParticipateGroupBuy(data).then(({data}) => {
                if(data.status === 'error') {
                    Toast.show(data.errorMessage);
                } else {
                    Toast.show("下单成功");
                    navigate("/person/groupbuy/me");
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            console.log(data);
            postBetOrder(data).then(({data}) => {
                if(data.status === 'error') {
                    Toast.show(data.errorMessage);
                } else {
                    Toast.show("下单成功");
                    navigate(`/person/bet/detail/${data.id}`, { state: { from: 'pay' } });
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }
    return (
        <div className="aui-flexView">
            <NavBar
                onBack={() => navigate(-1)}>
                支付确认页
            </NavBar>
            <div className="aui-scrollView content" style={{ padding: 0, marginTop: 0 }}>
                <div className="block-white">
                    <div className="grid-6-6">
                        <span className="label-2">平台昵称</span>
                        <div style={{ textAlign: "right", color: "#9f9f9f" }}>
                            <span>{ user.username }</span>
                        </div>
                    </div>
                    <div className="grid-6-6">
                        <span className="label-2">投注彩种</span>
                        <div style={{ textAlign: "right", color: "#9f9f9f" }}>
                            <span>{cnNameMap[type]}{cnNameMap[subType]}</span>
                        </div>
                    </div>
                    <div className="grid-6-6">
                        <span className="label-2">投注金额</span>
                        <div style={{ textAlign: "right", color: "#9f9f9f" }}>
                            <span className="font-money">{amount?.toFixed(2)}</span><span>元</span>
                        </div>
                    </div>
                </div>
                <div className="block-white">
                    <div style={{ display: "flex" }}>
                        <img src={WalletJPG}style={{ width: "50px", marginRight: "12px", verticalAlign: "middle" }}/>
                        <div style={{ lineHeight: "25px" }}>
                            <p>钱包余额支付</p>
                            <p style={{ fontSize: "14px", color: "#9f9f9f" }}>可用余额：{user?.balance.toFixed(2)}元</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <button className="btn-orange" style={{ width: "100%", height: "45px" }} onClick={doPayNow}>立刻支付:{amount}元</button>
            </div>
        </div>
    )
}