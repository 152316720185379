const notesMap: {
    [key: string]: any
} = {
    direct: {
        "102": [0, 0, 0, 6, 24, 60, 120, 210, 336, 504, 720],
        "103": [1, 3, 6, 10, 15, 21, 28, 36, 45, 55, 63, 69, 73, 75, 75, 73, 69, 63, 55, 45, 36, 28, 21, 15, 10, 6, 3, 1],
        "105": [10, 54, 96, 126, 144, 150, 144, 126, 96, 54],
    },
    group3: {
        "302": [0, 0, 2, 6, 12, 20, 30, 42, 56, 72, 90],
        "304": [0, 18, 16, 14, 12, 10, 8, 6, 4, 2],
    },
    group6: {
        "601": [0, 0, 0, 1, 4, 10, 20, 35, 56, 84, 120],
        "603": [0, 0, 8, 14, 18, 20, 20, 18, 14, 8]
    }
}

export default notesMap;