import React, { useMemo, useEffect, useState } from "react";
import "./ChaseNumDetail.css";
import WaitPNG from "../../assets/ddkj.5a301c70.png";
import LosePNG from "../../assets/zjzl.6f85eb0a.png";
import WinPNG from "../../assets/zjtb.564284aa.png";
import PickThreePNG from "../../assets/202.png";
import { useParams, useNavigate } from "react-router-dom";
import { getChaseNumDetail } from "../../axios";
import PickThreeBetDetail from "../../components/PickThreeBetDetail.tsx";
import { NavBar } from "antd-mobile";
const status2CN = ["进行中", "已完成", "已取消"]

export default function ChaseNumDetail() {
    const params = useParams();
    const navigate = useNavigate();
    const taskId = useMemo(() => {
        return params.id;
    }, [params]);
    const [chaseNumTask, setChaseNumTask] = useState<any>({});
    const [betList, setBetList] = useState<any[]>([]);
    useEffect(() => {
        getChaseNumDetail({taskId}).then(({data}) => {
            setChaseNumTask(data.chaseNumTask || []);
            setBetList(data.betList);
        })
    }, [])
    return (
        <div className="bigView chase-num-detail">
            <NavBar onBack={() => navigate(-1)}>
                追号详情
            </NavBar>
            <div className="detailHeader">
                <div className="detailHeader-top"
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "0px" }}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <div className="uni-image icon" style={{ width: "31px", height: "31px" }}>
                            <img src={PickThreePNG} draggable="false" />
                        </div>
                        <span style={{ fontSize: "16px", fontWeight: "900", color: "rgb(0, 0, 0)", marginLeft: "10px" }}>排列三</span>
                    </div>
                    <div style={{ width: "115px", height: "38px", marginRight: "10px" }}>
                        <div className="uni-image" style={{ width: "109px", height: "32px" }}>
                            <img src={WaitPNG} draggable="false" />
                        </div>
                    </div>
                </div>
                <div className="detailHeader-footer">
                    <div className="detailHeader-footer-top">
                        <div style={{ color: "red", fontWeight: "900" }}>{chaseNumTask.amount}元</div>
                        <div>方案金额</div>
                    </div>
                    <div className="detailHeader-footer-footer">
                        <div style={{ fontSize: "14px", fontWeight: 900 }}>{status2CN[chaseNumTask.status]}</div>
                        <div style={{ fontSize: "13px", marginTop: "5px" }}>方案状态</div>
                    </div>
                    <div className="detailHeader-footer-mian">
                        <div style={{ fontWeight: 900, color: "rgb(49, 147, 253)" }}>{chaseNumTask.bonus}元</div>
                        <div>税后奖金</div>
                    </div>
                </div>
            </div>
            <div className="detailMain">
                <div className="detailMain-top">
                    <div style={{ fontWeight: 900, fontSize: "14px" }}>投注信息
                    <span></span>
                </div>
                </div>
                {
                    betList?.[0] && (
                        <PickThreeBetDetail items={chaseNumTask.items} showTopInfo={false}/>
                    )
                }
            </div>
            <div className="detailFoot">
                <div className="detailFoot-top" style={{ alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ fontSize: "14px", fontWeight: 900 }}>
                                <span>追号情况</span>
                            </span>
                        <div className="u-m-l-20"
                            style={{ 
                                padding: "0px 5px", 
                                borderRadius: "5px", 
                                border: "1px solid rgb(119, 166, 235)", 
                                color: "rgb(119, 166, 235)", 
                                fontSize: "12px", 
                                height: "30px", 
                                textAlign: "center", 
                                lineHeight: "30px"
                            }}>
                            第{chaseNumTask.complete + 1}期/共{chaseNumTask.total}期</div>
                    </div>
                    {/* <div style={{ fontSize: "12px" }}>中奖后取消追号</div> */}
                </div>
                <div className="detailFoot-mian"
                    style={{ backgroundColor: "rgb(222, 224, 225)", height: "40px", textAlign: "center", lineHeight: "40px", fontSize: "14px" }}>
                    <div style={{ border: "1px solid rgb(240, 243, 246)", height: "100%" }}>期数</div>
                    <div style={{ border: "1px solid rgb(240, 243, 246)", height: "100%" }}>投注金额</div>
                    <div style={{ border: "1px solid rgb(240, 243, 246)", height: "100%" }}>开奖号码</div>
                    <div style={{ border: "1px solid rgb(240, 243, 246)", height: "100%" }}>状态</div>
                </div>
                <div className="detailFoot-foot wrap">
                    {
                        betList?.map((bet) => {
                            const { pickthreeInfo = {}, spend, status, subType } = bet;
                            const { pickthree = {} } = pickthreeInfo;
                            return (
                                
                                <div className="detailFoot-foot-list wrap u-border-bottom"
                                    style={{ fontSize: "12px", fontWeight: 600 }}>
                                    <div style={{ border: "1px solid rgb(240, 243, 246)", height: "60px", display: "flex", alignItems: "center" }}>
                                        <div>{pickthreeInfo?.term}期</div>
                                    </div>
                                    <div
                                        style={{ border: "1px solid rgb(240, 243, 246)", height: "60px", display: "flex", alignItems: "center" }}>
                                        <div>{spend}元</div>
                                    </div>
                                    <div
                                        style={{ border: "1px solid rgb(240, 243, 246)", height: "60px", display: "flex", alignItems: "center" }}>
                                        <span style={{ height: "30px", width: "30px" }}>{pickthree.result?.replaceAll(",", "|") || "-"}</span>
                                    </div>
                                    <div style={{ border: "1px solid rgb(240, 243, 246)", height: "60px", display: "flex", alignItems: "center" }}>
                                    {
                                        status === "8" ? (
                                            <div>等待中</div>
                                        ) : (
                                            <div style={{ color: "rgb(49, 147, 253)" }} onClick={() => navigate(`/person/bet/detail/${bet._id}`)}>查看&gt;</div>
                                        )
                                    }
                                    </div>
                                    
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="btnbox" style={{ display: "flex", justifyContent: "space-around" }}>
                <div 
                    style={{ width: "45%", height: "40px", backgroundColor: "rgb(49, 147, 253)", borderRadius: "10px", textAlign: "center", lineHeight: "40px", color: "rgb(255, 255, 255)" }}
                    onClick={() => navigate("/person/three")}>
                    继续投注排列三</div>
                {/* <div style={{ width: "45%", height: "40px", backgroundColor: "rgb(49, 147, 253)", borderRadius: "10px", textAlign: "center", lineHeight: "40px", color: "rgb(255, 255, 255)" }}>
                    复制投注该组号码</div> */}
            </div>
        </div>
    )
}