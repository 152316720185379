import GoEasy from 'goeasy';
let goEasyClient:GoEasy;

export function getGoEasyClient() {
    if (goEasyClient) {
        return goEasyClient
    } else {
        goEasyClient =  GoEasy.getInstance({
            host: 'hangzhou.goeasy.io', //应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
            appkey: 'BC-061756284ef8464ba2a0a95d0594f0c3', // common key,
            modules: ['im'],
        });
        return goEasyClient;
    }
}

export function connectGoEasyClient(goEasy: GoEasy, user: any, isGroupOwner?: boolean) {
    return new Promise((resolve, reject) => {
        const status = goEasy.getConnectionStatus();
        if(status === "disconnected") {
            goEasy.connect({
                id: user._id,
                data: {name:  user.username, avatar: user.avatar || "https://tc-lottery.oss-cn-guangzhou.aliyuncs.com/chat-avatar/Avatar-1.png" },
                onSuccess: function () {  //连接成功
                  resolve({});
                },
                onFailed: function (error) { //连接失败
                    console.log(error);
                  reject()
                },
                onProgress: function (attempts) { //连接或自动重连中
                  console.log("GoEasy is connecting", attempts);
                }
            });
        } else {
            resolve({})
        }
    })
}
export function offGoEasyClient() {
    // goEasyClient.disconnect();
}